
import { combineReducers } from '@reduxjs/toolkit';
import authenticationReducer from './_reducers/_auth_reducers';
import userReducer from './_reducers/_user_reducers';


const rootReducer = combineReducers({
  authentication: authenticationReducer,
  user : userReducer
});

export default rootReducer;
