import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/_reducers/_auth_reducers";
import { message } from 'antd'; // Ant Design's message component
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'; // React Icons for eye toggle

function Login({ isOpen, closeModal }) {
  // State to store input values
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
if(!username || !password){
  return message.error("please enter username and password")
}
    // If terms are not agreed, show an error message using Ant Design
    if (!agreeTerms) {
      message.error("You must agree to the Terms & Conditions to proceed.");
      return;
    }

    const reqData = {
      username: username,
      password: password,
      isClient: true,
      host: window.location.host,
    };

    dispatch(login(reqData))
      .then((data) => {
        if (!data.error) {
          closeModal();
          navigate("/dashboard");
        } else {
          console.error("Login failed:", data.error);
        }
      })
      .catch((error) => {
        console.error("Login request failed:", error);
      });
  };

  // Handle login with demo credentials
  const handleDemoLogin = () => {
    const demoCredentials = {
      username: "testaj3",
      password: "1122",
      isClient: true,
      host: window.location.host,
    };

    dispatch(login(demoCredentials))
      .then((data) => {
        if (!data.error) {
          closeModal();
          navigate("/dashboard");
        } else {
          console.error("Demo Login failed:", data.error);
        }
      })
      .catch((error) => {
        console.error("Demo login request failed:", error);
      });
  };

  return (
    <div className="fixed z-30 inset-0 flex items-center justify-center bg-black bg-opacity-50 px-3">
      <div className="bg-primary text-white max-w-[550px] md:min-w-[500px] min-w-[100%] rounded-2xl shadow-lg relative">
        {/* Header */}
        <div className="flex items-center justify-between mb-4 border-b border-secondary px-6 py-4">
          <img src="/assets/logo.png" alt="Magic Win" className="h-10" />
          <button
            onClick={closeModal}
            className="text-white bg-gray-700/70 w-9 h-9 rounded-full flex justify-center items-center  hover:text-white text-4xl"
          >
            &times;
          </button>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="px-3 pb-3">
          {/* Input Fields */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Username"
              className="bg-secondary w-full flex-grow p-2 rounded-md text-white placeholder-gray-400 focus:border-button focus:border outline-none"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="mb-4 relative">
            <input
              type={showPassword ? "text" : "password"} // Toggle between password and text
              placeholder="Password"
              className="bg-secondary w-full flex-grow p-2 rounded-md text-white placeholder-gray-400 focus:border-button focus:border outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute right-3 top-2 text-gray-400"
              onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
            >
              {showPassword ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}
            </button>
          </div>

          {/* Terms & Conditions */}
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              className="form-checkbox !checked:bg-green-950 text-orange-500 mr-2"
              checked={agreeTerms}
              onChange={(e) => setAgreeTerms(e.target.checked)}
            />
            <span className="text-sm">
              I agree that I have read{" "}
              <span className="text-orange-500 underline">
                Terms & Conditions
              </span>{" "}
              policy
            </span>
          </div>

          {/* Buttons */}
          <button
            type="submit"
            className="bg-transparent border border-orange-500 text-orange-500 w-full py-2 rounded-md mb-4"
          >
            Login
          </button>
          <button
            type="button"
            onClick={handleDemoLogin} // Trigger demo login when clicked
            className="bg-conic text-white w-full py-2 rounded-md"
          >
            Login with Demo ID
          </button>

          {/* Signup */}
          {/* <div className="text-center mt-4 text-sm">
            Don’t have an account?{" "}
            <a href="/" className="text-orange-500 underline">
              Signup here
            </a>
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default Login;
