import moment from "moment";
import { useEffect, useState } from "react";
import { FaSortDown, FaSortUp, FaTv } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Inplay() {
  const [cricketOpen, setCricketOpen] = useState(true);
  const [tennisOpen, setTennisOpen] = useState(true);
  const [footballOpen, setFootballOpen] = useState(true);
  const [matchData, setMatchData] = useState([]);
  const matchlistLocal = localStorage.getItem("matchList")
    ? JSON.parse(localStorage.getItem("matchList"))
    : null;

  const { matchList } = useSelector((state) => state.user);

  useEffect(() => {
    let matchListData = matchlistLocal ? matchlistLocal : matchList;
    setMatchData(matchListData);
  }, [matchList]);
  function getTeamsFromMatchName(matchName) {

    let team1 = "Team 1",
      team2 = "Team 2";

    if (typeof matchName !== 'string') {
      return { team1, team2 };
    }


    if (matchName.includes(" v ") || matchName.includes(" V ")) {

      [team1, team2] = matchName.split(/ v | V /);
    } else if (matchName.includes(" vs ") || matchName.includes(" VS ")) {

      [team1, team2] = matchName.split(/ vs | VS /);
    }


    return { team1, team2 };
  }





  return (
    <div className="pb-20">
      <div className="rounded-md my-2 pb-2 border bg-secondary border-secondary">
        <div className="flex text-white justify-between items-center border-b-2 border-button mb-2 px-2 py-1.5">
          <div className="flex  gap-2 py-2 text-sm font-semibold">
            <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/Cricket.png" className="h-5 w-5" /> Cricket
          </div>
          <div className="text-2xl">
            {!cricketOpen ? (
              <FaSortDown onClick={() => setCricketOpen(true)} />
            ) : (
              <FaSortUp onClick={() => setCricketOpen(false)} />
            )}
          </div>
        </div>
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${cricketOpen ? "max-h-[5000px]" : "max-h-0"
            }`}
        >
          <div className="space-y-1 rounded px-2 mb-4">
            {matchData && matchData?.filter(ele => ele.sportId == 4).length > 0 ? (
              matchData?.filter(ele => ele.sportId == 4)?.map((match, index) => (
                <div
                  onClick={() => {
                    localStorage.getItem("token")
                      ? (window.location.href = `/match-details/${match?.marketId}/${match?.eventId}`)
                      : localStorage.setItem("unauthorized", true);
                  }}
                  key={index}
                  className="grid bg-primary px-2 py-2 grid-cols-1 xl:grid-cols-2 rounded-lg justify-between items-center"
                >
                  <div className="text-xs flex gap-2 text-gray-300 font-semibold">

                    {
                      (() => {
                        const inputMoment = moment(match?.matchDate, "DD-MM-YYYY HH:mm:ss A");
                        const currentMoment = moment();
                        return currentMoment.isSameOrAfter(inputMoment) ?
                        <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/live.svg" />
                          : '';
                      })()
                    }
                    {/*  {match.status === "INPLAY" && (
                      <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/live.svg" />
                    )} */}
                    {match?.matchDate}
                  </div>
                  <div className="flex items-center justify-center my-0.5">
                    <div className="flex items-center justify-center jus gap-2 text-xs font-bold text-white">
                      {getTeamsFromMatchName(match.matchName).team1}
                    </div>
                    <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/vs.svg" />
                    <div className="flex items-center justify-center jus gap-2 text-xs font-bold text-white">
                      {getTeamsFromMatchName(match.matchName).team2}
                    </div>
                  </div>
                  <div className="flex items-center justify-center my-0.5">
                    <div className="flex items-center jus gap-2 text-xs font-bold text-white">

                      {match.matchName}
                    </div>
                  </div>
                  <div className="flex justify-end ">
                    <div className="w-full xl:w-[400px] grid grid-cols-6 gap-2 font-bold">
                      <button className="bg-yes text-sm text-center py-2 rounded">2.1</button>
                      <button className="bg-no text-sm text-center py-2 rounded">2.12</button>
                      <button className="bg-yes text-sm text-center py-2 rounded">-</button>
                      <button className="bg-no text-sm text-center py-2 rounded">1.89</button>
                      <button className="bg-yes text-sm text-center py-2 rounded">-</button>
                      <button className="bg-no text-sm text-center py-2 rounded">1.89</button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full text-center text-white font-semibold py-3 bg-primary rounded-lg">
                No data Found
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="rounded-md my-2 pb-2 border bg-secondary border-secondary">
        <div className="flex text-white justify-between items-center border-b-2 border-button mb-2 px-2 py-1.5">
          <div className="flex  gap-2 py-2 text-sm font-semibold">
            <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/Tennis.png" className="h-5 w-5" /> Tennis
          </div>
          <div className="text-2xl">
            {!tennisOpen ? (
              <FaSortDown onClick={() => setTennisOpen(true)} />
            ) : (
              <FaSortUp onClick={() => setTennisOpen(false)} />
            )}
          </div>
        </div>
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${tennisOpen ? "max-h-[5000px]" : "max-h-0"
            }`}
        >
          <div className="space-y-1 rounded px-2 mb-4">
            {matchData && matchData?.filter(ele => ele.sportId == 2).length > 0 ? (
              matchData?.filter(ele => ele.sportId == 2)?.map((match, index) => (
                <div
                  onClick={() => {
                    localStorage.getItem("token")
                      ? (window.location.href = `/match-details/${match?.marketId}/${match?.eventId}`)
                      : localStorage.setItem("unauthorized", true);
                  }}
                  key={index}
                  className="grid bg-primary px-2 py-2 grid-cols-1 xl:grid-cols-2 rounded-lg justify-between items-center"
                >
                  <div className="text-xs flex gap-2 text-gray-300 font-semibold">
                    {match.status === "INPLAY" && (
                      <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/live.svg" />
                    )}
                    {match?.matchDate}
                  </div>
                  <div className="flex items-center justify-center my-0.5">
                    <div className="flex items-center justify-center jus gap-2 text-xs font-bold text-white">
                      {getTeamsFromMatchName(match.matchName).team1}
                    </div>
                    <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/vs.svg" />
                    <div className="flex items-center justify-center jus gap-2 text-xs font-bold text-white">
                      {getTeamsFromMatchName(match.matchName).team2}
                    </div>
                  </div>
                  <div className="flex items-center justify-center my-0.5">
                    <div className="flex items-center jus gap-2 text-xs font-bold text-white">

                      {match.matchName}
                    </div>
                  </div>
                  <div className="flex justify-end ">
                    <div className="w-full xl:w-[400px] grid grid-cols-6 gap-2 font-bold">
                      <button className="bg-yes text-sm text-center py-2 rounded">2.1</button>
                      <button className="bg-no text-sm text-center py-2 rounded">2.12</button>
                      <button className="bg-yes text-sm text-center py-2 rounded">-</button>
                      <button className="bg-no text-sm text-center py-2 rounded">1.89</button>
                      <button className="bg-yes text-sm text-center py-2 rounded">-</button>
                      <button className="bg-no text-sm text-center py-2 rounded">1.89</button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full text-center text-white font-semibold py-3 bg-primary rounded-lg">
                No data Found
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="rounded-md my-2 pb-2 border bg-secondary border-secondary">
        <div className="flex text-white justify-between items-center border-b-2 border-button mb-2 px-2 py-1.5">
          <div className="flex  gap-2 py-2 text-sm font-semibold">
            <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/Soccer.png" className="h-5 w-5" /> Football
          </div>
          <div className="text-2xl">
            {!footballOpen ? (
              <FaSortDown onClick={() => setFootballOpen(true)} />
            ) : (
              <FaSortUp onClick={() => setFootballOpen(false)} />
            )}
          </div>
        </div>
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${footballOpen ? "max-h-[5000px]" : "max-h-0"
            }`}
        >
          <div className="space-y-1 rounded px-2 mb-4">
            {matchData && matchData?.filter(ele => ele.sportId == 1).length > 0 ? (
              matchData?.filter(ele => ele.sportId == 1)?.map((match, index) => (
                <div
                  onClick={() => {
                    localStorage.getItem("token")
                      ? (window.location.href = `/match-details/${match?.marketId}/${match?.eventId}`)
                      : localStorage.setItem("unauthorized", true);
                  }}
                  key={index}
                  className="grid bg-primary px-2 py-2 grid-cols-1 xl:grid-cols-2 rounded-lg justify-between items-center"
                >
                  <div className="text-xs flex gap-2 text-gray-300 font-semibold">
                    {match.status === "INPLAY" && (
                      <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/live.svg" />
                    )}
                    {match?.matchDate}
                  </div>
                  <div className="flex items-center justify-center my-0.5">
                    <div className="flex items-center justify-center jus gap-2 text-xs font-bold text-white">
                      {getTeamsFromMatchName(match.matchName).team1}
                    </div>
                    <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/vs.svg" />
                    <div className="flex items-center justify-center jus gap-2 text-xs font-bold text-white">
                      {getTeamsFromMatchName(match.matchName).team2}
                    </div>
                  </div>
                  <div className="flex items-center justify-center my-0.5">
                    <div className="flex items-center jus gap-2 text-xs font-bold text-white">

                      {match.matchName}
                    </div>
                  </div>
                  <div className="flex justify-end ">
                    <div className="w-full xl:w-[400px] grid grid-cols-6 gap-2 font-bold">
                      <button className="bg-yes text-sm text-center py-2 rounded">2.1</button>
                      <button className="bg-no text-sm text-center py-2 rounded">2.12</button>
                      <button className="bg-yes text-sm text-center py-2 rounded">-</button>
                      <button className="bg-no text-sm text-center py-2 rounded">1.89</button>
                      <button className="bg-yes text-sm text-center py-2 rounded">-</button>
                      <button className="bg-no text-sm text-center py-2 rounded">1.89</button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full text-center text-white font-semibold py-3 bg-primary rounded-lg">
                No data Found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inplay;
