import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { authServices } from "../_services/_auth_services";


const initialState = {
  
};

export const login = createAsyncThunk(
  "authentication/login",
  async (userData, { rejectWithValue }) => {
    try {
      const user = await authServices.login(userData);
   
     
      return user;
    } catch (error) {
    
      return rejectWithValue(error.message);
    }
  }
);


const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.login_loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.login_loading = false;
        state.loggedIn = true;
        state.user = action.payload;
        state.token = action.payload?.data?.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.login_loading = false;
        state.error = action.payload;
      })
      
  },
});

export const { logout } = authenticationSlice.actions;

export default authenticationSlice.reducer;
