export const DepositListFirst = [
    {
        "children": [
            { "title": "Do Not Save the UPI ID and Bank details at time of making deposit as this keeps on changing." },
            { "title": "Before making payment always check the Bank details available at time of doing Deposit's." },
            { "title": "It can take upto 2 hours for your deposit to credit in your Sikander777 wallet, even if the amount has been debited from your bank account. If your wallet balance is not updated in 2 hours, kindly contact the support team" },
            { "title": "Please wait for few minutes to have a successfull payment transaction." },
            { "title": "Incase you face issue while depositing in payment gateway use manual deposit." }
        ]
    }
]


export const DepositListTwo = [
    {
        "title": "Please Notes : ",
        "children": [
            { "title": "AFTER DEPOSIT, UPLOAD SLIP, WRITE YOUR DEPOSIT AMOUNT." },
            { "title": "COPY & ENTER THE 12 DIGIT UTR NUMBER." },
            { "title": "CLICK SUBMIT BUTTON & RECIEVE POINTS & BONUS INSTANTLY." },
            { "title": "NEFT RECIEVING TIME VARIES FROM 40 MINUTES TO 1 HOUR." },
            { "title": "SITE IS NOT RESPONSIBLE FOR MONEY DEPOSITED TO OLD, IN-ACTIVE OR CLOSED ACCOUNTS." }
        ]
    }
]

export const DepositListThree = [
    {
        "children": [
            { "title": "Payment Gateway deposit is restricted to INR 5,00,000. Click on Sikander777 book for larger deposits." },
            { "title": "Minimum amount for Bank Transfer and Payment Gateway is INR 300." }, 
        ]
    }
]