import { useNavigate } from "react-router-dom"
import { casinoImages } from "../middelware/casinoConstants"

function CasinoList() {
    const navigate = useNavigate()
    const handleResponseCasino = (product) => {
        localStorage.getItem('token') ?  navigate(`/${product.shortName}/${product.eventId}`) : localStorage.setItem("unauthorized", true);
       
    }

    return (
        <div>
            <div className="rounded-md my-2 pb-20">
                {/* <div className="flex bg-secondary justify-between items-center mb-2 px-2 py-1.5">
                    <h2 className="text-white text-[16px] font-bold">{name}</h2>
                    <button className="text-sm bg-main text-white px-3 py-1 rounded-full font-semibold shadow  hover:bg-secondary">
                        See All
                    </button>
                </div> */}
                <div className="grid xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 px-2 gap-2 overflow-x-auto">
                    {casinoImages
                        ?.filter((image) => image.section2 === "liveCasino")
                        ?.map((game, index) => (
                            <div
                                key={index}
                                onClick={() => handleResponseCasino(game)}
                                className=""
                            >
                                <div
                                      style={{
                                        backgroundImage: `url(/assets/appImages/categorydetail/${game.pathName})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                    }}
                                    className="w-34 h-44 max-w-34 min-w-34 object-cover rounded-lg cursor-pointer"
                                />
                                <div className="text-center mt-2 text-sm  text-gray-300 uppercase font-semibold">
                                    {game.title}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default CasinoList
