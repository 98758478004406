import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import "./casino.css";
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import CasinoTab from "./casino_components/CasinoTab";
import DragonTiger20rules from './images/dragon-tiger-20-rules.jpg';
import GameCard from "./casino_components/GameCard";
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
// import { httpPost, httpPostBet } from "../../../../middelware/Http";
// import Loader from "../../../../component/Loader/Loader";
import { message } from "antd";
import { httpPost, httpPostBet } from "../../../middelware/CasinoMiddleware/Http";
import Loader from "../../../component/Loader/Loader";


class Dragontiger20 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newCasinoModal: false,
      betOpen: false,
      myBetOpen: false,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      backBetModal: false,
      backBetModalMobile: false,
      BetPlaceDesktop: false,
      ResultModel: false,
      casinoData: [],
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "dt20",
      betList: [],
      betresponse: [],
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      activeTab: 1,
      LoadingBet: false,
    }
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  handleBackOpen = (data) => {
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.rate, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time <= 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);

  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };


  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.sid,
      "rate": (this.state.count - this.state.oddsDifference) + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "dt20",
      "eventId": 3035,
      "betFor": this.state.betSlipData.nat + "",
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        message.success(result.message)
        // toast.success(<ErrorTost message={result.message} />, {
        //   autoClose: 1500,
        // });
        this.betList(3035)
        // this.props.dispatch(userActions.getUserBalance());
      }
      else {
        message.error(result.message)
        // toast.error(<ErrorTost message={result.message} />, {
        //   autoClose: 1500,
        // });
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };

  componentDidMount() {

    let requestData = {
      eventId: 3035,
    }
    this.getDidMountData(requestData)
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "dt20",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 2000);
  }


  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets && filteredBets.length > 0 && filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }

  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          // console.log(profitLossCount, "profitLossCount");
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }


  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };
  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  handleClose = () => {
    this.setState({ backBetModal: false, backBetModalMobile: false, });
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };


  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, activeTab, minStake, maxStake } = this.state;

    let { data, result } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let Dragon = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    let Tiger = data && data.t2 && data.t2[2] ? data.t2[1] : {};
    let Tie = data && data.t2 && data.t2[2] ? data.t2[2] : {};
    return (
      <>
        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }

        {showLoader ?
          <Loader  />
         
          :
          <div className={`w-full relative h-full   overflow-y-auto text-sm flex`}>
            {ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "20-20 Dragon Tiger"}
                shortName={shortName ? shortName : "dt20"}
                result={this.state.result}
              />
            ) : null}

            <div className="lg:flex block w-full lg:pt-1 pt-0 scroll-md lg:space-x-2">

              {/* <div className="lg:w-[15%] w-full bg-fuchsia-200 lg:px-2 lg:block hidden"></div> */}

              <div className="lg:hidden block">
                <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={DragonTiger20rules}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                />
              </div>

              <div className="w-full lg:flex">

                {activeTab === 1 ? (
                  <div className="w-full lg:flex block">

                    <div className="lg:w-[70%] w-full ">
                      <PageHeaderTheme
                        PageTitle={name ? name : "20-20 Dragon Tiger"}
                        ruleImage={DragonTiger20rules}
                        t1={t1}
                      />
                      <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[460px] md:h-[300px] h-[200px] border border-[#cf4b04]">
                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full ' />
                        <div className="absolute top-0 left-0">
                          {/* <div className="text-white font-semibold px-2 pt-[2px] tracking-wide uppercase text-[14px]">Card</div> */}
                          <div className="flex space-x-1 p-1.5">
                            <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="lg:h-12 h-8 lg:w-10 w-6 border-[1px] border-yellow-300" />
                            <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="lg:h-12 h-8 lg:w-10 w-6 border-[1px] border-yellow-300" />
                          </div>
                        </div>

                        <div className="flex justify-end items-end absolute bottom-2 right-1">
                          <FlipCountdown
                            titlePosition='hidden'
                            hideYear
                            hideMonth
                            hideDay
                            hideHour
                            hideMinute
                            endAtZero
                            size='small'
                            endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : null)).toUTCString()}
                          />
                        </div>
                      </div>

                      <div className="odds-bet w-full p-2 my-2.5 border border-[#cf4b04]">
                        <div className="flex space-x-4">
                          <GameCard
                            handleBackOpen={this.handleBackOpen}
                            Data={Dragon}
                            Name={Dragon.nat ? Dragon.nat : Dragon.nation ? Dragon.nation : "Dragon"}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                          <GameCard
                            handleBackOpen={this.handleBackOpen}
                            Data={Tie}
                            Name={Tie.nat ? Tie.nat : Tie.nation ? Tie.nation : "Tie"}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                          <GameCard
                            handleBackOpen={this.handleBackOpen}
                            Data={Tiger}
                            Name={Tiger.nat ? Tiger.nat : Tiger.nation ? Tiger.nation : "Tiger"}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                        </div>
                        {/* <div className="flex justify-end items-center font-normal lg:text-[14px] text-[10px] black-text px-2.5">
                          <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                        </div> */}
                      </div>

                      <div className=" pb-2 bg-black/5 border border-[#cf4b04]">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 justify-end items-center py-1.5 px-2">
                          {result && result.length > 0 ? result.map((element, index) => (
                            <div onClick={() =>
                              this.handleResultModel(element)} className="result-color w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                              <p className={`${element && element.result && element.result === "1" ? "text-[#F75500]" : element && element.result && element.result === "2" ? "text-[#FFF523]" : "text-[#FFFF2E]"} font-[700] text-[14px]`}>{element && element.result && element.result === "1" ? "D" : element && element.result && element.result === "2" ? "T" : "-"}</p>
                            </div>
                          )) : null}
                        </div>
                      </div>
                    </div>

                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                    />

                  </div>
                ) : null}

                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}

              </div>
            </div>
          </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Dragontiger20);