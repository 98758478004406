import React, { useEffect, useState } from "react";
import CasinoImages from "../component/Dashboard/CasinoImages";
import DashboardCarousel from "../component/Dashboard/DashboardCarousel";
import Cards from "../component/Dashboard/Cards";
import DashboardInplay from "../component/Dashboard/DashboardInplay";
import Footer from "../component/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getDomainSettingData, getUserBalance } from "../redux/_reducers/_user_reducers";
import { useNavigate } from "react-router-dom";


const Dashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [localStoragelogin, setlocalStorage] = useState(localStorage.getItem("token") ? true : false);
  const { token } = useSelector((state) => state.authentication);
  const localstorageuser = localStorage.getItem("token");


  useEffect(() => {
    if (token || localstorageuser) {

      dispatch(getUserBalance())
    }
  }, [localstorageuser, token, dispatch]);
  return (
    <div className="">
      {localStoragelogin && (
        <div className="flex w-full py-1.5 text-white">
          <div className="py-2 w-1/2 text-center flex space-x-2 justify-center items-center text-md font-base bg-[#02A425]" onClick={() => {
            navigate("/deposit");
          }}>
            <img src="/assets/appImages/magic-win-biz/assets/vectors/deposit.svg" alt="beposit" /> <span className="px-0.5">Deposit
            </span>
          </div>

          <div className="py-2 w-1/2 text-center flex space-x-2 justify-center items-center text-md font-base bg-[#D40C0C]" onClick={() => {
            navigate("/withdrawal");
          }}>
            <img src="/assets/appImages/magic-win-biz/assets/vectors/withdraw.svg" alt="beposit" /> <span className="px-0.5">Withdraw
            </span>
          </div>



        </div>)}

      <DashboardCarousel className="pl-3" />
      {/* <img
        className="w-full  h-[250px] sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px]"
        src={`/assets/appImages/categorydetail/4054.webp`}
        alt=""
      /> */}
      <Cards />
      <DashboardInplay />
      <CasinoImages filterSection={"trending"} name={"Trending"} />
      <CasinoImages filterSection={"popular"} name={"Popular Games"} />
      <CasinoImages filterSection={"indian"} name={"Indian Casino"} />
      <img
        className="w-full  h-[250px] sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px]"
        src={`/assets/appImages/categorydetail/4188.webp`}
        alt=""
      />
      <CasinoImages filterSection={"casinoGames"} name={"Casino Games"} />
      <CasinoImages filterSection={"liveCasino"} name={"Live Casino"} />
      <img
        className="w-full  h-[250px] sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px]"
        src={`/assets/appImages/categorydetail/4198.webp`}
        alt=""
      />
      <CasinoImages filterSection={"multiplayer"} name={"Multiplayer Games"} />
      <CasinoImages filterSection={"eSports"} name={"Esports"} />






      {/* <Information /> */}
      <Footer />
    </div>
  );
};

export default Dashboard;
