import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { apiCall } from "../../middelware/Http";
import { toast } from "react-toastify";
import { Option } from "antd/es/mentions";

const Withdrwal = () => {
  const [activeTab, setActiveTab] = useState("Add Account");
  const [accountDetails, setAccountDetails] = useState(null);
  const [formData, setFormData] = useState({
    accountNumber: "",
    ifscCode: "",
    accountHolder: "",
    bankName: "",
    branchName: "",
  });
  const [amount, setAmount] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getBankDetails();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getBankDetails = async () => {
    try {
      const res = await apiCall("POST", "website/getAccountDetailsByUserId");
      setAccountDetails(res.data);
    } catch (error) {
      toast.error("Failed to fetch account details.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.accountNumber)
      errors.accountNumber = "Account Number is required";
    if (!formData.ifscCode) errors.ifscCode = "IFSC Code is required";
    if (!formData.accountHolder)
      errors.accountHolder = "Account Holder Name is required";
    if (!formData.bankName) errors.bankName = "Bank Name is required";
    if (!formData.branchName) errors.branchName = "Branch Name is required";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await apiCall(
        "POST",
        "website/updateAccountDetails",
        formData
      );
      toast.success("Account details added successfully!");
      setFormData({
        accountNumber: "",
        ifscCode: "",
        accountHolder: "",
        bankName: "",
        branchName: "",
      });
      getBankDetails();
    } catch (error) {
      toast.error("Failed to add account details. Please try again.");
    }
  };
  const handleQuickAmountSelect = (selectedAmount) => {
    setAmount(selectedAmount.toString());
    setErrors((prev) => ({ ...prev, amount: "" }));
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();

    if (amount) {
      const changePasswordData = {
        amount: amount,
      };
      try {
        setIsSubmitting(true);
        const response = await apiCall(
          "POST",
          "website/withdrawReq",
          changePasswordData
        );
        const toastId = toast.success(response?.message);
        setTimeout(() => toast.dismiss(toastId), 1000);

        setAmount("");
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        const toastId = toast.error(
          "Something went wrong!. Please try again later."
        );
        setTimeout(() => toast.dismiss(toastId), 1000);
      }
    }
  };

  const handleInputChange2 = (e) => {
    setAmount(e.target.value);
  };
  return (
    <div className="bg-black text-white p-2 min-h-screen pb-20">
      <h1 className="text-xl font-medium text-center">Withdraw Request</h1>
      <p className="text-xs mb-4">
        <ul className="list-disc pl-5">
          <li>The bonus amount can be used to place bets across the platform and the winnings can be withdrawn.</li>
          <li>  In case of Any withdrawal issue i.e: Late withdrawal, bank issue etc client can complain on withdraw issue whatsapp given below.</li>
          <li> If the withdrawals are pending from the bank, it may take upto 72 banking hours for your transaction to clear.</li>
        </ul>
      </p>

      <div className="flex flex-col justify-center text-sm font-semibold mb-4 gap-2 items-center">
        <div className="border-2 border-button text-sm text-center leading-5 px-4 py-2 mt-3 rounded">
          CLICK HERE TO WITHDRAW IN USDT
          <br />
          CLICK HERE FOR ANY WITHDRAW ISSUE
        </div>
        <button className="bg-green-500 text-black font-semibold px-4 py-2 mt-2 rounded-md">
          CLICK HERE TO RESET WITHDRAW PIN
        </button>
      </div>

      <div className="flex  text-xs font-semibold justify-center mb-4">
        <div className="bg-white rounded-lg mt-1">
          <button
            onClick={() => handleTabChange("Add Account")}
            className={`px-6 sm:px-16 py-3 rounded-l-md ${
              activeTab === "Add Account"
                ? "bg-orange-500 rounded-lg text-white"
                : " text-black"
            }`}
          >
            Add Account
          </button>
          <button
            onClick={() => handleTabChange("Existing Account")}
            className={`px-6 sm:px-16 py-3 rounded-r-md ${
              activeTab === "Existing Account"
                ? "bg-orange-500 rounded-lg text-white"
                : " text-black"
            }`}
          >
            Existing Account
          </button>
        </div>
      </div>
      {activeTab === "Existing Account"  &&
      <div className="py-2 px-2 overflow-y-auto max-w-6xl mx-auto md:block hidden">
        {accountDetails ? (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-500 uppercase bg-gray-50 dark:bg-secondary dark:text-gray-400">
              <tr className="bg-[#1E1E1E]">
                <th scope="col" className="px-6 lg:py-3 py-1.5">
                  Account Number
                </th>
                <th scope="col" className="px-6 lg:py-3 py-1.5">
                  Account Holder
                </th>
                <th scope="col" className="px-6 lg:py-3 py-1.5">
                  Bank Name
                </th>
                <th scope="col" className="px-6 lg:py-3 py-1.5">
                  Branch Name
                </th>
                <th scope="col" className="px-6 lg:py-3 py-1.5">
                  IFSC Code
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-start   text-lg font-bold">
                <td className="px-6">{accountDetails?.accountNumber ? accountDetails?.accountNumber : ''}</td>
                <td  className="px-6">{accountDetails?.accountHolder ? accountDetails?.accountHolder : ''}</td>
                <td  className="px-6">{accountDetails?.bankName ? accountDetails?.bankName : ''}</td>
                <td  className="px-6">{accountDetails?.branchName ? accountDetails?.branchName : ''}</td>
                <td  className="px-6">{accountDetails?.ifscCode ? accountDetails?.ifscCode : ''}</td>
              </tr>
            </tbody>
          </table>
        ): null}
      </div>
      }
      {accountDetails && (
        <div className="md:hidden block text-gray-500 uppercase  bg-gray-50 dark:bg-secondary dark:text-gray-400 py-2">
          <div className="flex  items-center px-2 bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
            <span className="flex-1 text-start ">Account Number</span>
            <span className="flex-1 text-left pl-4">
              {accountDetails?.accountNumber}
            </span>
          </div>

          <div className="flex  items-center px-2 bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
            <span className="flex-1 text-start">IFSC Code</span>
            <span className="flex-1 text-left pl-4">
              {accountDetails?.ifscCode}
            </span>
          </div>

          <div className="flex  items-center px-2 bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
            <span className="flex-1 text-start">Account Holder</span>
            <span className="flex-1 text-left pl-4">
              {accountDetails?.accountHolder}
            </span>
          </div>

          <div className="flex  items-center px-2 bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
            <span className="flex-1 text-start">Bank Name</span>
            <span className="flex-1 text-left pl-4">
              {accountDetails?.bankName}
            </span>
          </div>

          <div className="flex  items-center px-2 bg-white py-1 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
            <span className="flex-1 text-start">Branch Name</span>
            <span className="flex-1 text-left pl-4">
              {accountDetails?.branchName}
            </span>
          </div>
        </div>
      )}

      <div className=" p-4 rounded-lg max-w-6xl mx-auto">
        {activeTab === "Add Account" ? (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-4 gap-4">

            <div className="col-span-2">
                <label className="block text-sm mb-1">Payment Option *</label>
                <select className="w-full p-2.5 bg-secondary rounded-md text-white">
                <option value={''}></option>
                  <option value={'bank'}>Bank</option>
                </select>
                {/* <input
                  type="text"
                  name="accountHolder"
                  value={formData.accountHolder}
                  onChange={handleInputChange}
                  className={`w-full p-2 bg-secondary rounded-md text-white ${
                    errors.accountHolder ? "border-red-500 border" : ""
                  }`}
                  placeholder="Holder Name"
                />
                {errors.accountHolder && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.accountHolder}
                  </p>
                )} */}
              </div>



              <div className="col-span-2">
                <label className="block text-sm mb-1">Holder Name *</label>
                <input
                  type="text"
                  name="accountHolder"
                  value={formData.accountHolder}
                  onChange={handleInputChange}
                  className={`w-full p-2 bg-secondary rounded-md text-white ${
                    errors.accountHolder ? "border-red-500 border" : ""
                  }`}
                  placeholder="Holder Name"
                />
                {errors.accountHolder && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.accountHolder}
                  </p>
                )}
              </div>

              <div className="col-span-3">
                <label className="block text-sm mb-1">Bank Name</label>
                <input
                  type="text"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleInputChange}
                  className={`w-full p-2 bg-secondary rounded-md text-white ${
                    errors.bankName ? "border-red-500 border" : ""
                  }`}
                  placeholder="Bank Name"
                />
                {errors.bankName && (
                  <p className="text-red-500 text-xs mt-1">{errors.bankName}</p>
                )}
              </div>

              <div className="col-span-1">
                <label className="block text-sm mb-1">Branch</label>
                <input
                  type="text"
                  name="branchName"
                  value={formData.branchName}
                  onChange={handleInputChange}
                  className={`w-full p-2 bg-secondary rounded-md text-white ${
                    errors.branchName ? "border-red-500 border" : ""
                  }`}
                  placeholder="Branch"
                />
                {errors.branchName && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.branchName}
                  </p>
                )}
              </div>

              <div className="col-span-2">
                <label className="block text-sm mb-1">Account Number *</label>
                <input
                  type="text"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleInputChange}
                  className={`w-full p-2 bg-secondary rounded-md text-white ${
                    errors.accountNumber ? "border-red-500 border" : ""
                  }`}
                  placeholder="Account Number"
                />
                {errors.accountNumber && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.accountNumber}
                  </p>
                )}
              </div>

              <div className="col-span-2">
                <label className="block text-sm mb-1">IBAN/IFSC Number</label>
                <input
                  type="text"
                  name="ifscCode"
                  value={formData.ifscCode}
                  onChange={handleInputChange}
                  className={`w-full p-2 bg-secondary rounded-md text-white ${
                    errors.ifscCode ? "border-red-500 border" : ""
                  }`}
                  placeholder="IBAN/IFSC Number"
                />
                {errors.ifscCode && (
                  <p className="text-red-500 text-xs mt-1">{errors.ifscCode}</p>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="bg-button px-8 py-2 mt-6 text-sm rounded-md block mx-auto"
            >
              Submit
            </button>
          </form>
        ) : (
          <form onSubmit={handleSubmit2}>
            <div className="grid grid-cols-3 gap-4 max-w-6xl uppercase mt-4 mx-auto">
            <div>
                <label className="block text-sm mb-1 ">A/C No.</label>
                <input
                  type="text"
                  className={`w-full p-2 bg-secondary rounded-md text-white ${
                    errors.amount ? "border-red-500" : ""
                  }`}
                  placeholder="Account Number"
                  value={accountDetails?.accountNumber ? accountDetails?.accountNumber : null}
                  onChange={handleInputChange2}
                  disabled
                />
                {errors.amount && (
                  <span className="text-red-500 text-xs">{errors.amount}</span>
                )}
              </div>






              <div>
                <label className="block text-sm mb-1">A/C Name</label>
                <input
                  type="text"
                  className={`w-full p-2 bg-secondary uppercase rounded-md text-white ${
                    errors.amount ? "border-red-500" : ""
                  }`}
                  placeholder="Account Name"
                  value={accountDetails?.accountHolder ? accountDetails?.accountHolder : null}
                  onChange={handleInputChange2}
                  disabled
                />
                {/* {errors.amount && (
                  <span className="text-red-500 text-xs">{errors.amount}</span>
                )} */}
              </div>













              <div>
                <label className="block text-sm mb-1">Amount</label>
                <input
                  type="number"
                  className={`w-full p-2 bg-secondary rounded-md text-white ${
                    errors.amount ? "border-red-500" : ""
                  }`}
                  placeholder="Amount"
                  value={amount}
                  onChange={handleInputChange2}
                />
                {errors.amount && (
                  <span className="text-red-500 text-xs">{errors.amount}</span>
                )}
              </div>
            </div>

            <div className="flex flex-wrap justify-center gap-1 mt-10">
              {[300, 1000, 2000, 5000, 10000, 25000].map((presetAmount) => (
                <button
                  key={presetAmount}
                  type="button"
                  className="bg-secondary px-4 py-1 text-sm font-bold rounded-full text-white"
                  onClick={() => handleQuickAmountSelect(presetAmount)}
                >
                  {presetAmount}
                </button>
              ))}
            </div>

            <button
              type="submit"
              className="bg-button px-10 py-2 mt-6 text-sm rounded-md block mx-auto"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Withdrwal;
