function Loader() {
  return (
    <div className="flex w-full h-[75vh] bg-black/70 justify-center items-center">
      <img
        className="w-auto h-auto max-w-[500px] max-h-[200px] md:max-w-[500px] md:max-h-[200px] sm:max-w-[300px] sm:max-h-[150px] p-4"
        src="/assets/appImages/preloader.svg"
        alt="Loading..."
      />
    </div>
  );
}

export default Loader;
