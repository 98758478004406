import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import "./casino.css";
import Teenpattiondayrules from './images/tponeday-rules.jpg';
import BetLocked from "./casino_components/BetLocked";
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import CasinoTab from "./casino_components/CasinoTab";
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
// import { httpPost, httpPostBet } from "../../../../middelware/Http";
// import Loader from "../../../../component/Loader/Loader";
import { message } from "antd";
import { httpPost, httpPostBet } from "../../../middelware/CasinoMiddleware/Http";
import Loader from "../../../component/Loader/Loader";


class Teenpattionday extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "Teen",
      betList: [],
      betresponse: [],
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      clicked: true,
      completeBetList: [],
      inCompleteBetList: [],
      activeTab: 1,
      LoadingBet: false,
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  handleTvScreen = () => {
    this.setState({ isTvScreen: !this.state.isTvScreen });
    this.setState(prevState => ({ clicked: !prevState.clicked }));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users && nextProps.users.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };


  handleBackOpen = (data) => {
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.odds, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time <= 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);

  };

  componentDidMount() {

    let requestData = {
      eventId: 3031,
    }
    this.getDidMountData(requestData)
    // this.socketConnection()
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "Teen",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 1000);
  }


  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }



  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }


  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }

  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });

  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.data.sectionId,
      "rate": this.state.count + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "Teen",
      "eventId": 3031,
      "betFor": this.state.betSlipData.nat + "",
      "isLay": this.state.betSlipData.type === "No" ? true : false,
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    console.log(result, "result")
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        message.success(result.message)
        // toast.success(<ErrorTost message={result.message} />);
        this.betList(3031)
      }
      else {
        message.error(result.message)
        // toast.error(<ErrorTost message={result.message} />);
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  handleClose = () => {
    this.setState({ backBetModal: false, backBetModalMobile: false });
  };

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };


  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, totalProfitLoss, clicked, minStake, maxStake, activeTab } = this.state;
    let { data, result } = casinoData ? casinoData : {};
    console.log(result, "232332323");
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let t2 = data && data.t1 && data.t1[1] ? data.t1[1] : {};
    let PlayerA = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let PlayerB = data && data.t1 && data.t1[1] ? data.t1[1] : {};
    return (
      <>
        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }
        {showLoader ?
          <Loader />
          
          :
          <div className={`w-full relative h-screen overflow-y-auto text-sm flex`}>
            {ResultModel &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Teenpatti onday"}
                shortName={shortName ? shortName : "teen"}
                result={this.state.result}
              />
            ) : null}

            <div className="lg:flex block w-full lg:pt-1 pt-0 scroll-md lg:space-x-2">

              {/* <div className="lg:w-[15%] w-full bg-fuchsia-200 lg:px-2 lg:block hidden"></div> */}

              <div className="lg:hidden block">
                <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={Teenpattiondayrules}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                />
              </div>

              <div className="w-full lg:flex">

                {activeTab === 1 ? (
                  <div className="w-full lg:flex block">
                    <div className="lg:w-[70%] w-full ">
                      <PageHeaderTheme
                        PageTitle={name ? name : "20-20 Teenpatti"}
                        ruleImage={Teenpattiondayrules}
                        t1={t1}
                      />

                      <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[460px] md:h-[300px] h-[200px] border border-[#cf4b04]">
                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                        <div className=" flex justify-between">
                          <div className="absolute top-0 left-0 w-full">
                            <div className="w-full p-1 space-y-1">
                              <div className="text-white font-semibold px-1 tracking-wide uppercase text-[14px]">Player A</div>
                              <div>
                                <div className="flex space-x-1 justify-start">
                                  <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-8 w-7 border-[1px] border-yellow-300" />
                                  <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="h-8 w-7 border-[1px] border-yellow-300" />
                                  <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="h-8 w-7 border-[1px] border-yellow-300" />
                                </div>
                                {/* <div className="text-white font-semibold p-[1px] tracking-wide bg-black/80 text-[12px]">Player A</div> */}
                              </div>

                              <div>
                                <div className="text-white font-semibold px-1 tracking-wide uppercase text-[14px]">Player B</div>
                                <div className="flex space-x-1 justify-start">
                                  <img src={`/cards/${t2 && t2.C1 ? t2.C1 : 1}.png`} alt="card" className="h-8 w-7 border-[1px] border-yellow-300" />
                                  <img src={`/cards/${t2 && t2.C2 ? t2.C2 : 1}.png`} alt="card" className="h-8 w-7 border-[1px] border-yellow-300" />
                                  <img src={`/cards/${t2 && t2.C3 ? t2.C3 : 1}.png`} alt="card" className="h-8 w-7 border-[1px] border-yellow-300" />
                                </div>
                                {/* <div className="text-white font-semibold p-[1px] tracking-wide bg-black/80 text-[12px]">Player B</div> */}
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end items-end absolute bottom-2 right-1">
                            <FlipCountdown
                              titlePosition='hidden'
                              hideYear
                              hideMonth
                              hideDay
                              hideHour
                              hideMinute
                              endAtZero
                              size='small'
                              endAt={new Date(Date.now() + 1000 * (t1 && t1.lasttime ? t1.lasttime : null)).toUTCString()}
                            />
                          </div>
                        </div>
                      </div>

                      <div className=" grey-color white-text divide-y border border-[#cf4b04] ">

                        <div className="grid lg:grid-cols-6 grid-cols-4 text-center darktext font-[600] lg:text-[16px] text-[14px] capitalize divide-x">
                          <div className="lg:col-span-4 col-span-2 w-full flex justify-start white-text items-center px-[6px] py-1"></div>
                          <div class="w-full flex justify-center items-center light-blue py-1">Back</div>
                          <div class="w-full flex justify-center items-center odds-khaii py-1">Lay</div>
                        </div>

                        <div className="w-full text-center grey-color">
                          <div className="grid lg:grid-cols-6 grid-cols-4 divide-x">
                            <div className="h-full lg:text-[16px] text-[14px] capitalize darktext font-[600] px-2 lg:col-span-4 col-span-2 w-full text-left">
                              <div>{PlayerA && PlayerA.nat ? PlayerA.nat : PlayerA.nation ? PlayerA.nation : "Player A"}</div>
                              <div className={`${posArray[PlayerA.sectionId] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[PlayerA.sectionId]}</div>
                            </div>
                            <div class="flex justify-end items-center divide-x w-full">
                              {PlayerA && PlayerA.gstatus === 'ACTIVE' && PlayerA.b1 != 0 ?
                                <div className="w-full light-blue cursor-pointer " onClick={() => this.handleBackOpen({ data: PlayerA, type: "Yes", odds: PlayerA.b1, nat: PlayerA.nat ? PlayerA.nat : PlayerA.nation, type: "Yes" }, this.section1Ref)}>
                                  <div className=" text-center py-2" >
                                    <span className="lg:text-[16px] text-[14px] darktext font-[600]">
                                      {(parseFloat(PlayerA && PlayerA.b1 ? (PlayerA.b1) / 100 + 1 : "0")).toFixed(2).replace(/\.?0+$/, "")}
                                    </span><br />
                                    {/* <span className="text-[12px] darktext font-medium">{PlayerA && PlayerA.bs1 ? (PlayerA.bs1) : "-"}</span> */}
                                  </div>
                                </div> :
                                <div className="w-full light-blue cursor-pointer " >
                                  <div className=" text-center py-2 relative lagai-background dark-text">
                                    {/* <span className='bg-white/50 px-1.5 py-2 border-[1px] border-black uppercase'>suspended</span> */}
                                    <span className="lg:text-[16px] text-[14px] darktext font-[600]">
                                      {(parseFloat(PlayerA && PlayerA.b1 ? (PlayerA.b1) / 100 + 1 : "0")).toFixed(2).replace(/\.?0+$/, "")}
                                    </span><br />
                                    {/* <span className="text-[12px] darktext font-medium">{PlayerA && PlayerA.bs1 ? (PlayerA.bs1)/100 : "-"}</span> */}
                                    <BetLocked />
                                  </div>
                                </div>}
                            </div>
                            <div class="flex justify-end items-center divide-x w-full">
                              {PlayerA && PlayerA.gstatus === 'ACTIVE' && PlayerA.l1 != 0 ?
                                <div className="w-full odds-khaii cursor-pointer " onClick={() => this.handleBackOpen({ data: PlayerA, type: "Yes", odds: PlayerA.l1, nat: PlayerA.nat ? PlayerA.nat : PlayerA.nation, type: "No" }, this.section1Ref)}>
                                  <div className=" text-center py-2" >
                                    <span className="lg:text-[16px] text-[14px] darktext font-[600]">
                                      {(parseFloat(PlayerA && PlayerA.l1 ? (PlayerA.l1) / 100 + 1 : "0")).toFixed(2).replace(/\.?0+$/, "")}
                                    </span><br />
                                    {/* <span className="text-[12px] darktext font-medium">{PlayerA && PlayerA.ls1 ? (PlayerA.ls1) : "-"}</span> */}
                                  </div>
                                </div> :
                                <div className="w-full odds-khaii cursor-pointer " >
                                  <div className=" text-center py-2 relative khai-background dark-text">
                                    {/* <span className='bg-white/50 px-1.5 py-2 border-[1px] border-black uppercase'>suspended</span> */}
                                    <span className="lg:text-[16px] text-[14px] darktext font-[600]">
                                      {(parseFloat(PlayerA && PlayerA.l1 ? (PlayerA.l1) / 100 + 1 : "0")).toFixed(2).replace(/\.?0+$/, "")}
                                    </span><br />
                                    {/* <span className="text-[12px] darktext font-medium">{PlayerA && PlayerA.ls1 ? (PlayerA.ls1) : "-"}</span> */}
                                    <BetLocked />
                                  </div>
                                </div>}
                            </div>
                          </div>
                        </div>

                        <div className="w-full text-center grey-color">
                          <div className="grid lg:grid-cols-6 grid-cols-4 divide-x">
                            <div className="h-full lg:text-[16px] text-[14px] capitalize darktext font-[600] px-2 lg:col-span-4 col-span-2 w-full text-left">
                              <div>{PlayerB && PlayerB.nat ? PlayerB.nat : PlayerB.nation ? PlayerB.nation : "Player B"} </div>
                              <div className={`${posArray[PlayerB.sectionId] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[PlayerB.sectionId]}</div>
                            </div>
                            <div class="flex justify-end items-center w-full">
                              {PlayerB && PlayerB.gstatus === 'ACTIVE' && PlayerB.b1 !== "0.00" ?
                                <div className="w-full light-blue cursor-pointer " onClick={() => this.handleBackOpen({ data: PlayerB, type: "Yes", odds: PlayerB.b1, nat: PlayerB.nat ? PlayerB.nat : PlayerB.nation, type: "Yes" }, this.section1Ref)}>
                                  <div className=" text-center py-2" >
                                    <span className="lg:text-[16px] text-[14px] darktext font-[600]">
                                      {(parseFloat(PlayerB && PlayerB.b1 ? (PlayerB.b1) / 100 + 1 : "0")).toFixed(2).replace(/\.?0+$/, "")}
                                    </span><br />
                                    {/* <span className="text-[12px] darktext font-medium">{PlayerB && PlayerB.bs1 ? (PlayerB.bs1) : "-"}</span> */}
                                  </div>
                                </div> :
                                <div className="w-full light-blue cursor-pointer " >
                                  <div className=" text-center py-2 relative lagai-background dark-text">
                                    {/* <span className='bg-white/50 px-1.5 py-2 border-[1px] border-black uppercase'>suspended</span> */}
                                    <span className="lg:text-[16px] text-[14px] darktext font-[600]">
                                      {(parseFloat(PlayerB && PlayerB.b1 ? (PlayerB.b1) / 100 + 1 : "0")).toFixed(2).replace(/\.?0+$/, "")}
                                    </span><br />
                                    {/* <span className="text-[12px] darktext font-medium">{PlayerB && PlayerB.bs1 ? (PlayerB.bs1) : "-"}</span> */}
                                    <BetLocked />
                                  </div>
                                </div>}
                            </div>
                            <div class="flex justify-end items-center w-full">
                              {PlayerB && PlayerB.gstatus === 'ACTIVE' && PlayerB.l1 !== "0.00" ?
                                <div className="w-full odds-khaii cursor-pointer " onClick={() => this.handleBackOpen({ data: PlayerB, type: "Yes", odds: PlayerB.l1, nat: PlayerB.nat ? PlayerB.nat : PlayerB.nation, type: "No" }, this.section1Ref)}>
                                  <div className=" text-center py-2" >
                                    <span className="lg:text-[16px] text-[14px] darktext font-[600]">
                                      {(parseFloat(PlayerB && PlayerB.l1 ? (PlayerB.l1) / 100 + 1 : "0")).toFixed(2).replace(/\.?0+$/, "")}
                                    </span><br />
                                    {/* <span className="text-[12px] darktext font-medium">{PlayerB && PlayerB.ls1 ? (PlayerB.ls1) : "-"}</span> */}
                                  </div>
                                </div> :
                                <div className="w-full odds-khaii cursor-pointer " >
                                  <div className=" text-center py-2 relative khai-background dark-text">
                                    {/* <span className='bg-white/50 px-1.5 py-2 border-[1px] border-black uppercase'>suspended</span> */}
                                    <span className="lg:text-[16px] text-[14px] darktext font-[600]">
                                      {(parseFloat(PlayerB && PlayerB.l1 ? (PlayerB.l1) / 100 + 1 : "0")).toFixed(2).replace(/\.?0+$/, "")}
                                    </span><br />
                                    {/* <span className="text-[12px] darktext font-medium">{PlayerB && PlayerB.ls1 ? (PlayerB.ls1) : "-"}</span> */}
                                    <BetLocked />
                                  </div>
                                </div>}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className=" pb-2 bg-black/5 mt-0.5 border border-[#cf4b04]">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 justify-end items-center py-1.5 px-2">
                          {result && result.length > 0 ? result.map((element, index) => (
                            <div onClick={() => this.handleResultModel(element)} className="result-color w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                              <p className="text-[#ffff33] font-[600] text-[13px]">{element && element.result === '2' ? "B" : element && element.result === '1' ? <p className="text-[#FF4502] font-[600] text-[13px]">A</p> : "-"}</p>
                            </div>
                          )) : null}
                        </div>
                      </div>

                    </div>


                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                    />


                  </div>
                ) : null}

                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}


              </div>
            </div>
          </div >}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Teenpattionday);