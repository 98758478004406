import React from 'react'
import { data } from './informationJson';

const  Information = () => {
 

        const sortedData = [...data].sort((a, b) => a.order - b.order);
        return (
          <div className=" text-white min-h-screen p-6">
            <div className="">
              {sortedData.map((item) => (
                <div
                  key={item.id}
                  className="flex items-start border border-[#343131] p-6 rounded-lg mb-6 transition-colors relative"
                >
              
                  <div className='absolute -top-[16px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-satrt items-center bg-black px-2'>
                    <div className="text-xl ">{item.icon}</div>
                    <h2 className="text-md font-semibold">{item.title}</h2>
      
                  </div>
                  
                  <div className="">
                  <p className="text-[13px] text-white leading-relaxed">{item.content1}</p>
                  <p className="text-[13px] text-white leading-relaxed">{item.content2}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
      
        );

}

export default Information