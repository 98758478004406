import { useState } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import moment from "moment";

function BetListComponentMobile({ oddsBetData, fancyBetData, matchName,setBetListModal }) {
  const [cricketOpen, setCricketOpen] = useState(true);

  return (
    <div className="rounded-md pb-2 border bg-secondary border-secondary">
      
      <div className="flex text-white bg-button justify-between items-center  px-2 ">
        <div className="flex gap-2 py-1 text-sm font-semibold text-black">My Bets</div>
        <div onClick={()=>setBetListModal(false)}  className="text-xs bg-black rounded-full px-2 py-1 text-center">
          X
        </div>
      </div>
      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out ${
          cricketOpen ? "max-h-[5000px]" : "max-h-0"
        }`}
      >
        <div className="w-full border-b-2 border-button text-xs text-white flex justify-between items-center py-1 px-2">
          <div>Matched Bets</div>
          <div className="flex gap-2 items-center">
            <input type="checkbox" />
            Average Odds
          </div>
        </div>
        <div>
          <div className="w-full text-white flex justify-center items-center text-center text-xs border-b border-white py-2 ">
            {matchName}
          </div>
          <div className="rounded-t-lg space-y-3 py-1">
            <div className="bet_list_header_div">
              <div className="bet_list_grid">
                {/* Header */}
                <div className="grid grid-cols-5 gap-2 text-xs text-white py-1 border-b capitalize border-white mb-2  justify-items-center">
                  <div className="flex justify-center items-center font-semibold">
                    market
                  </div>
                  <div className="font-semibold">Runs</div>
                  <div className="font-semibold">Odds</div>
                  <div className="font-semibold">Stake</div>
                  <div className="font-semibold">P/L</div>
                </div>

                {oddsBetData.filter((e) => e.oddsType !== "matchOdds").length >
                  0 && (
                  <div className="w-full text-white flex justify-center items-center text-center text-xs  py-1 ">
                    Bookmaker
                  </div>
                )}
                {oddsBetData
                  .filter((e) => e.oddsType !== "matchOdds")
                  .map((element, index) => (
                    <div
                      key={index}
                      className={`grid grid-cols-5 justify-items-center  gap-2 text-xs ${
                        element.type === "K" ? "bg-[#f9e6ed] " : "bg-[#c9e6ef] "
                      } py-2`}
                    >
                      <div className="font-bold">{element.teamName}</div>
                      <div>-</div>
                      <div>
                        {parseFloat(Number(element.odds))
                          .toFixed(2)
                          .replace(/\.?0+$/, "")}
                      </div>

                      <div>
                        {parseFloat(element.amount)
                          .toFixed(2)
                          .replace(/\.?0+$/, "")}
                      </div>
                      <div>
                        {element.positionInfo && element.selectionId
                          ? parseFloat(
                              element.positionInfo[element.selectionId]
                            )
                              .toFixed(2)
                              .replace(/\.?0+$/, "")
                          : 0}
                      </div>
                    </div>
                  ))}
                {oddsBetData.filter((e) => e.oddsType === "matchOdds").length >
                  0 && (
                  <div className="w-full text-white flex justify-center items-center text-center text-xs  py-1 ">
                    Match Odds
                  </div>
                )}
                {oddsBetData
                  .filter((e) => e.oddsType === "matchOdds")
                  .map((element, index) => (
                    <div
                      key={index}
                      className={`grid grid-cols-5 justify-items-center  gap-2 text-xs ${
                        element.type === "K" ? "bg-[#f9e6ed] " : "bg-[#c9e6ef] "
                      } py-2`}
                    >
                      <div className="font-bold">{element.teamName}</div>
                      <div>-</div>
                      <div>
                        {parseFloat(Number(element.odds))
                          .toFixed(2)
                          .replace(/\.?0+$/, "")}
                      </div>

                      <div>
                        {parseFloat(element.amount)
                          .toFixed(2)
                          .replace(/\.?0+$/, "")}
                      </div>
                      <div>
                        {element.positionInfo && element.selectionId
                          ? parseFloat(
                              element.positionInfo[element.selectionId]
                            )
                              .toFixed(2)
                              .replace(/\.?0+$/, "")
                          : 0}
                      </div>
                    </div>
                  ))}

                {fancyBetData.map((element, index) => (
                  <>
                    <div className="w-full text-white flex justify-center items-center text-center text-xs  py-1 ">
                      {element?.sessionName}
                    </div>
                    <div
                      key={index}
                      className={`grid grid-cols-5 justify-items-center  gap-2 text-xs ${
                        element.type === "N" ? "bg-[#f9e6ed] " : "bg-[#c9e6ef] "
                      } py-2`}
                    >
                      <div className="font-bold">The Bet</div>
                      <div>{element?.run}</div>
                      <div>
                        {parseFloat(Number(element.odds))
                          .toFixed(2)
                          .replace(/\.?0+$/, "")}
                      </div>

                      <div>
                        {parseFloat(element.amount)
                          .toFixed(2)
                          .replace(/\.?0+$/, "")}
                      </div>
                      <div>
                        {Number.parseFloat(
                          Math.abs(element && element.loss ? element.loss : 0)
                        )
                          .toFixed(2)
                          .replace(/\.?0+$/, "")}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-b-2 border-button text-xs text-white flex justify-between items-center py-1 px-2 mt-2">
          <div>Unmatched Bets</div>
        </div>
      </div>
    </div>
  );
}

export default BetListComponentMobile;
