import React from 'react'
import { AiOutlineCheck } from 'react-icons/ai';

const ProfileModal = ({ handleCancel, handleChange, isToggled }) => {

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-main bg-opacity-50">
            <div className="bg-secondary p-6 rounded-lg shadow-lg space-y-4 border border-secondary w-[500px] max-w-[95vw]">
                <h2 className="text-sm text-center font-bold">Are you sure want to {isToggled ? "Deactivated" : "Activated"} this account?</h2>
                <div className='mb-3 flex justify-between items-center px-2 text-[14px]'>
                    <span className='text-button'>Holder Name:</span>
                    <span className='font-bold'>Shakti  Kumar </span>
                </div>
                <div className='mb-3 flex justify-between items-center px-2 text-[14px]'>
                    <span className='text-button'>Account #:</span>
                    <span className='font-bold'>1441001700307646 </span>
                </div>
                <div className='mb-3 flex justify-between items-center px-2 text-[14px]'>
                    <span className='text-button'>Payment Method:</span>
                    <span className='font-bold'>Bank</span>
                </div>
                <div className="flex space-x-4">
                    <button
                        onClick={handleCancel}
                        className="h-[40px] capitalize w-full font-bold text-xs px-2 border-[6px] border-transparent rounded-lg flex justify-center items-center whitespace-nowrap text-white bg-[conic-gradient(from_0deg,#f15a24,#e4440a)]"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleChange}
                        className="px-4 py-2 bg-[#198754] text-white rounded w-full flex justify-center items-center space-x-2"
                    >
                        <span>Yes</span> <AiOutlineCheck />
                    </button>
                </div>
            </div>
        </div>

    );

}

export default ProfileModal