import Sidebar from "../component/sidebar/Sidebar";
import Dashboard from "../pages/Dashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../component/header/Header";
import { useState } from "react";
import Footer from "../component/footer/Footer";
import Profile from "../pages/Profile";
import AccountStatement from "../pages/AccountStatement";
import Deposit from "../component/Deposit/Deposit";
import MobileFooter from "../component/MobileFooter/MobileFooter";
import Withdrawal from "../component/Withdrawal/Withdrawal";
import MatchViewDeatils from "../pages/matchDeatils";
import ProfitLoss from "../pages/ProfitLoss";
import Information from "../component/information/Information";
import Contact from "../component/content/content";
import CasinoList from "../pages/CasinoList";
import Teenpattit20 from "../pages/DiamondCasino/Casino/Teenpattit20";
import Worlimatka from "../pages/DiamondCasino/Casino/Worlimatka";
import Dragontiger20 from "../pages/DiamondCasino/Casino/Dragontiger20";
import Teenpattionday from "../pages/DiamondCasino/Casino/Teenpattionday";
import Lucky7b from "../pages/DiamondCasino/Casino/Lucky7b";
import Amarakbaranthony from "../pages/DiamondCasino/Casino/Amarakbaranthony";
import Cards32A from "../pages/DiamondCasino/Casino/Cards32A";
import Dragontiger202 from "../pages/DiamondCasino/Casino/Dragontiger202";
import Cards32B from "../pages/DiamondCasino/Casino/cards32B";
import Teenpattittest from "../pages/DiamondCasino/Casino/Teenpattittest";
import IframeCasino from "../pages/DiamondCasino/Casino/IframeCasino/IframeCasino";
import DepositWithdrwal from "../pages/DepositWithdrwal";
import Inplay from "../pages/Inplay";
import PrivateRoute from "../routes/PrivateRoute";
import IframeQtech from "../pages/DiamondCasino/Casino/IframeCasino/IframeQtech";
import SlotGames from "../pages/SlotGames";
import EditStack from "../component/EditStake/EditStake";
import IframeCasinonew from "../pages/DiamondCasino/Casino/IframeCasino/IframeCasinonew";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  return (
    <section className="bg-black px-2 w-full h-screen overflow-hidden pt-2">
      <div className="flex lg:gap-2">
        <div>
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </div>
        <div className="xl:w-[calc(100vw-11rem)] w-full ">
          <Header setSidebarOpen={setIsSidebarOpen} />
          <div className="h-[90vh] md:h-[90vh] xl:h-[90vh] overflow-auto pb-12 sm:mt-0 ">
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />}  />
              <Route path="/inplay"  element={<Inplay />}  />
              <Route path="/match-details/:marketId?/:eventId?" element={<PrivateRoute element={<MatchViewDeatils />} />} />
              <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
              <Route path="/account-statement" element={<PrivateRoute element={<AccountStatement />} />} />
              <Route path="/deposit-withdrwal" element={<PrivateRoute element={<DepositWithdrwal />} />} />
              <Route path="/profit-loss" element={<PrivateRoute element={<ProfitLoss />} />} />
              <Route path="/deposit" element={<PrivateRoute element={<Deposit />} />} />
              <Route path="/withdrawal" element={<PrivateRoute element={<Withdrawal />} />} />
              <Route path="/information" element={<PrivateRoute element={<Information />} />} />
              <Route path="/contact" element={<PrivateRoute element={<Contact />} />} />
              <Route path="/casino" element={<CasinoList />}  />
              <Route path="/Slot-games" element={<SlotGames />}  />
              <Route path="/teen20/:eventId" element={<PrivateRoute element={<Teenpattit20 />} />} />
              <Route path="/worli/:eventId" element={<PrivateRoute element={<Worlimatka />} />} />
              <Route path="/dt20/:eventId" element={<PrivateRoute element={<Dragontiger20 />} />} />
              <Route path="/teen/:eventId" element={<PrivateRoute element={<Teenpattionday />} />} />
              <Route path="/lucky7eu/:eventId" element={<PrivateRoute element={<Lucky7b />} />} />
              <Route path="/card32-a/:eventId" element={<PrivateRoute element={<Cards32A />} />} />
              <Route path="/aaa/:eventId" element={<PrivateRoute element={<Amarakbaranthony />} />} />
              <Route path="/dt202/:eventId" element={<PrivateRoute element={<Dragontiger202 />} />} />
              <Route path="/card32eu/:eventId" element={<PrivateRoute element={<Cards32B />} />} />
              <Route path="/teen9/:eventId" element={<PrivateRoute element={<Teenpattittest />} />} />
              <Route path="/iframe-casino/:gameId?" element={<PrivateRoute element={<IframeCasino />} />} />
              <Route path="/iframe-casino-new/:provider?/:gameId?" element={<PrivateRoute element={<IframeCasinonew />} />} />
              <Route path="/iframe-qtech-casino/:gameId?" element={<PrivateRoute element={<IframeQtech />} />} />
             
            </Routes>
          </div>
          <MobileFooter />
        </div>
      </div>
    </section>
  );
};

export default Layout;
