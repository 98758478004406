import React, { useState } from "react";

const VideoModal = ({ videoSrc, closeModal }) => {
  return (
    <div className="fixed inset-0 bg-[#141414] rounded-2xl flex justify-center items-center z-50">
      <div className="bg-[#141414]  rounded-md">
        <button
          className="absolute top-2 right-2 font-bold text-white p-2 rounded-full"
          onClick={closeModal}
        >
          X
        </button>
        <div className="w-full bg-[#141414]">
          <video
            src={videoSrc}
            controls
            // autoPlay
            className="w-[300px] h-[600px] rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoModal;