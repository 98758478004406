import moment from "moment";
import { useEffect, useState } from "react";
import { FaTv } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function DashboardInplay() {
  const [tab, setTab] = useState(1);
  const [sportsId, setSportsId] = useState(4);
  const [matchData, setMatchData] = useState(4);
  const navigate = useNavigate()
  const matchlistLocal = localStorage.getItem("matchList") ? JSON.parse(localStorage.getItem("matchList")) : null
  const { matchList } = useSelector(state => state.user)
  useEffect(() => {
    let matchListData = matchlistLocal ? matchlistLocal : matchList;
    if (tab === 1) {
      const sortedMatchList = matchListData ? matchListData?.filter(match => match.status === "INPLAY").sort((a, b) => a.priority - b.priority) : [];
      const filteredAndSortedData = sortedMatchList?.filter(element => element.sportId === sportsId)
      setMatchData(filteredAndSortedData);
    } else {
      const sortedMatchList = matchListData?.filter(match => match.status !== "INPLAY").sort((a, b) => a.priority - b.priority);
      const filteredAndSortedData = sortedMatchList?.filter(element => element.sportId === sportsId)
      setMatchData(filteredAndSortedData);
    }
  }, [matchList, sportsId, tab]);




  return (
    <div>
      <div className="rounded-md mt-3.5 mb-2 md:pb-2 border border-secondary">
        <div className="flex bg-secondary justify-between items-center  px-2 py-1.2">
          <div className="flex justify-center md:gap-4 py-2 text-sm font-semibold">
            <button
              onClick={() => setTab(1)}
              className={`text-white ${tab === 1 && "bg-orange-600"
                } md:px-5 px-4 py-[5px] rounded-[4px] outline-none md:text-[14px] text-[12px]`}
            >
              In Play
            </button>
            <button
              onClick={() => setTab(2)}
              className={`text-white  ${tab === 2 && "bg-orange-600"
                } md:px-5 px-4 py-[5px] rounded-[4px] outline-none md:text-[14px] text-[12px]`}
            >
              Upcoming
            </button>
          </div>
        </div>
        <div className="flex px-2 gap-1 overflow-x-auto ">
          <div className="flex jus overflow-x-auto gap-2 md:px-1.5 px-0 py-2 ">
            <button
              onClick={() => setSportsId(4)}
              className={`bg-secondary text-white flex flex-col justify-center items-center border  md:text-[12px] text-[9px] px-2 py-1 rounded outline-none ${sportsId === 4 ? "border-tabBorder" : "border-gray-500"
                }`}
            >
              <img alt="" className="w-4 h-4" src="\assets\appImages\magic-win-biz\assets\icons\sports\Cricket.png" />
              <p className="md:my-0 text-sm my-1">Cricket</p>
            </button>

            <button
              onClick={() => setSportsId(1)}
              className={`bg-secondary text-white flex flex-col justify-center items-center border  md:text-[12px] text-[9px] px-2 py-1 rounded outline-none ${sportsId === 1 ? "border-tabBorder" : "border-gray-500"
                }`}
            >
              <img alt="" className="w-4 h-4" src="\assets\appImages\magic-win-biz\assets\icons\sports\Soccer.png" />
                <p className="md:my-0 text-sm my-1">Soccer</p>
            </button>
            <button
              onClick={() => setSportsId(2)}
              className={`bg-secondary text-white flex flex-col justify-center items-center border  md:text-[12px] text-[9px] px-2 py-1 rounded outline-none ${sportsId === 2 ? "border-tabBorder" : "border-gray-500"
                }`}
            >
              <img alt="" className="w-4 h-4" src="\assets\appImages\magic-win-biz\assets\icons\sports\Tennis.png" />
                <p className="md:my-0 text-sm my-1">Tennis</p>
            </button>
          </div>
        </div>

        <div className="">
          <div className="space-y-2 rounded px-2 md:mb-4 mb-2">
            {(matchData && matchData?.length > 0) ?
              matchData?.map((match, index) => {
                return <div onClick={() => {
                  localStorage.getItem('token') ? window.location.href = `/match-details/${match?.marketId}/${match?.eventId}` : localStorage.setItem("unauthorized", true);
                }} key={index} className="grid bg-primary px-2 py-2 grid-cols-1 lg:grid-cols-2 rounded-lg justify-between items-center">
                  <div className="flex md:items-center justify-between">
                    <div>
                      <div className="text-[9px] text-button font-semibold md:pl-3.5">
                        {match?.matchDate}
                      </div>
                      <div className=" flex items-center gap-2 md:text-xs text-[11px] font-bold text-white">
                        {/* {match.status === "INPLAY" && <div className="w-2 h-2 rounded-full animate-ping bg-green-500"> </div>} */}
                        {
                          (() => {
                            const inputMoment = moment(match?.matchDate, "DD-MM-YYYY HH:mm:ss A");
                            const currentMoment = moment();
                            return currentMoment.isSameOrAfter(inputMoment) ?
                              <div className="md:w-2 w-[5px] md:h-2 h-[5px] rounded-full animate-ping bg-green-500"> </div>
                              : '';
                          })()
                        }


                        {match.matchName}
                      </div>
                    </div>
                    <div className="lg:hidden flex md:items-center gap-1 px-1 ">
                      <FaTv className="text-button" />
                      <div className="text-center px-1 py-0.5 bg-slate-100/20 text-white font-bold text-[8px] rounded-sm md:h-auto h-[14px]" >BM</div>
                      <div className="text-center px-1 py-0.5 bg-slate-100/20 text-white font-bold text-[8px] rounded-sm md:h-auto h-[14px]" >F</div>
                    </div>
                  </div>
                  <div className="flex justify-end md:gap-3 md:mt-0 mt-2">
                    <div className="lg:flex hidden items-center gap-1 px-1 ">
                      <FaTv className="text-button" />
                      <div className="text-center px-1 py-0.5 bg-slate-100/20 text-white font-bold text-[8px] rounded-sm md:h-auto h-[14px]" >BM</div>
                      <div className="text-center px-1 py-0.5 bg-slate-100/20 text-white font-bold text-[8px] rounded-sm md:h-auto h-[14px]" >F</div>
                    </div>
                    <div className=" w-full lg:w-[400px] grid grid-cols-6 gap-2 font-semibold">
                      <button className="bg-yes md:text-[12px] text-[11px]  h-[40px] text-center py-2 rounded">
                        2.1
                      </button>
                      <button className="bg-no md:text-[12px] text-[11px]  h-[40px] text-center py-2 rounded">
                        2.12
                      </button>
                      <button className="bg-yes md:text-[12px] text-[11px]  h-[40px] text-center py-2 rounded">
                        -
                      </button>
                      <button className="bg-no md:text-[12px] text-[11px]  h-[40px] text-center py-2 rounded">
                        1.89
                      </button>
                      <button className="bg-yes md:text-[12px] text-[11px]  h-[40px] text-center py-2 rounded">
                        -
                      </button>
                      <button className="bg-no md:text-[12px] text-[11px]  h-[40px] text-center py-2 rounded">
                        1.89
                      </button>
                    </div>
                  </div>
                </div>
              })
              :
              <div className="w-full text-center text-white font-semibold py-3 bg-primary rounded-lg"> No data Found</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardInplay;
