import React, { useEffect, useState, useRef } from "react";
import { FaPlay } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SPORTSCONSTANT } from "../../middelware/global";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const navigate = useNavigate();
  const [matchData, setMatchData] = useState([]);
  const [matchDataLocal, setMatchDataLocal] = useState([]);

  const matchlistLocal = localStorage.getItem("matchList")
    ? JSON.parse(localStorage.getItem("matchList"))
    : null;
  const { matchList } = useSelector((state) => state.user);
  const sidebarRef = useRef(null); // Ref to the sidebar element

  useEffect(() => {
    let matchListData = matchlistLocal ? matchlistLocal : matchList;
    setMatchDataLocal(matchListData)
    const printingData = organizeData(matchListData);
    setMatchData(printingData);
  }, [matchList]);

  const organizeData = (data) => {
    if (!data) return [];
    const organizedData = [];
    data?.forEach((item) => {
      const { sportId, seriesId, seriesName, createdAt } = item;
      let sportIndex = organizedData?.findIndex(
        (sport) => sport.sportId === sportId
      );
      if (sportIndex === -1) {
        sportIndex = organizedData.length;
        organizedData.push({ sportId, series: [] });
      }
      let seriesIndex = organizedData[sportIndex].series.findIndex(
        (series) => series.seriesId === seriesId
      );
      if (seriesIndex === -1) {
        seriesIndex = organizedData[sportIndex].series.length;
        organizedData[sportIndex].series.push({
          seriesId,
          seriesName,
          matches: [],
        });
      }
      organizedData[sportIndex].series[seriesIndex].matches.push(item);
    });
    return organizedData;
  };

  const [openKeys, setOpenKeys] = useState([]);
  const [openKeys1, setOpenKeys1] = useState({});

  const handleClick = (index, e) => {
    e.stopPropagation();
    if (openKeys.includes(index)) {
      setOpenKeys(openKeys.filter((key) => key !== index));
    } else {
      setOpenKeys([...openKeys, index]);
    }
  };

  const handleClick1 = (sportIndex, seriesIndex, e) => {
    e.stopPropagation();
    const key = `${sportIndex}-${seriesIndex}`;
    setOpenKeys1((prevOpenKeys1) => ({
      ...prevOpenKeys1,
      [key]: !prevOpenKeys1[key],
    }));
  };

  const handleNavigate = (item) => {
    localStorage.getItem('token') ?   window.location.href = item : localStorage.setItem("unauthorized", true);

  };

  // Close sidebar when clicked outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        setIsSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen, setIsSidebarOpen]);

  return (
    <div
    ref={sidebarRef}
    className={`${
      isSidebarOpen
        ? "translate-x-0 opacity-100"
        : "-translate-x-full opacity-0"
    } xl:translate-x-0 xl:opacity-100 xl:block w-64 xl:w-44 h-screen sidebarbg z-30 text-white fixed xl:relative top-0 left-0 bottom-0 transition-all duration-300`}
  >
       <div className="p-2 mx-4 flex items-center justify-between border-gray-700">
      <button
        onClick={() => setIsSidebarOpen(false)}
        className="text-gray-400 hover:text-white text-3xl block xl:hidden"
      >
        &times;
      </button>
      <img
        onClick={() => {
          navigate("/");
        }}
        src="/assets/logo.png"
        alt="Magic Win"
        className="h-8"
      />
    </div>
    <div className="hidden xl:block">
 
    <ul className="mt-4 space-y-4">
      <li onClick={()=>navigate("/inplay")} className="flex items-center px-4 py-1.5 gap-3 text-sm bg-black ml-2 mr-0 rounded-l-full">
        <img src="/assets/sidebarimg/179.svg" alt="" />
        <span>In Play</span>
      </li>
      <li  onClick={()=>{   localStorage.getItem('token') ?   navigate('/account-statement') : localStorage.setItem("unauthorized", true);}} className="flex items-center px-4 py-1.5 gap-3 text-sm bg-black ml-2 mr-0 rounded-l-full">
        <img alt="" src="/assets/sidebarimg/181.svg" />
        <span>Transactions</span>
      </li>
    </ul>
  
    <div className="my-2">
      <div className="bg-gradient-to-r from-orange-500 to-orange-950 rounded-l-full ml-2 flex items-center justify-between space-x-2 h-8 cursor-pointer bg-header_bg-900">
        <span className="select-none text-sm pl-3 w-full font-bold uppercase text-black">
          Sports
        </span>
      </div>
      {SPORTSCONSTANT?.map((menuItem, index) => {
        const sport = matchData?.find(
          (sport) => sport.sportId.toString() === menuItem.count
        );
        return (
          <div
            key={index}
            className={`text-white overflow-hidden py-0 my-0 transition-[max-height] duration-300 max-h-96 ease-in`}
          >
            <div className="cursor-pointer">
              <div
                className="uppercase px-3 py-1 my-0 ml-0 w-full space-x-2 font-normal text-sm inline-flex items-center"
                onClick={(e) => handleClick(index, e)}
              >
                <span>
                  {openKeys.includes(index) ? (
                    <AiOutlineMinusSquare />
                  ) : (
                    <AiOutlinePlusSquare />
                  )}
                </span>
                <span>{menuItem.text}</span>
              </div>
              {sport && openKeys.includes(index) && (
                <div className="ml-4 py-0 my-0">
                  {sport?.series?.map((series, seriesIndex) => (
                    <div key={seriesIndex} className="">
                      <div
                        className="text-xs relative px-0 text-white w-full py-0 my-0 space-x-2 font-normal inline-flex items-center cursor-pointer"
                        onClick={(e) => handleClick1(index, seriesIndex, e)}
                      >
                        {openKeys1[`${index}-${seriesIndex}`] ? (
                          <AiOutlineMinusSquare />
                        ) : (
                          <AiOutlinePlusSquare />
                        )}
                        <span className="px-2 py-0 my-0">
                          {series.seriesName ? series.seriesName : "N/A"}
                        </span>
                      </div>
                      {openKeys1[`${index}-${seriesIndex}`] && (
                        <div className=" py-0 my-0">
                          {series.matches.map((item) => (
                            <li
                              key={item._id}
                              className="text-xs relative py-0 my-0  px-0  mb-2  text-white  w-full  space-x-0.5 font-normal inline-flex items-center cursor-pointer"
                              onClick={(e) => {
                                handleNavigate(
                                  `/match-details/${item?.marketId}/${item?.eventId}`
                                );
                              }}
                            >
                              <span>
                                <FaPlay
                                  size={8}
                                  className="text-white mx-2"
                                />
                              </span>
                              <span> {item.matchName}</span>
                            </li>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
    </div>
    <div className="block xl:hidden ml-0.5">
    <ul className="">
          
            <li onClick={()=>{
               setIsSidebarOpen(false)
               
              navigate("/inplay")
              }} className="flex items-center justify-between border-b border-gray-400 font-semibold  text-sm bg-gradient-to-r from-[#6DA80D] to-green-950 my-1 p-2">
            <div  className="flex gap-2"><span><img alt="" src={'/assets/appImages/magic-win-biz/assets/icons/sports/inplay.png'} className="w-5 h-5 bg-white rounded-full" /></span><span>In Play</span></div>
              <span className="bg-button  text-black font-semibold  text-xs rounded px-2 py-1">{matchDataLocal?.filter((match)=>match.status === "INPLAY")?.length}</span>
            </li>

            
            <li onClick={()=>{
               setIsSidebarOpen(false)
              navigate("/inplay")
              }} className="flex items-center justify-between border-b border-gray-400 font-semibold  text-sm  my-1 p-2">
            <div className="flex gap-2"><span><img alt="" src={'/assets/appImages/magic-win-biz/assets/icons/sports/calendar.png'} className="w-5 h-5" /></span><span>Upcoming</span></div>
              <span className="bg-button  text-black font-semibold  text-xs rounded px-2 py-1">{matchDataLocal?.filter((match)=>match.status !== "INPLAY")?.length}</span>
            </li>

            <li className="bg-gradient-to-r from-orange-500 to-orange-950 px-2 py-2 uppercase text-sm mt-4">Sports</li>

            
            {SPORTSCONSTANT?.map((menuItem, index) => {
        const sport = matchData?.find(
          (sport) => sport.sportId.toString() === menuItem.count
        );
        return (
          <div
            key={index}
            className={`text-white overflow-hidden border-b   font-semibold border-gray-300 py-0 my-0 transition-[max-height] duration-300 max-h-96 ease-in`}
          >
            <div className="cursor-pointer">
              <div
                className={`uppercase px-3 py-0.5 my-0 ml-0 w-full space-x-2 font-normal text-sm inline-flex items-center `}
                onClick={(e) => handleClick(index, e)}
              >
                <span><img alt="" src={menuItem?.iconImg} className="w-5 h-5" /></span><span>{menuItem.text}</span>
              </div>
              {sport  && (
                <div className={`${openKeys.includes(index) ?'transition-[max-height] duration-500 max-h-96' :" transition-[max-height] duration-500 max-h-0"}`}>
                  {sport?.series?.map((series, seriesIndex) => (
                    <div key={seriesIndex} className={`border-b border-gray-300  `}>
                      <div
                        className="text-sm relative px-0 text-white w-full py-0 my-0 space-x-2 font-normal inline-flex items-center cursor-pointer"
                        onClick={(e) => handleClick1(index, seriesIndex, e)}
                      >
                        <div className={`px-2 py-0 my-0 w-full ${openKeys1[`${index}-${seriesIndex}`] ? "bg-secondary" : ""}`}>
                          {series.seriesName ? series.seriesName : "N/A"}
                        </div>
                      </div>
                      {openKeys1[`${index}-${seriesIndex}`] && (
                        <div className=" py-0 my-0">
                          {series.matches.map((item) => (
                            <li
                              key={item._id}
                              className="text-sm relative py-0 my-0  pl-4 border-b border-gray-300   mb-2  text-white  w-full  space-x-0.5 font-normal inline-flex items-center cursor-pointer"
                              onClick={(e) => {
                                setIsSidebarOpen(false)
                                handleNavigate(
                                  `/match-details/${item?.marketId}/${item?.eventId}`
                                );
                              }}
                            >
                              
                              <span> {item.matchName}</span>
                            </li>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      })}
          </ul>

    </div>
  </div>
  
  );
};

export default Sidebar;
