import {
  FaHome,
  FaFutbol,
  FaDice,
  FaThLarge,
  FaArrowLeft,
} from "react-icons/fa";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { useEffect, useState } from "react"; // Import useState
import ModalContent from "./ModalContent";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa6";
// import { LoginOutlined } from "@mui/icons-material";
import { MdLogout } from "react-icons/md";

function MobileFooter() {
  // State to handle drawer visibility
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  // Function to toggle drawer visibility
  const toggleDrawer = () => {
    localStorage.getItem("token")
      ? setIsDrawerOpen(!isDrawerOpen)
      : localStorage.setItem("unauthorized", true);
  };

  // Function to close the drawer
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
  const [balance, setBalance] = useState({
    coins: "",
    exposure: "",
  });
  useEffect(() => {
    setTimeout(() => {
      let Balance = JSON.parse(localStorage.getItem("clientBalance"));
      let clientExposure = JSON.parse(localStorage.getItem("clientExposure"));
      setBalance({
        coins: Balance,
        exposure: clientExposure,
      });
    }, 1000);
  }, [balance]);

  return (
    <div>
      <div className="fixed bottom-0 uppercase left-0 w-full block xl:hidden bg-secondary rounded-t-2xl px-2 py-2 z-30">
        <div className="flex w-full justify-between items-center rounded-full bg-black md:py-0.5 py-2.5 px-5">
          <div
            onClick={() => navigate("/")}
            className="flex flex-col items-center text-white"
          >
            <FaHome className="md:text-xl" size={17} />
            <span className="md:text-xs text-[8px] mt-1 capitalize font-medium">Home</span>
          </div>

          <div
            onClick={() => navigate("/inplay")}
            className="flex flex-col items-center text-white"
          >
            <FaFutbol className="text-2xl" size={17}/>
            <span className="md:text-xs text-[8px] mt-1 capitalize font-medium">Inplay</span>
          </div>

          <div
            onClick={() => {
              localStorage.getItem("token")
                ? navigate("/profile")
                : localStorage.setItem("unauthorized", true);
            }}
            className="flex flex-col items-center text-yellow-400 b24-widget-button-pulse-animate"
          >
            <div className="bg-yellow-500 relative rounded-full p-2">
              <div className="widget-pulse"></div>
              <FaUser className="text-xl text-black" size={17}/>
            </div>
          </div>

          <div
            onClick={() => navigate("/casino")}
            className="flex flex-col items-center text-white"
          >
            <FaDice className="text-2xl" size={17}/>
            <span className="md:text-xs text-[8px] mt-1 capitalize font-medium">Casino</span>
          </div>

          <div
            className="flex flex-col items-center text-white"
            onClick={toggleDrawer}
          >
            <FaThLarge className="text-xl" size={14}/>
            <span className="md:text-xs text-[8px] mt-1 capitalize font-medium">More</span>
          </div>
        </div>
      </div>

      <div
        className={`fixed bottom-0 block xl:hidden left-0 w-full h-screen z-40 bg-black/95 overflow-hidden transform transition-transform duration-800 ease-in-out ${
          isDrawerOpen ? "translate-y-0" : "translate-y-full"
        }`}
      >
        <div className="relative h-screen w-screen">
          <ModalContent setIsDrawerOpen={setIsDrawerOpen} />
          <div className="absolute bottom-0 left-0 flex justify-between  bg-black w-full p-2">
            <div className="flex justify-center items-center gap-2">
              <img
                className="w-14"
                src="/assets/appImages/icons/454.png"
                alt=""
              />
              <div className="p-2 text-white font-semibold bg-primary rounded-sm text-xs">
                Balance:{" "}
                <span
                  className={`${
                    balance && balance.coins && parseFloat(balance.coins) > 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {balance && balance.coins
                    ? parseFloat(balance.coins).toFixed(2)
                    : 0}
                </span>
                <br />
                Liability:{" "}
                <span
                  className={`${
                    balance &&
                    balance.exposure &&
                    parseFloat(balance.exposure) > 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {balance && balance.exposure
                    ? parseFloat(balance.exposure).toFixed(2)
                    : 0}
                </span>
              </div>
            </div>
            <div className="flex gap-2 justify-center items-center">
              <FaArrowLeft
                onClick={() => {
                  navigate("/");
                  setIsDrawerOpen(false);
                }}
                className="text-3xl p-1 text-white rounded-full bg-primary"
              />
              <RxCross2
                onClick={() => setIsDrawerOpen(false)}
                className="text-3xl text-red-600 rounded-full bg-primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileFooter;
