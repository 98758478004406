import React, { useState } from "react";
import VideoModal from "../videoSrc/VideoModal";

const Footer = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [videoSrc, setVideoSrc] = useState("");
  
    const openModal = (video) => {
      setVideoSrc(video);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      setVideoSrc(""); // Reset the video source when modal is closed
    };

    return (
        <footer className="bg-black text-white md:pb-0 pb-28">
            <div className='mx-auto max-w-[480px] mt-2 mb-3 rounded-lg p-3 flex justify-center items-center'>
                <div className='w-full bg-[#1A1A1A] h-20 text-white font-bold md:text-xs text-[10px] flex justify-between items-center rounded-md px-3.5'>
                    <div className='py-2'>
                        <span>Customer Support
                        </span><br />
                        <span> If you have any questions?
                        </span>
                    </div>
                    <div className=' h-[40px] border-[6px] border-transparent rounded-lg px-2 flex justify-center items-center whitespace-nowrap text-white bg-[conic-gradient(from_0deg,#f15a24,#e4440a)]'> Get Answer
                    </div>
                </div>
            </div>
         

            <div className="flex justify-center items-center mx-auto px-6 md:mt-10 mt-4">
                <div className="md:flex block justify-center items-center md:space-x-4 space-x-0 md:space-y-0 space-y-4">
                    <img src='https://assets-bucket.b-cdn.net/magic-win-biz/cms-content/assets/images/icons/455.png' alt='' className='max-w-[180px]' />
                    <img src='https://assets-bucket.b-cdn.net/magic-win-biz/assets/images/newUiAssets/download-app.svg' alt='' className='max-w-[180px]' />
                </div>
            </div>

            <div className="container mx-auto px-1 mt-10">
                <div className="grid grid-cols-2 md:grid-cols-6 md:gap-y-2 gap-y-3 text-left md:text-[12px] text-[10px] font-bold">
                    <a href="#" className="hover:text-gray-400">Betting Rules</a>
                    <a href="#" className="hover:text-gray-400">Privacy Policy</a>
                    <a href="#" className="hover:text-gray-400">About us</a>
                    <a href="#" className="hover:text-gray-400">Terms & Conditions</a>
                    <a href="#" className="hover:text-gray-400">FAQ's</a>
                    <a href="#" className="hover:text-gray-400">Cookie Policy</a>
                </div>
            </div>

            <div className=" mx-auto px-1 mt-12">
                <h3 className="text-[14px] text-center font-bold mb-4">Tutorials</h3>
                <div className="grid grid-cols-1 md:grid-cols-4 md:gap-3 gap-2.5 text-[11px] font-bold">
                    <button className="bg-[#1A1A1A] text-white md:px-4 px-2 md:text-[12px] text-[10px] py-3 flex items-center justify-between rounded-md" 
                     onClick={() => openModal("/assets/videoSrc/2041.mp4")}
                    >
                        How to Signup & Login? <span className="md:text-[13px] text-[16px]">▶</span>
                    </button>
                    <button className="bg-[#1A1A1A] text-white md:px-4 px-2 md:text-[12px] text-[10px] py-3 flex items-center justify-between rounded-md"
                    
                    onClick={() => openModal("/assets/videoSrc/2329.mp4")}
                    >
                        How to WhatsApp Deposit and Slip Upload? <span className="md:text-[13px] text-[16px]">▶</span>
                    </button>
                    <button className="bg-[#1A1A1A] text-white md:px-4 px-2 md:text-[12px] text-[10px] py-3 flex items-center justify-between rounded-md"
                    onClick={() => openModal("/assets/videoSrc/2045.mp4")}
                    >
                        How to Auto Deposit? <span className="md:text-[13px] text-[16px]">▶</span>
                    </button>
                    <button className="bg-[#1A1A1A] text-white md:px-4 px-2 md:text-[12px] text-[10px] py-3 flex items-center justify-between rounded-md"
                    onClick={() => openModal("/assets/videoSrc/2044.mp4")}
                    >
                        How to Withdraw? <span className="md:text-[13px] text-[16px]">▶</span>
                    </button>
                </div>
            </div>

            <div className="container mx-auto px-6 md:mt-10 mt-5 text-center">
                <h3 className="text-sm font-medium mb-4">Follow Us</h3>
                <div className="flex justify-center md:space-x-4 space-x-2">
                    <a href="#" className="text-blue-500 hover:text-blue-400">
                        <img src='https://assets-bucket.b-cdn.net/magic-win-biz/cms-content/assets/images/icons/134.png' alt='' />
                    </a>
                    <a href="#" className="text-pink-500 hover:text-pink-400">
                        <img src='https://assets-bucket.b-cdn.net/magic-win-biz/cms-content/assets/images/icons/135.png' alt='' />
                    </a>
                    <a href="#" className="text-blue-400 hover:text-blue-300">
                        <img src='https://assets-bucket.b-cdn.net/magic-win-biz/cms-content/assets/images/icons/159.png' alt='' />
                    </a>
                    <a href="#" className="text-blue-500 hover:text-blue-400">
                        <img src='https://assets-bucket.b-cdn.net/magic-win-biz/cms-content/assets/images/icons/160.png' alt='' />
                    </a>
                </div>
            </div>

            <div className="mx-auto md:px-6 md:mt-10 mt-5 text-center md:text-xs text-[10px] font-bold text-white md:leading-normal leading-5">
                <p>
                    Magic Win is a product of Magic Win Group which operates in accordance with the license
                    granted by SVG Gambling Commission under the license.
                </p>
                <p className="mt-2">© 2024 Magic Win. All rights reserved.</p>
            </div>

            {isModalOpen && <VideoModal videoSrc={videoSrc} closeModal={closeModal} />}
        </footer>
    )
}

export default Footer;