import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAccountStatement, getDepositWithdrawList } from "../redux/_reducers/_user_reducers";
import CustomPagination from "../component/CustomPagination/CustomPagination";

function DepositWithdrwal() {
  const [payloadData, setPayloadData] = useState({
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    statementFor: "",
  });
  const dispatch = useDispatch();
  const { depositWithdrawal } = useSelector((state) => state.user);
  const statementData = depositWithdrawal
  const [pageNumber, setPageNumber] = useState(1);
  const [sportType, setSportType] = useState("");
  const [filteredData, setFilteredData] = useState();
  const pageSize = 20;

  useEffect(() => {
    handleSubmit();
  }, [dispatch, pageNumber]);

  useEffect(() => {
    let dataFilter;

    if (sportType === "") {
      dataFilter = statementData;
    } else if (sportType === "cricket") {
      dataFilter = statementData?.filter(
        (ele) =>
          ele.statementFor !== "internationalCasino" &&
          ele.statementFor !== "diamondCasino"
      );
    } else {
      dataFilter = statementData?.filter(
        (ele) => ele.statementFor === sportType
      );
    }

    console.log(statementData, dataFilter, sportType, "jjjjjjjjjjj");
    setFilteredData(dataFilter);
  }, [sportType, statementData]);

  const groupedData = {};
  filteredData?.forEach((item) => {
    const dateKey = moment(item.date).format("YYYY-MM-DD");
    groupedData[dateKey] = groupedData[dateKey] || [];
    groupedData[dateKey].push(item);
  });

  let totalAmount = 0;
  filteredData?.map((data) => {
    totalAmount += data.amount;
  });
  let balance = 0;
  let showAmount = 0;
  let finalData = filteredData?.map((data) => {
    balance = totalAmount - showAmount;
    showAmount += data.amount;
    return {
      amount: data.amount,
      date: data.date,
      balance: balance,
      reqType: data.reqType,
      remark: data.remark,
      canceledRemark: data.canceledRemark,
      statementFor: data.statementFor,
      isComm: data.isComm,
      marketId: data.marketId,
      createdAt: data.createdAt,
      selectionId: data.selectionId || "0",
      status: data.status,
      utrNo: data?.utrNo
    };
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayloadData({
      ...payloadData,
      [name]: value,
    });
  };

  // Handle change for the select dropdown
  const handleSelectChange = (e) => {
    setPayloadData({
      ...payloadData,
      status: e.target.value, // Update the statementFor
    });
    const reqData = {
      fromDate: payloadData.fromDate,
      toDate: payloadData.toDate,
      pageNo: pageNumber,
      size: pageSize,
      status: e.target.value,
    };
    dispatch(getDepositWithdrawList(reqData));
  };

  const handleSubmit = () => {
    const reqData = {
      fromDate: payloadData.fromDate,
      toDate: payloadData.toDate,
      pageNo: pageNumber,
      size: pageSize,
      status: payloadData.status,
    };
    dispatch(getDepositWithdrawList(reqData));
  };



  return (
    <>
      <section className="w-full bg-black pb-20">
        <div className="relative mx-auto text-center w-full max-w-full shadow-xl sm:rounded-md py-4">
          <div className="px-2 space-y-1 h-auto afterFooter">
            <div className="w-full overflow-x-auto">
              <div className="flex md:flex-row flex-col justify-center text-center relative lg:gap-3 gap-1 py-2">

                <div className="flex w-full md:w-52 items-start border-2 border-secondary  rounded-sm mb-6 transition-colors relative">
                  <div className="absolute mx-2 -top-[10px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-satrt items-center bg-main px-2">
                    <h2 className="text-xs font-semibold">From</h2>
                  </div>
                  <input
                    type="date"
                    name="fromDate"
                    id="fromDate"
                    value={payloadData.fromDate}
                    onChange={handleChange}
                    className="block w-full  mt-1 border-white rounded-sm bg-black text-white py-3 px-3 shadow-sm focus:outline-none "
                  />
                </div>
                <div className="flex w-full md:w-52 items-start border-2 border-secondary  rounded-sm mb-6 transition-colors relative">
                  <div className="absolute mx-2 -top-[10px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-satrt items-center bg-main px-2">
                    <h2 className="text-xs font-semibold">To</h2>
                  </div>
                  <input
                    type="date"
                    name="toDate"
                    id="toDate"
                    value={payloadData.toDate}
                    onChange={handleChange}
                    className="block w-full  mt-1 border-white rounded-sm bg-black text-white py-3 px-3 shadow-sm focus:outline-none "
                  />
                </div>


                <div>
                  <button
                    type="submit"
                    className="bg-button mt-1 text-white text-sm font-bold px-4 py-3.5 md:w-52 w-full rounded-sm"
                    onClick={handleSubmit}
                  >
                    Load Report
                  </button>
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              {/* <select
              id="customSelect"
              className="block w-32 border mt-1 border-white rounded-sm bg-black text-white py-2 px-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={payloadData.statementFor}
              onChange={handleSelectChange}
            >
              <option value="">All</option>
              <option value="profitLoss">P&L</option>
              <option value="ACCOUNT_STATEMENT">Account</option>
            </select>
            <select
              id="customSelect"
              className="block w-32 border mt-1 border-white rounded-sm bg-black text-white py-2 px-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={sportType}
              onChange={handleSelectChange2}
            >
              <option value="">All</option>
              <option value="cricket">Cricket</option>
              <option value="internationalCasino">internationalCasino</option>
              <option value="diamondCasino">diamondCasino</option>
            </select> */}
            </div>
            <div className="flex w-full md:w-52 items-start border-2 border-secondary  rounded-sm mb-6 transition-colors relative">
              <div className="absolute mx-2 -top-[10px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-satrt items-center bg-main px-2">
                <h2 className="text-xs font-semibold">Select</h2>
              </div>
              <select
                id="customSelect"
                className="block w-full  mt-1 border-white rounded-sm bg-black text-white py-3 px-3 shadow-sm focus:outline-none "
                value={payloadData.status}
                onChange={handleSelectChange}
              >
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="accept">Approve</option>
                <option value="cancel">Cancel</option>
              </select>
            </div>
            <div className="py-4 space-y-1">
              {finalData?.length > 0 && (
                <>


                  <div className="overflow-x-auto w-full">
                    <table className="w-full text-sm text-left text-white bg-main border border-secondary capitalize">
                      <thead className="text-xs uppercase bg-[#404040]">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Date
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Amount
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Type
                          </th>
                          <th scope="col" className="px-6 py-3">
                            UTR
                          </th>
                          <th scope="col" className="px-6 py-3">
                            DESC
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Remark
                          </th>
                          <th scope="col" className="px-6 py-3">
                            status
                          </th>
                        </tr>
                      </thead>
                      {finalData?.map((item, index) => (
                        <tbody key={index}>
                          <tr className="bg-main border-b-[1px] border-secondary">
                            <td
                              scope="row"
                              className="px-6 py-4 font-medium whitespace-nowrap"
                            >
                              {moment(item.date).format("DD/MM/YYYY hh:mm:ss A")}
                            </td>
                            <td className="px-6 py-4">{item.amount}</td>
                            <td className="px-6 py-4 text-white">{item?.reqType}</td>


                            <td className="px-6 py-4">
                              {item.utrNo}
                            </td>
                            <td className="px-6 py-4">
                              {item.canceledRemark}
                            </td>
                            <td className="px-6 py-4">
                              {item.remark}
                            </td>

                            <td className="px-6 py-4 flex justify-start items-center space-x-2 ">
                              {item.status}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>

                  </div>
                  <CustomPagination
                    // totalCount={accountStatement?.totalCount}
                    pageSize={pageSize}
                    currentPage={pageNumber}
                    onChange={setPageNumber}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DepositWithdrwal;

