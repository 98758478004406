export const domainName = 'magic_win'
export const  SPORTSCONSTANT =[
    {
        text: 'Cricket',
        count: "4",
        iconImg:"/assets/appImages/magic-win-biz/assets/icons/sports/Cricket.png"
    },
    {
        text: 'Tennis',
        count: "2",
        iconImg:"/assets/appImages/magic-win-biz/assets/icons/sports/Tennis.png"
    },
    {
        text: 'Football',
        count: "1",
        iconImg:"/assets/appImages/magic-win-biz/assets/icons/sports/Soccer.png"
    },
    {
        text: 'Election',
        count: "999",
         iconImg:"/assets/appImages/magic-win-biz/assets/icons/sports/election.png"
    },
    

]
