export const casinoImages = [
  { pathName: "2087.png", section: "multiplayer" },
  { pathName: "2223", section: "Section 1" },
  { pathName: "2490", section: "Section 1" },
  { pathName: "2491", section: "Section 1" },
  { pathName: "2493", section: "Section 1" },
  { pathName: "2494", section: "Section 1" },
  { pathName: "3031.png", section: "popular",
    iframeID: 230263,
    provider: "DC",

  },
  { pathName: "3348.webp", section: "popular",
    iframeID: 600144,
    provider: "JiLi",

  },
  {
    pathName: "3349.webp",
    section: "popular",
    iframeID: 225241,
    provider: "DC",
  },
  { pathName: "3350", section: "Section 1" },
  { pathName: "3351", section: "Section 1" },
  {
    pathName: "3920.webp",
    section: "indian",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/67.webp",
    title: "worli matka",
    eventId: "3054",
    shortName: "worli",
    casinoSort: "marketWhite",
  },
  { pathName: "3921.webp", section: "indian" },
  { pathName: "3922.webp", section: "indian" },
  { pathName: "3923.webp", section: "indian" },
  { pathName: "3924.webp", section: "indian" },
  {
    pathName: "3926.webp",
    section: "indian",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/69.png",
    title: "20 20 dragon tiger",
    eventId: "3035",
    shortName: "dt20",
    img: "/images/casino/dt20.jpg",
    casinoSort: "marketWhite",
  },
  { pathName: "3935.webp", section: "indian" },
  { pathName: "3936.webp", section: "indian" },
  { pathName: "4054.webp", section: "carousel" },
  { pathName: "4098", section: "Section 1" },
  { pathName: "4116.webp", section: "popular" },
  { pathName: "4188.webp", section: "carousel" },
  { pathName: "4198.webp", section: "carousel" },
  { pathName: "4328", section: "Section 1" },
  {
    pathName: "4361.webp",
    section: "trending",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/70.png",
    title: "teenpatti 1-day",
    eventId: "3031",
    shortName: "teen",
    img: "/images/casino/teenpatti.jpg",
    casinoSort: "marketWhite",
  },
  { pathName: "4362.webp", section: "trending" },
  { pathName: "4363.webp", section: "trending" },
  {
    pathName: "4364.webp",
    section: "trending",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/71.png",
    title: "lucky 7 - b",
    eventId: "3032",
    shortName: "lucky7eu",
    img: "/images/casino/lucky7eu.jpg",
    casinoSort: "marketWhite",
  },
  {
    pathName: "150010.webp",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/72.png",
    title: "32 cards a",
    eventId: "3055",
    shortName: "card32-a",
    img: "/images/casino/card32a.jpg",
    casinoSort: "marketWhite",
  },
  {
    pathName: "150010.webp",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/73.png",
    title: "32 cards b",
    eventId: "3034",
    shortName: "card32eu",
    img: "/images/casino/card32b.jpg",
    casinoSort: "marketWhite",
  },
  {
    pathName: "900027.webp",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/74.png",
    title: "20-20 Dargon tiger 2",
    eventId: "3059",
    shortName: "dt202",
    img: "/images/casino/dt202.jpg",
    casinoSort: "marketWhite",
  },
  {
    pathName: "150014.webp",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/83.png",
    title: "amar akbar anthony",
    eventId: "3056",
    shortName: "aaa",
    img: "/images/casino/aaa.jpg",
    casinoSort: "marketWhite",
  },
  {
    pathName: "150023.webp",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/81.png",
    title: "teenpatti test",
    eventId: "3048",
    shortName: "teen9",
    img: "/images/casino/teentest.jpg",
    casinoSort: "marketWhite",
  },
  {
    pathName: "150033.webp",
    section2: "liveCasino",
    shortImgPath:
      "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/82.png",
    title: "20 20 teenpatti",
    eventId: "3030",
    shortName: "teen20",
    casinoSort: "marketWhite",
  },
  { pathName: "4365.webp", section: "trending" },
  { pathName: "4366.webp", section: "trending" },
  { pathName: "4367.webp", section: "trending" },
  { pathName: "4368.webp", section: "trending" },
  { pathName: "4369.webp", section: "trending" },
  { pathName: "4370.webp", section: "trending" },
  { pathName: "4376.webp", section: "carousel" },
  { pathName: "4424.webp", section: "trending" },
  { pathName: "4425.webp", section: "trending" },
  { pathName: "4523.webp", section: "trending" },
  {
    pathName: "1700.webp",
    section: "popular",
    iframeID: 226028,
    provider: "DC",
  },
  { pathName: "1701.webp", section: "Section 2" },
  { pathName: "1702.webp", section: "Section 2" },
  { pathName: "1703.webp", section: "Section 2" },
  { pathName: "1704.webp", section: "Section 2" },
  { pathName: "1705.webp", section: "Section 2" },
  { pathName: "1706.webp", section: "Section 2" },
  { pathName: "1707", section: "Section 2" },
  { pathName: "1708", section: "Section 2" },
  { pathName: "1709", section: "Section 2" },
  { pathName: "1710", section: "Section 2" },
  { pathName: "1711.webp", section: "popular",
    iframeID: 201206,
    provider: "DC",
   },
  { pathName: "1712.webp", section: "popular",
    iframeID: 203585,
    provider: "DC",


  },
  { pathName: "1713.webp", section: "popular" },
  { pathName: "1714.webp", section: "popular" },
  { pathName: "1715.webp", section: "popular",

    iframeID: 200527,
    provider: "DC",
   },
  { pathName: "1716.webp", section: "popular",
    iframeID: 226025,
    provider: "DC",
   },
  { pathName: "1717.webp", section: "popular",
    iframeID: 200526,
    provider: "DC",
   },
  { pathName: "1718.webp", section: "popular" },
  { pathName: "1719.webp", section: "popular" },
  { pathName: "1720.webp", section: "popular",
    iframeID: 202987,
    provider: "DC",
   },
  { pathName: "1721.webp", section: "popular",
    iframeID: 203013,
    provider: "DC",
   },
  { pathName: "1723.webp", section: "popular",
    iframeID: 201153,
    provider: "DC",
   },
  { pathName: "1725.webp", section: "eSports" },
  { pathName: "1726.webp", section: "eSports" },
  { pathName: "1727.webp", section: "eSports" },
  { pathName: "1728.webp", section: "eSports" },
  { pathName: "1729.webp", section: "eSports" },
  { pathName: "1730.webp", section: "eSports" },
  { pathName: "1731.webp", section: "eSports" },
  { pathName: "1732.webp", section: "eSports" },
  { pathName: "1733.webp", section: "eSports" },
  { pathName: "1734.webp", section: "eSports" },
  { pathName: "1735.webp", section: "eSports" },
  { pathName: "1736.webp", section: "multiplayer" },
  { pathName: "1737.webp", section: "multiplayer" },
  { pathName: "1738.webp", section: "multiplayer" },
  { pathName: "1739.webp", section: "multiplayer" },
  { pathName: "1740.webp", section: "multiplayer" },
  { pathName: "1741.webp", section: "multiplayer" },
  { pathName: "1742.webp", section: "multiplayer" },
  { pathName: "2056", section: "Section 2" },
  { pathName: "2057", section: "Section 2" },
  { pathName: "2058", section: "Section 2" },
  { pathName: "2061", section: "Section 2" },
  { pathName: "2063", section: "Section 2" },
  { pathName: "2074", section: "Section 2" },
  { pathName: "2075", section: "Section 2" },
  { pathName: "2079", section: "Section 2" },
  { pathName: "2080", section: "Section 2" },
  { pathName: "2081", section: "Section 2" },
  { pathName: "2082", section: "Section 2" },
  { pathName: "2083", section: "Section 2" },
  { pathName: "2084.png", section: "multiplayer" },
  { pathName: "2085.png", section: "multiplayer" },
  { pathName: "2086.png", section: "multiplayer" },
  { pathName: "45", section: "Section 3" },
  { pathName: "46", section: "Section 3" },
  { pathName: "47", section: "Section 3" },
  { pathName: "1629.webp", section: "liveCasino" },
  { pathName: "1630.webp", section: "liveCasino" },
  { pathName: "1631.webp", section: "liveCasino" },
  { pathName: "1632.webp", section: "liveCasino" },
  { pathName: "1633.webp", section: "liveCasino" },
  { pathName: "1634.webp", section: "liveCasino" },
  { pathName: "1635.webp", section: "liveCasino" },
  { pathName: "1636.webp", section: "liveCasino" },
  { pathName: "1637.webp", section: "liveCasino" },
  { pathName: "1638.webp", section: "liveCasino" },
  { pathName: "1639.webp", section: "liveCasino" },
  { pathName: "1640.webp", section: "liveCasino" },
  { pathName: "1641.webp", section: "liveCasino" },
  { pathName: "1642.webp", section: "liveCasino" },
  { pathName: "1643.webp", section: "liveCasino" },
  { pathName: "1691.webp", section: "casinoGames" },
  { pathName: "1692.webp", section: "casinoGames" },
  { pathName: "1693.webp", section: "casinoGames" },
  { pathName: "1694.webp", section: "casinoGames" },
  { pathName: "1695.webp", section: "casinoGames" },
  { pathName: "1696.webp", section: "casinoGames" },
  { pathName: "1697.webp", section: "casinoGames" },
  { pathName: "1698.webp", section: "casinoGames" },
  { pathName: "1699.webp", section: "casinoGames" },
  { pathName: "1700.webp", section: "casinoGames" },
  { pathName: "1701.webp", section: "casinoGames" },
  { pathName: "1702.webp", section: "casinoGames" },
  { pathName: "1703", section: "Section 3" },
  { pathName: "1704", section: "Section 3" },


];
