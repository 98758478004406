import React from "react";

const Rules = ({ isOpen, closeModal }) => {

    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50 max-w-full" onClick={closeModal}>
            <div className="bg-primary text-white xl:max-w-[700px]  mx-4  md:mx-24 py-2  shadow-lg relative">
                
                <div className="flex items-center bg-button justify-between  border-secondary px-6 py-4">
                    <span className="text-black">Rules</span>
                    <button
                        onClick={closeModal}
                        className="text-black font-bold text-2xl"
                    >
                        &times;
                    </button>
                </div>

                <ul className="px-4 py-3 overflow-auto max-h-[70vh] text-sm xl:text-sm">
                    <li>If you do not accept this agreement do not place any bet.</li>
                    <li>Cheating bets deleted automatically or manually. No Claim.</li>
                    <li>Admin decision is final and no claim on it.</li>
                    <li>Batsman Runs (In-Play) Over/Under (back/lay) runs bets will stand after batsman has faced one ball or is given out before first ball is faced. Score counts if batsman is Not-Out including if innings is declared. In case of rain, match abandoned etc. settled bets will be valid.</li>
                    <li>Current/Next Innings Runs Odd/Even Extras and Penalty runs will be included for settlement purposes.</li>
                    <li>Runs at Fall of 1st Wicket: At least one delivery must be bowled, if no wickets fall bets will be void unless settlement is already determined.</li>
                    <li>Runs at Fall of Next Wicket: The total innings runs scored by a team before the fall of the specified wicket determines the result of the market. If a team declares or reaches their target then the total amassed will be the result of the market. Bets will be void should no more play take place following the intervention of rain, or any other delay, as the ability to reach previous quotes offered will have been removed. In case of rain, match abandoned etc. settled bets will be valid.</li>
                    <li>We do not accept manual bet.</li>
                    <li>In case of cheating and betting in unfair rates we will cancel the bet even after settling.</li>
                    <li>Local fancy are based on Haar - Jeet.</li>
                    <li>Incomplete session will be cancelled but complete session will be settled.</li>
                    <li>In case of match abandoned, cancelled, no result etc. completed sessions will be settled.</li>
                    <li>In case of a Tie match, match odds bets are cancelled but only settled and completed session, fancy are valid.</li>
                    <li>Lambi Paari: In 20-20 match entire twenty overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled. In One Day match entire 50 overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled.</li>
                    <li>Advance Session, Lambi 1st Inning Valid Only.</li>
                    <li>Total Match Four, Total Match Sixes, Total Match Runs, Total Match Wides, Total Match Wicket. If Over Gets Less Than All BET Will Cancel. This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game.</li>
                    <li>Total Match Four, Total Match Sixes, Total Match Runs, Total Match Wides, Total Match Wicket. If Over Gets Less Than All BET Will Cancel. This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game.</li>
                    <li>Total Match Four, Total Match Sixes, Total Match Runs, Total Match Wides, Total Match Wicket. If Over Gets Less Than All BET Will Cancel. This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game.</li>
                    <li>Total Match Four, Total Match Sixes, Total Match Runs, Total Match Wides, Total Match Wicket. If Over Gets Less Than All BET Will Cancel. This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game.</li>
                    <li>Total Match Four, Total Match Sixes, Total Match Runs, Total Match Wides, Total Match Wicket. If Over Gets Less Than All BET Will Cancel. This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game.</li>
                    <li>Total Match Four, Total Match Sixes, Total Match Runs, Total Match Wides, Total Match Wicket. If Over Gets Less Than All BET Will Cancel. This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game.</li>
                    <li>Total Match Four, Total Match Sixes, Total Match Runs, Total Match Wides, Total Match Wicket. If Over Gets Less Than All BET Will Cancel. This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game.</li>
                    
                    <li>Total Match Four, Total Match Sixes, Total Match Runs, Total Match Wides, Total Match Wicket. If Over Gets Less Than All BET Will Cancel. This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game.</li>
                </ul>

            </div>
        </div>
    )
}

export default Rules