import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import SignIn from "./pages/SignIn";
import Layout from "./layout/Layout";
import PrivateRoute from "./routes/PrivateRoute";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getDomainSettingData, getMatchList } from "./redux/_reducers/_user_reducers";
import { BalanceProvider } from "./component/ContextApi";


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMatchList());
    let domainSetting = {
      domainUrl: window.location.origin,
      // domainUrl: 'https://sikander777.com',
    };
    dispatch(getDomainSettingData(domainSetting));
    const interval = setInterval(() => {
      dispatch(getMatchList());
    }, 180000);
    return () => clearInterval(interval);
  }, [dispatch]);
//..
  return (
    <BalanceProvider> 
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Layout />} />
        </Routes>
      </BrowserRouter>
    </div>
    </BalanceProvider>
  );
}

export default App;
