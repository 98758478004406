import React, { useState, useEffect } from 'react';
import RulesModelBmx from "./RulesModelBmx";
import { useNavigate } from 'react-router-dom';


export default function CasinoTab(props) {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = () => {
            let user = JSON.parse(localStorage.getItem('spuser'));
            setUserData(user);
        };
        const timerId = setTimeout(fetchData, 500);
        return () => clearTimeout(timerId);
    }, []);

    const { activeTab, handleTabClick, PageTitle, t1, ruleImage, totalBet } = props;
    const [rulesModal, setRulesModal] = useState(false);

    const handleOpenRules = () => {
        setRulesModal(true)
    };

    return (
        <div className="rules-header">
            {rulesModal ?
                <RulesModelBmx ruleImage={ruleImage} PageTitle={PageTitle} setRulesModal={setRulesModal} />
                : null}
            <div className="flex justify-between items-center whitespace-wrap md:space-x-0 space-x-1 w-full cursor-pointer text-[12px] tracking-wide uppercase font-semibold h-[40px]">


                <div className='flex '>
                    <div className={`${activeTab === 1 ? "cricket-game green-button white-text lg:ml-0 ml-0 border-t-2 border-white" : "soccer-game dark-green-button white-text "} flex justify-center items-center px-1.5 py-[10px]`} onClick={() => handleTabClick(1)}>
                        <span className="flex justify-center items-center ">
                            Game
                        </span>
                    </div>

                    <div className={`${activeTab === 2 ? "cricket-game green-button white-text lg:ml-0 ml-0 border-t-2 border-white" : "soccer-game dark-green-button white-text"} flex justify-center items-center px-1.5 py-[10px]`} onClick={() => handleTabClick(2)}>
                        <span className="flex justify-center items-center">
                            Placed Bets ({totalBet})
                        </span>
                    </div>
                   
                </div>

                <div className='text-right px-2 py-1 font-[500] uppercase text-[11px] white-text '>
                    <span onClick={() => handleOpenRules()} className=' cursor-pointer underline'>
                        Rules  
                    </span><span className='px-3 font-bold text-md' onClick={()=>navigate('/casino')}>back</span><br />
                    <span className=''>
                        Round ID : {1 && t1.mid ? t1.mid : null}
                    </span>
                </div>

            </div>
        </div>
    );
}
