import React, { useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { CgProfile } from "react-icons/cg";
import { FaRegEye, FaRegEyeSlash, FaUsersRectangle } from "react-icons/fa6";
import { IoMdSettings } from 'react-icons/io';
import { MdPassword } from 'react-icons/md';
import { TiMinus } from 'react-icons/ti';
import ProfileModal from '../component/ProfileModal/ProfileModal';
import CustomPagination from '../component/CustomPagination/CustomPagination';
import { apiCall } from '../middelware/Http';


const Profile = () => {
    const userData = JSON.parse(localStorage.getItem("user_info_magic_win"))

    const [passwords, setPasswords] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: "",
    });
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPasswords((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const handleSubmit = async () => {
        const newErrors = {};

        if (!passwords.oldPassword) {
            newErrors.oldPassword = "Old Password is required.";
        }
        if (!passwords.password) {
            newErrors.password = "New Password is required.";
        }
        if (!passwords.confirmPassword) {
            newErrors.confirmPassword = "Confirm Password is required.";
        } else if (passwords.password !== passwords.confirmPassword) {
            newErrors.confirmPassword = "New Password and Confirm Password must match.";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const result = await apiCall("PATCH", "user/updateUserPassword", passwords);
        }
    };

    const [isToggled, setIsToggled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempToggle, setTempToggle] = useState(false);

    const handleToggleClick = () => {
        setTempToggle(isToggled);
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = () => {
        setIsToggled(!tempToggle);
        setIsModalOpen(false);
    };

    return (
        <div className="bg-main text-white min-h-screen p-6">
            <div className=" mx-auto">
                <div className="flex items-start border-2 border-secondary p-6 rounded-lg mb-6 transition-colors relative" >
                    <div className='absolute -top-[16px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-satrt items-center bg-main px-2'>
                        <div className="text-xl "><CgProfile />
                        </div>
                        <h2 className="text-md font-semibold">User Details</h2>

                    </div>
                    <div className="">
                        <p className="text-[15px] text-white leading-relaxed flex justify-center items-center space-x-2">
                            <span className='text-button'><FaUsersRectangle />
                            </span>
                            <span className='font-bold'> User Name</span></p>
                        <p className="text-[15px] text-white leading-relaxed flex justify-center items-center ml-4 space-x-2">
                    
                            <span className='font-bold ml-4'>{` ${userData?.username} [${userData?.name}]`} </span></p>
                    </div>
                </div>
            </div>
            <div className=" mx-auto">
                <div className="items-start border-2 border-secondary p-6 rounded-lg mb-6 transition-colors relative">
                    <div className="absolute -top-[16px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-start items-center bg-main px-2">
                        <div className="text-xl">
                            <MdPassword />
                        </div>
                        <h2 className="text-md font-semibold">Change Password</h2>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
                        <div>
                            <label className="block text-white text-sm mb-2">Old Password</label>
                            <div className="relative">
                                <input
                                    type={showOldPassword ? "text" : "password"}
                                    placeholder="Old Password"
                                    name="oldPassword"
                                    value={passwords.oldPassword}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 bg-[#404040] text-white rounded-lg border border-transparent focus:outline-none focus:ring focus:ring-button"
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-2 flex items-center text-white/20"
                                    onClick={() => setShowOldPassword((prev) => !prev)}
                                >
                                    {showOldPassword ? (
                                        <FaRegEyeSlash className="text-white" />
                                    ) : (
                                        <FaRegEye className="text-white" />
                                    )}
                                </button>
                            </div>
                            {errors.oldPassword && (
                                <p className="text-red-500 text-sm mt-1">{errors.oldPassword}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-white text-sm mb-2">New Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="New Password"
                                    name="password"
                                    value={passwords.password}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 bg-[#404040] text-white rounded-lg border border-transparent focus:outline-none focus:ring focus:ring-button"
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-2 flex items-center text-white/20"
                                    onClick={() => setShowPassword((prev) => !prev)}
                                >
                                    {showPassword ? (
                                        <FaRegEyeSlash className="text-white" />
                                    ) : (
                                        <FaRegEye className="text-white" />
                                    )}
                                </button>
                            </div>
                            {errors.password && (
                                <p className="text-red-500 text-sm mt-1">{errors.password}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-white text-sm mb-2">
                                Confirm Password
                            </label>
                            <div className="relative">
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    value={passwords.confirmPassword}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 bg-[#404040] text-white rounded-lg border border-transparent focus:outline-none focus:ring focus:ring-button"
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-2 flex items-center text-white/20"
                                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                                >
                                    {showConfirmPassword ? (
                                        <FaRegEyeSlash className="text-white" />
                                    ) : (
                                        <FaRegEye className="text-white" />
                                    )}
                                </button>
                            </div>
                            {errors.confirmPassword && (
                                <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-center mt-6">
                        <button
                            onClick={handleSubmit}
                            className="h-[40px] font-bold text-sm uppercase px-4 border-[6px] border-transparent rounded-lg flex justify-center items-center whitespace-nowrap text-white bg-[conic-gradient(from_0deg,#f15a24,#e4440a)]"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className=" mx-auto">
                <div className="flex items-start border-2 border-secondary p-6 rounded-lg mb-6 transition-colors relative" >
                    <div className='absolute -top-[16px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-satrt items-center bg-main px-2'>
                        <div className="text-xl "><IoMdSettings />
                        </div>
                        <h2 className="text-md font-semibold">Manage Bank Account</h2>

                    </div>

                    <div class="overflow-x-auto w-full">
                        <table class="w-full text-sm text-left text-white bg-main border border-secondary capitalize">
                            <thead class="text-xs uppercase bg-[#404040]">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                        cardHolder Name
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        account No
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        iban
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-main border-b-[1px] border-secondary">
                                    <th scope="row" class="px-6 py-4 font-medium  whitespace-nowrap">
                                        Shakti Kumar
                                    </th>
                                    <td class="px-6 py-4">
                                        1441001700307646
                                    </td>
                                    <td class="px-6 py-4">
                                        PUNB180010
                                    </td>
                                    <td class="px-6 py-4 flex justify-satrt items-center space-x-2 ">
                                        <button
                                            onClick={handleToggleClick}
                                            className={`w-8 h-3 flex items-center rounded-full transition-colors duration-300 ${isToggled ? "bg-button" : "bg-gray-400"}`}>
                                            <div className={`w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 ${isToggled ? "translate-x-4" : "translate-x-0"}`}>
                                                <span className="flex items-center justify-center pt-0.5 text-main">
                                                    {isToggled ?
                                                        <><AiOutlineCheck /></>
                                                        : <> <TiMinus />
                                                        </>}
                                                </span>
                                            </div>
                                        </button>
                                        <span
                                            className={`font-bold ${isToggled ? "text-green-600" : "text-red-600"
                                                }`}
                                        >
                                            {isToggled ? "Activated" : "Deactivated"}
                                        </span>

                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <CustomPagination />
                    </div>
                </div>

            </div> */}
            {isModalOpen && (
                <ProfileModal handleChange={handleChange} handleCancel={handleCancel} isToggled={isToggled} />
            )}
        </div>
    );
};

export default Profile;