import React from 'react';
import { Carousel } from 'antd';
import { casinoImages } from '../../middelware/casinoConstants';

const DashboardCarousel = () => {
  return (
    <div className="mx-auto xl:w-[calc(100vw-13rem)] h-full  py-2">
      {casinoImages && casinoImages.length > 0 ? (
        <Carousel
          autoplay
          dots={true} // You can switch to `false` if you prefer no dots
          speed={500}
          autoplaySpeed={3000}
          pauseOnHover={true} // Pause carousel on hover
          easing="ease-in-out" // Smooth easing for transitions
          arrows={true} // Show arrows for navigation
        >
          {casinoImages
            .filter((image) => image.section === 'carousel')
            .map((game, index) => (
              <div key={index} className="w-full">
                <img
                  src={`/assets/appImages/categorydetail/${game.pathName}`}
                  alt={game.name || `Slide ${index + 1}`}
                  className="w-full h-[140px] sm:h-[180px] md:h-[200px] lg:h-[300px] xl:h-[450px] object-cover rounded-lg shadow-md"
                  loading="lazy"
                />
              </div>
            ))}
        </Carousel>
      ) : (
        <div className="text-center text-white">No images available.</div>
      )}
    </div>
  );
};

export default DashboardCarousel;
