


import { message } from "antd";
import { domainName } from "../../middelware/global";
import { apiCall } from "../../middelware/Http";


async function login(data) {

  try {


    const user = await apiCall("POST", "user/login", data);

    if (user) {
      user?.message &&  message.success(user?.message) 
      localStorage.setItem(`user_info_${domainName}`, JSON.stringify(user?.data));
      localStorage.setItem("clientBalance", JSON.stringify(user?.data?.balance));
      localStorage.setItem("clientbetChipsData", JSON.stringify(user?.data?.betChipsData));
      localStorage.setItem("token", JSON.stringify(user?.token))
      localStorage.setItem('oneBetAmount', JSON.stringify(user.data.oneClickBetAmount ? user.data.oneClickBetAmount : 0));
      localStorage.setItem('oneClickStatus', JSON.stringify(false));
      localStorage.setItem('betSlipData', JSON.stringify([100,200,300]));
      localStorage.setItem("unauthorized",false)
      return { userinfo: user };
    }
  } catch (error) {
    message.error(error?.data?.message)
    return Promise.reject(error);
  }
}

function logout() {

  localStorage.clear();
  window.location.href("/login")
}


export const authServices = {
  login,
  logout,
};