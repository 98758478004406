import React, { useState, useEffect } from 'react'

function Blinking({ price, size, color, blinkColor, isLast, textColors, boderColors }) {


    const [blink, setBlink] = useState(false);
    const [prevPrice, setPrevPrice] = useState(price);
    const [prevSize, setPrevSize] = useState(size);
    useEffect(() => {
        if (price !== prevPrice || size !== prevSize) {
            setBlink(true);
            const timeout = setTimeout(() => {
                setBlink(false);
                setPrevPrice(price);
                setPrevSize(size);
                setBlink(false);
            }, 500); // Blinking duration
            return () => clearTimeout(timeout);
        }
    }, [price, size, prevPrice, prevSize, color, blinkColor]);



    return (
        <div className={` flex justify-center py-0.5 border-[1px] rounded-md items-center ${blink ? `bg-[#facc15]` : `${color}`} ${isLast ? 'rounded-br-2xl' : ''} ${boderColors ? `${boderColors}` : ''}`}>
            <div className={`text-center leading-3 ${textColors ? `${textColors}` : ''}`}>
                <span className={`2xl:text-xs lg:text-[10px] text-xs font-black ${blink ? 'text-black' : textColors}`}>{price ? price : "-"}</span><br />
                <span className="lg:text-[10px] text-xs font-semibold text-[#8A8A8A]">{size ? size : "-"}</span>
            </div>
        </div>
    )
}

export default Blinking

