import React, { useRef, useState, useEffect } from 'react'
import { RiInformationFill } from "react-icons/ri";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from 'react-router-dom';
import BetPlaceCounter from './BetPlaceCounter';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { TiEdit } from "react-icons/ti";
import { FaDeleteLeft } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

export const betChipsData = {
  "1000": 1000,
  "2000": 2000,
  "5000": 5000,
  "10000": 10000,
  "20000": 20000,
  "50000": 50000,
  "100000": 100000,
  "250000": 250000,
};


export function BetPlaceMobile(props) {


  let { openBets, closeSec, closeRow, matchName, betSlipData, placeBet, count, betLoading, increaseCount, decreaseCount ,setStakeModal } = props;
  const betChipsLocalStorage = localStorage.getItem("clientbetChipsData") ? JSON.parse(localStorage.getItem("clientbetChipsData")) : betChipsData
  const myArray = Object.values(betChipsLocalStorage);
  const modalRef = useRef();
  const [positions, setPositionData] = useState(0);

  useEffect(() => {


    if (betSlipData && betSlipData.position && betSlipData.position.length > 0) {
      betSlipData && betSlipData.position.forEach((eles) => {
        if (betSlipData.selectionId === eles._id) {
          setPositionData(eles.position);
        }
      });
    }


    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // handleClose(); // Close modal when clicking outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [betSlipData]);


  const [stake, setStack] = useState(0);
  let [placeButton, setPlaceButton] = useState(false)
  const updateInputValue = (event) => {
    const newValue = parseFloat(event.target.value);
    setStack(() => {
      const newStack = !isNaN(newValue) ? (newValue >= 0 ? newValue : 0) : 0;
      betSlipData.stake = newStack;
      if (betSlipData.stake > 0) {
        setPlaceButton(true);
      }
      if (betSlipData.stake <= 0) {
        setPlaceButton(false);
      }
      updateOddsPostModal()
      return newStack;
    });
  };
  const addstackValue = (number)=>{
   
    setStack((stack) => {
      const newStack = !isNaN(number) ?  `${stack}${number}` : 0;
      betSlipData.stake = newStack;
      if (betSlipData.stake > 0) {
        setPlaceButton(true);
      }
      if (betSlipData.stake <= 0) {
        setPlaceButton(false);
      }
      updateOddsPostModal()
      return newStack;
    });
  }
  const deleteStackValue = () => {
    if(stake){
     setStack((stack) => {
       if(!stack && stack === null) return
       const newStack = stack.slice(0, -1); // Remove the last character
       betSlipData.stake = newStack;
       if (betSlipData.stake > 0) {
         setPlaceButton(true);
       }
       if (betSlipData.stake <= 0) {
         setPlaceButton(false);
       }
       updateOddsPostModal()
       return newStack;
     });
    }
   };

  const updateFinalBalance = (amount) => setStack(prevStack => {
    const newStack = Number(prevStack) + amount
    betSlipData.stake = newStack;
    if (betSlipData.stake > 0) {
      setPlaceButton(true);
    }
    if (betSlipData.stake <= 0) {
      setPlaceButton(false);
    }
    return newStack
  });


  if (betSlipData.oddsType === "fancy") {
    // filterdata = runCount.session.filter(session => session.Selection_id == betSlipData.data.Selection_id);
  }

  if (betSlipData.oddsType === "bookmaker") {
    // filterdata = runCount.team_data.filter(session => session.selectionid == betSlipData.data.selectionid);

  }




  const arrayData = (element) => {
    if (element > 0) {
      updateFinalBalance(element);
      updateOddsPostModal()
    }
  };

  const updateOddsPostModal = async () => {

    let oddsType = betSlipData.oddsType
    let positionArray = {}
    let positionArrayNew = {}

    if (oddsType == "Match Odds" || oddsType == "Tied Match") {
      betSlipData.nameOther.map((oddsData) => {
        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionId] ? betSlipData.position[oddsData.selectionId] : 0
        let calculatePos = positionArray[oddsData.selectionId]


        positionArray[oddsData.selectionId] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionId] = Number(calculatePos)

      })
    }

    if (oddsType == "toss" || oddsType == "bookmaker") {
      betSlipData.nameOther.map((oddsData) => {

        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionid] ? betSlipData.position[oddsData.selectionid] : 0
        let calculatePos = positionArray[oddsData.selectionid]

        positionArray[oddsData.selectionid] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionid] = Number(calculatePos)

      })
    }


    betSlipData.oldPos = betSlipData.position
    betSlipData.position = positionArray

  }


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);




  const closeModal = () => {
    // assuming betSlipData and id are available here
    let id = closeSec;
    closeRow(id);
  };

  const handlePlaceBet = () => {
    Promise.resolve(placeBet())
      .then(closeModal)
      .catch((error) => {
        console.error("Error placing bet:", error);
      });
  };




  return (
    <section className='py-0.5 hidden sm:block'>
      {/* ${betSlipData.betType === "Y" || betSlipData.betType === "L" ? "border-[#A5D9FE]" : "border-[#F8D0CE]"} */}

      <div ref={modalRef} className={`border border-button bg-[#2D2E2E] rounded-lg relative `}>
        <div className='py-1 px-1 '>
          <div className='py-1.5 px-1.5 rounded  bg-button w-full flex items-center justify-between'>
            <span className='text-md font-bold uppercase'>Bet Slip</span>
            <span className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold' onClick={closeModal}> &times;</span>
          </div>
          <div className='bg-black px-1 my-1 rounded'>


            <div className='text-sm font-normal space-y-1'>
              {/* <div>{matchName}</div> */}
              <div className='text-button py-1 px-0.5 font-bold text-sm uppercase'>{betSlipData.oddsType}</div>

            </div>
            <div className={`px-2 py-0.5 rounded ${betSlipData.betType === "Y" || betSlipData.betType === "L" ? "bg-[#A5D9FE]" : "bg-[#F8D0CE]"}`}>


              <div className='text-black py-1 px-1 flex items-center gap-3 font-bold text-sm'>{betSlipData.name}

                <span className='text-red-800' onClick={closeModal}><RiDeleteBin6Line />
                </span>
                {/* <div className=''>

                </div> */}
              </div>
              <div className='text-sm font-normal py-1 flex items-center space-x-3'>
                <div className="relative mb-2 flex-1 flex py-1 items-center bg-white rounded-full ">
                  <button className="ml-3 w-6 h-6 rounded-full font-bold flex items-center justify-center bg-[#CEC8C6]" onClick={decreaseCount}><FaMinus size={11} /></button>
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full font-bold  rounded text-center px-3 py-[0.32rem] leading-[1.6] outline-none focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="exampleFormControlInput1"
                    value={
                      betSlipData.oddsType === "fancy" || betSlipData.oddsType === "bookmaker" || betSlipData.oddsType === "toss"
                        ? (count * 100)
                        : count}
                  />

                  <button className="mr-3 w-6 h-6 rounded-full font-bold flex items-center justify-center bg-[#CEC8C6]" onClick={increaseCount}><FaPlus size={11} /></button>
                </div>
                <div class="relative mb-2 flex-1 flex py-1 bg-white rounded-full items-center">
                  <button className="ml-3 w-6 h-6 rounded-full font-bold flex items-center justify-center bg-[#CEC8C6]"><FaMinus size={11} /></button>
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full font-bold rounded text-center bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="exampleFormControlInput1"
                    placeholder="Stack "
                    value={stake ? stake : ""}
                    onChange={updateInputValue}
                  />
                  <button className="mr-3 w-6 h-6 rounded-full font-bold flex items-center justify-center bg-[#CEC8C6]" ><FaPlus size={11} /></button>

                </div>
              </div>
            </div>
            <div className='flex py-1 px-2 mt-1 text-[10px] gap-x-1 items-center'>
              {myArray && myArray.map((element, index) => (
                <div key={index} className="flex py-1 justify-center items-center bg-button rounded-sm" onClick={() => arrayData(element)}>
                  <span className='px-1  text-black text-xs font-bold' >{element}</span>

                </div>
              ))}
              <div onClick={()=>setStakeModal(true)} className='px-2 py-1 text uppercase text-xs font-bold flex items-center  text-button'><TiEdit size={18} /> <span className='text-white'>Edit</span></div>
            </div>


            <div className='text-[10px] font-extrabold text-gray-400 px-2 py-0.5'>Select a different amount</div>

            <div className='flex space-x-4 px-2 py-1.5 w-full'>

              <div className='grid grid-cols-6 gap-1 w-[80%]'>
                {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '00'].map((number) => (
                  <div className='flex ' key={number}>
                    <button onClick={()=>addstackValue(number)} className='px-5 w-full text-xs py-1 font-bold rounded bg-[#CEC8C6]'>{number}</button>
                  </div>
                ))}
              </div>
              <div onClick={()=>deleteStackValue()} className='rounded bg-[#CEC8C6] w-[20%] text-black flex justify-center items-center'  >
                <FaDeleteLeft  size={19} />
              </div>
            </div>
            </div>
            <hr className='my-2 mx-2 font-bold text-md border-[#5f5f5f]' />
            <div className='text-sm px-2 gap-2 font-bold flex items-center'>
              <div class="relative  flex-1 text-center px-2  py-1.5 rounded  bg-[#7B7B7B] cursor-pointer text-sm uppercase" onClick={() => closeModal()}>
                <span className='py-3 text-white'>Cancel</span>
              </div>
              {placeButton === false ? <>
                <div
                  class="relative  flex-1 px-2  text-center py-1.5 rounded bg-button text-gray-200 text-sm uppercase">
                  <span className='py-3 text-black'>placebet</span>
                </div>
              </> :


                <div onClick={handlePlaceBet} class="relative  flex-1 px-2  text-center py-1.5 rounded bg-button text-sm uppercase">
                  <span className='py-3 text-black'>placebet</span>


                </div>
              }
            </div>



          </div>

          {betLoading === true ? (
            <div className='border-2 bg-black opacity-80 rounded-lg h-96 w-full absolute top-0 flex justify-center items-center'>
              <div role="status" className='text-button font-bold text-xxl text-center'>
                {/* <BetPlaceCounter /> */}
                <div className="loaderBet"></div>
                
               
              </div>
              
            </div>
          ) : (
            null)}


      </div>

    </section>
  )
}
