import { useNavigate } from "react-router-dom";

function Cards() {
  const navigate = useNavigate()

  const handleClick = (path) => {
    localStorage.getItem("token")
    ? navigate(path)
    : localStorage.setItem("unauthorized", true);

    
   
  };
  return (
    <>
      <div className="mt-2 text-white font-semibold w-full">
        <div className="flex md:px-2 gap-1.5 overflow-x-auto w-full">
          {/* <button className="bg-gradient-to-r from-orange-800 to-orange-500 text-sm px-4 py-2 rounded flex items-center gap-2 justify-between shadow shadow-white flex-grow-0 flex-shrink-0 ">
            Casino Lobby
            <img
              src={`/assets/appImages/categorydetail/2083.png`}
              alt="20"
              width={20}
            />
          </button> */}

          <button onClick={()=>navigate("/slot-games")} className="md:w-[240px] w-[160px] bg-gradient-to-r from-green-600 to-green-400 md:text-sm text-[11px]  py-2 rounded flex items-center gap-2 justify-between shadow shadow-white flex-grow-0 flex-shrink-0 ">
           <span></span> Slot Games
            <img
              src={`/assets/appImages/categorydetail/2223.svg`}
              alt="20"
              width={40}
              height={10}
            />
          </button>

          {/* <button className="bg-gradient-to-r from-blue-400 to-blue-800 text-sm px-4 py-2 rounded flex items-center gap-2 justify-between shadow shadow-white flex-grow-0 flex-shrink-0 ">
            Esports
            <img
              src={`/assets/appImages/categorydetail/2074.png`}
              alt="20"
              width={20}
            />
          </button> */}

          <button onClick={()=>navigate("/casino")}  className="md:w-[240px] w-[160px] bg-gradient-to-r from-violet-800 to-violet-400 md:text-sm text-[11px] px-4 py-2 rounded flex items-center gap-2 justify-between shadow shadow-white flex-grow-0 flex-shrink-0 ">
            Live Casino
            <img
              src={`/assets/appImages/categorydetail/2058.png`}
              alt="20"
              width={20}
            />
          </button>
        </div>
      </div>
      <div>
        <div className="my-1.5 text-white font-semibold">
          <div className="flex md:px-2 gap-1.5 overflow-x-auto ">
            <button onClick={()=>handleClick("/iframe-casino/201153")} className="bg-gradient-to-r from-orange-800 to-orange-500 md:text-sm text-[11px] px-4 py-2 rounded flex items-center gap-0.5 justify-between shadow shadow-white md:min-w-32 min-w-40 w-60">
              Ezugi
              <img src={`/assets/appImages/categorydetail/2061.png`} alt='20' width={20} />
            </button>
            <button onClick={()=>{handleClick('/iframe-qtech-casino/SPB-aviator')}} className="bg-gradient-to-r from-blue-800 to-blue-400 md:text-sm text-[11px] px-4 py-2 rounded flex items-center gap-0.5 justify-between shadow shadow-white md:min-w-32 min-w-40 w-60">
              QTech
              <img src={`/assets/appImages/magic-win-biz/cms-content/assets/images/icons/67.webp`} alt='20' width={20} />
            </button>
            <button onClick={()=>{handleClick('/iframe-casino/600000')}} className="bg-gradient-to-r from-cyan-800 to-cyan-400 md:text-sm text-[11px] px-4 py-2 rounded flex items-center gap-0.5 justify-between shadow shadow-white md:min-w-32 min-w-40 w-60">
              Jilli
              <img src={`/assets/appImages/magic-win-biz/cms-content/assets/images/icons/86.png`} alt='20' width={20} />
            </button>
          </div>
        </div>
      </div>

    </>
  );
}

export default Cards;


