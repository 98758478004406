import { useNavigate } from "react-router-dom";
import { casinoImages } from "../../middelware/casinoConstants";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function CasinoImages({ filterSection, name }) {
  const navigate = useNavigate();

  const handleResponseCasino = (product) => {
    if (product?.iframeID) {
      navigate(`/iframe-casino-new/${product?.provider}/${product?.iframeID}`);
    } else {
      if (!product.shortName || !product.eventId) return;
      localStorage.getItem("token")
        ? navigate(`/${product.shortName}/${product.eventId}`)
        : localStorage.setItem("unauthorized", true);
    }
  };

  return (
    <div>
      <div className="rounded-md my-4 pb-2 border border-secondary">
        <div className="flex bg-secondary justify-between items-center mb-2 px-3 py-1">
          <h2 className="text-white md:text-[14px] text-[12px] font-bold">{name}</h2>
          <button className="md:text-[12px] text-[10px] text-white p-[6px] px-[12px] rounded-full font-semibold shadow hover:bg-secondary see-all-btn">
            See All
          </button>
        </div>

        <Swiper
          spaceBetween={10}
          slidesPerView="auto"
          loop={true}
          grabCursor={true}
          className="px-2 "
          >
          {casinoImages
            ?.filter((image) => image.section === filterSection)
            ?.map((game, index) => (
              <SwiperSlide key={index} className="!w-auto">
                <img
                  onClick={() => handleResponseCasino(game)}
                  src={`/assets/appImages/categorydetail/${game.pathName}`}
                  alt={game.name}
                  className="md:w-32 w-24 md:h-32 h-24 object-cover rounded-lg"
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}

export default CasinoImages;
