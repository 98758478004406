import axios from "axios";

let domainSetting = (localStorage.getItem('clientdomainSetting') && localStorage.getItem('clientdomainSetting') != "undefined" && localStorage.getItem('clientdomainSetting') != "null" ) ?  JSON.parse(localStorage.getItem('clientdomainSetting')) : null;

export const baseUrl = {
    // BACKEND_URL: 'https://secondapi.cric365day1.com/v1/api',
    // BACKEND_URL: 'https://api.urbet99.com/v1/',
    // SOCKET_URL: 'https://api.urbet99.com/',
    // BASE_IMAGE_DOMAIN: 'https://urbet99.com/images1',
    // BACKEND_URL: 'https://api.sikander777.com/v1/',
    // SOCKET_URL: 'https://api.sikander777.com/',
    // BASE_IMAGE_DOMAIN: 'https://sikander777.com/images1',

    // BACKEND_URL: 'https://api.ashhas247.io/v1/',
    // SOCKET_URL: 'https://api.ashhas247.io/',
   //

    BACKEND_URL: 'https://api.cric365day1.com/v1/',
    // SOCKET_URL: 'https://api.cric365day1.com/',
    SOCKET_URL: 'https://socket.crickexpo.in',
    BASE_IMAGE_DOMAIN: 'https://api.cric365day1.com/images1',

    // BACKEND_URL: 'https://api.urbet99.com/v1/',
    // SOCKET_URL: 'https://api.urbet99.com/',
    // BASE_DOMAIN: domainSetting && domainSetting.domainUrl ? domainSetting.domainUrl : 'https://api.urbet99.com',
    // BASE_IMAGE_DOMAIN: domainSetting && domainSetting.imgBaseUrl ? domainSetting.imgBaseUrl : 'https://api.urbet99.com/images1',

};


function authHeader() {
    const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;


    if (token) {
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}

export const apiCall = async (method, path, payload) => {

    try {
        const response = await axios({
            method,
            url: baseUrl.BACKEND_URL + path,
            data: payload,
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
        });

        return response.data;
    } catch (error) {
        if (Number(error?.response?.data?.code) === 3 || Number(error?.response?.status) === 401) {
            localStorage.clear();
            window.location.href = '/';
        } else if (error.response) {
            throw error.response;
        } else if (error.request) {

            throw error.request;
        } else {
            console.error(error, "Error occurred during request setup");
            throw new Error(error.message);
        }
    }
};

export const apiCallForm = async (method, path, payload) => {

    try {
        const response = await axios({
            method,
            url: baseUrl.BACKEND_URL + path,
            data: payload,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeader(),
            },
        });

        return response.data;
    } catch (error) {
        if (Number(error?.response?.data?.code) === 3 || Number(error?.response?.status) === 401) {
            localStorage.clear();
            window.location.href = '/';
            // throw error.response
        } else if (error.response) {
            throw error.response;
        } else if (error.request) {
            throw new Error('No response received from the server');
        } else {
            console.error(error, "Error occurred during request setup");
            throw new Error(error.message);
        }
    }
};
