import { useEffect, useState, useRef } from "react";
import Login from "../login/Login";
import data from "./headerJson.json";

import { useDispatch, useSelector } from "react-redux";
import { MdOutlineRefresh } from "react-icons/md";
import {
  FaChartBar,
  FaCreditCard,
  FaDollarSign,
  FaHistory,
  FaInfoCircle,
  FaPhone,
  FaSignOutAlt,
  FaUser,
  FaUserAlt,
} from "react-icons/fa";
import { BsBank } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { domainName } from "../../middelware/global";
import SignIn from "../../pages/SignIn";
import { getMatchList } from "../../redux/_reducers/_user_reducers";
import { login } from "../../redux/_reducers/_auth_reducers";

function Header({ setSidebarOpen }) {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openModal = () => setIsLoginOpen(true);
  const closeModal = () => {
    setIsLoginOpen(false);
    localStorage.setItem("unauthorized", false);
  };
  const [balance, setBalance] = useState({
    coins: "",
    exposure: "",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRefbutton = useRef(null);
  const [localStoragelogin, setlocalStorage] = useState(
    localStorage.getItem("token") ? true : false
  );
  const { token, login_loading } = useSelector((state) => state.authentication);
  const localstorageuser = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem(`user_info_${domainName}`));
  const unauthorizedLoginModal = localStorage.getItem("unauthorized")
    ? JSON.parse(localStorage.getItem("unauthorized"))
    : false;

  useEffect(() => {
    if (token || localstorageuser) {
      setlocalStorage(true);
    }
  }, [token, localstorageuser]);
  useEffect(() => {
    if (unauthorizedLoginModal) {
      setIsLoginOpen(true);
    }
  }, [unauthorizedLoginModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !dropdownRefbutton.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {

    const initData = localStorage.getItem("matchList");
    const intervalId = setInterval(() => {
      localStorage.removeItem("matchList");
    }, 1000 * 60 * 5);
    if (!initData) {
      dispatch(getMatchList());
    }
    return () => clearInterval(intervalId);
  }, [dispatch]);


  useEffect(() => {
    setTimeout(() => {
      let Balance = JSON.parse(localStorage.getItem("clientBalance"));
      let clientExposure = JSON.parse(localStorage.getItem("clientExposure"));
      setBalance({
        coins: Balance,
        exposure: clientExposure,
      });
    }, 1000);
  }, [balance]);

  const handleDemoLogin = () => {
    const demoCredentials = {
      username: "C1000",
      password: "1122",
      isClient: true,
      host: window.location.host,
    };

    dispatch(login(demoCredentials))
      .then((data) => {
        if (!data.error) {
          closeModal();
          navigate("/dashboard");
        } else {
          console.error("Demo Login failed:", data.error);
        }
      })
      .catch((error) => {
        console.error("Demo login request failed:", error);
      });
  };

  let domainSetting = JSON.parse(localStorage.getItem("clientdomainSetting"));
  return (
    <>
      {domainSetting && domainSetting?.clientNotification && (
        <marquee className="bg-white px-2 rounded-md text-xs py-0.5 font-semibold hidden md:block mb-1">
          {domainSetting?.clientNotification}
        </marquee>
      )}
      {/* md:static fixed top-0 left-0 */}
      <div className=" md:w-auto w-full md:p-0 p-2 bg-black">
        <header className="flex w-full top-0 left-0 md:bg-[#1A1A1A] bg-black items-center justify-between shadow md:px-1 px-0 md:py-4 py-0 mb-1">
          <div className="flex items-center justify-between gap-3 ">
            <button
              className="text-gray-200 xl:hidden ml-3"
              onClick={() => setSidebarOpen(true)}
            >
              <svg
                className="h-6 w-6"
                fill="#ffff"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
            <div className="flex xl:hidden items-center justify-center border-gray-700">
              <img
                onClick={() => navigate("/")}
                src="/assets/logo.png"
                alt="Magic Win"
                className="h-10 w-32"
              />
            </div>




            <div className="hidden xl:flex gap-3 uppercase font-semibold ">
              {data.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (!item?.access) {
                      localStorage.getItem("token")
                        ? navigate(item?.path)
                        : localStorage.setItem("unauthorized", true);
                    } else {
                      navigate(item?.path);
                    }
                  }}
                  className="flex flex-col items-center text-gray-300 hover:text-red-500">
                  <img src={item.img} alt={item.title} className="w-5 h-5" />
                  <div className="order-2 text-center">
                    <h3 className="text-xs">{item.title}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="relative flex items-center justify-center gap-2 md:space-x-4 rounded-md">
            {!localStoragelogin && (

              <>
                <div
                  className="flex flex-col items-center text-center px-3 py-1 bg-conic rounded-md"
                  onClick={handleDemoLogin}
                >

                  <div className="text-sm py-1.5 font-bold text-white capitalize">
                    Demo
                  </div>
                </div>
                {/* <div className="relative inline-block">
                <select className="px-3 py-2 bg-transparent border text-white border-white rounded-md focus:outline-none">
                  <option value="en">Eng</option>
                  <option value="fr">Français</option>
                  <option value="es">Español</option>
                  <option value="de">Deutsch</option>
                </select>
              </div> */}
                <div className="flex px-[1px] py-[1px] bg-orange-600 rounded-md">




                  <div
                    className="flex flex-col items-center text-center bg-[#1A1A1A] px-2 py-1 rounded-l-md"
                    onClick={openModal}
                  >
                    <img
                      src="/assets/headerImg/login.svg"
                      width={16}
                      height={16}
                      alt="login icon"
                    />
                    <div className="text-[10px] font-bold text-white capitalize">
                      login
                    </div>
                  </div>

                  <div
                    onClick={() => setIsSignUpOpen(true)}
                    className="flex flex-col items-center text-center px-2 py-1"
                  >
                    <img
                      src="/assets/headerImg/signup.svg"
                      width={16}
                      height={16}
                      alt="signup icon"
                    />
                    <div className="text-[10px] font-bold text-white capitalize">
                      signup
                    </div>
                  </div>
                </div>
              </>
            )}

            {localStoragelogin && (
              <>
                <div
                  onClick={() => {
                    navigate("/deposit");
                  }}
                  className="px-2 py-2.5 bg-conic rounded-md "
                >
                  <div className="font-bold text-xs text-white capitalize">Deposit</div>
                </div>
                {/* <div className="flex px-3 py-2.5 bg-orange-600 rounded-md text-sm">
                <div className="font-bold text-white capitalize">
                  <MdOutlineRefresh size={20} />
                </div>
              </div> */}

                <div
                  ref={dropdownRefbutton}
                  onClick={toggleDropdown}
                  className="flex flex-col items-center justify-center px-4 border border-orange-700 text-green-500 capitalize rounded-md text-xs"
                >
                  <span className="mt-1">
                    <FaUserAlt />{" "}
                  </span>
                  {/* <marquee> */}
                  <span className="py-0.5">
                    {balance && balance.coins
                      ? (Math.floor(Number(balance.coins) * 100) / 100)
                      : 0}
                  </span>
                  {/* </marquee> */}
                </div>

                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="w-[218px] z-50 absolute right-0 top-14 bg-primary text-white  px-0.5"
                  >
                    <div className="flex items-center justify-center mb-6">
                      <div className="text-center">
                        <div className="font-bold text-lg">
                          {userData?.username}
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-b p-4 mb-6">
                      <div className="flex items-center justify-center gap-2 text-center font-semibold text-sm mb-2">
                        <BsBank /> Balance Information
                      </div>
                      <div className="text-sm flex justify-between">
                        <span>Balance:</span>
                        <span
                          className={`${balance &&
                            balance.coins &&
                            parseFloat(balance.coins) > 0
                            ? "text-green-500"
                            : "text-red-500"
                            }`}
                        >
                          {balance && balance.coins
                            ? (Math.floor(Number(balance.coins) * 100) / 100)
                            : 0}
                        </span>
                      </div>
                      <div className="text-sm flex justify-between mb-4">
                        <span>Liability:</span>
                        <span
                          className={`${balance &&
                            balance.exposure &&
                            parseFloat(balance.exposure) > 0
                            ? "text-green-500"
                            : "text-red-500"
                            }`}
                        >
                          {balance && balance.exposure
                            ? (Math.floor(Number(balance.exposure) * 100) / 100)
                            : 0}
                        </span>
                      </div>

                      <div className="flex gap-2">
                        <button
                          onClick={() => {
                            localStorage.getItem("token")
                              ? navigate("/deposit")
                              : localStorage.setItem("unauthorized", true);

                            setIsDropdownOpen(false);
                          }}
                          className="flex-1 bg-[conic-gradient(from_0deg,#f15a24,#e4440a)] hover:bg-orange-600 text-white py-[6px] px-[12px] rounded"
                        >
                          Deposit
                        </button>
                        <button
                          onClick={() => {
                            localStorage.getItem("token")
                              ? navigate("/withdrawal")
                              : localStorage.setItem("unauthorized", true);

                            setIsDropdownOpen(false);
                          }}
                          className="flex-1 bg-[conic-gradient(from_0deg,#f15a24,#e4440a)] hover:bg-red-600 text-white py-1 px-3 rounded"
                        >
                          Withdraw
                        </button>
                      </div>
                    </div>

                    <div className="">
                      <div className="overflow-y-auto h-[35vh] scrollbar-thin scrollbar-thumb-white scrollbar-track-transparent">
                        <div className="">
                          <div
                            onClick={() => {
                              localStorage.getItem("token")
                                ? navigate("/profile")
                                : localStorage.setItem("unauthorized", true);

                              setIsDropdownOpen(false);
                            }}
                            className="flex items-center gap-2 text-sm py-3 px-4 hover:bg-rightbank  cursor-pointer account-sec"
                          >
                            <FaUser size={18} />
                            <span className="text-[12px]">Profile</span>
                          </div>

                          <div
                            onClick={() => {
                              localStorage.getItem("token")
                                ? navigate("/account-statement")
                                : localStorage.setItem("unauthorized", true);

                              setIsDropdownOpen(false);
                            }}
                            className="flex items-center gap-2 text-sm py-3 px-4 hover:bg-rightbank  cursor-pointer account-sec"
                          >
                            <FaChartBar size={18} />
                            <span className="text-[12px]">Account Statement</span>
                          </div>
                          {/* <div className="flex items-center gap-2 text-sm py-3 px-4 hover:bg-rightbank rounded-lg cursor-pointer">
                      <FaCreditCard />
                      <span className="text-[12px]">Add Payment Methods</span>
                    </div> */}
                          <div
                            onClick={() => {
                              localStorage.getItem("token")
                                ? navigate("/profit-loss")
                                : localStorage.setItem("unauthorized", true);

                              setIsDropdownOpen(false);
                            }}
                            className="flex items-center gap-2 text-sm py-3 px-4 hover:bg-rightbank  cursor-pointer account-sec"
                          >
                            <FaDollarSign size={18} />
                            <span className="text-[12px]">Profit And Loss</span>
                          </div>

                          <div
                            onClick={() => {
                              localStorage.getItem("token")
                                ? navigate("/deposit-withdrwal")
                                : localStorage.setItem("unauthorized", true);

                              setIsDropdownOpen(false);
                            }}
                            className="flex items-center gap-2 text-sm py-3 px-4 hover:bg-rightbank  cursor-pointer account-sec"
                          >
                            <FaHistory size={18} />
                            <span className="text-[12px]">Wallet Transactions</span>
                          </div>

                          <div
                            className="flex items-center gap-2 text-sm py-3 px-4 hover:bg-rightbank  cursor-pointer  account-sec"
                            onClick={() => {
                              localStorage.getItem("token")
                                ? navigate("/information")
                                : localStorage.setItem("unauthorized", true);

                              setIsDropdownOpen(false);
                            }}
                          >
                            <FaInfoCircle size={18} />
                            <span className="text-[12px]">Information</span>
                          </div>

                          <div
                            className="flex items-center gap-2 text-sm py-3 px-4 hover:bg-rightbank  cursor-pointer  account-sec"
                            onClick={() => {
                              localStorage.getItem("token")
                                ? navigate("/contact")
                                : localStorage.setItem("unauthorized", true);

                              setIsDropdownOpen(false);
                            }}
                          >
                            <FaPhone size={18} />
                            <span className="text-[12px]">Contact Us</span>
                          </div>
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          localStorage.clear();
                          setIsDropdownOpen(false);
                          window.location.reload();
                        }}
                        className="flex items-center gap-2 text-sm py-3 px-4 hover:bg-rightbank cursor-pointer  account-sec"
                      >
                        <FaSignOutAlt size={18} />
                        <span className="text-[12px]">LogOut</span>
                      </div>

                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </header>

        <div className="flex lg:hidden gap-1 mt-5   overflow-auto w-screen uppercase justify-around">
          {data?.map((item, index) => (
            <div
              onClick={() => {
                if (!item?.access) {
                  localStorage.getItem("token")
                    ? navigate(item?.path)
                    : localStorage.setItem("unauthorized", true);
                } else {
                  navigate(item?.path);
                }
              }}

              key={index}
              className="flex flex-col items-center text-gray-300 px-2 hover:text-red-500"
            >
              <img
                src={item.img}
                alt={item.title}
                className="min-w-5 min-h-5 w-5 h-5 "
              />
              <div className="order-2 py-2 text-center">
                <h3 className="text-xs text-nowrap font-semibold">
                  {item.title}
                </h3>
              </div>
            </div>
          ))}

        </div>
        <hr className="w-full bg-white text-white md:hidden block" />
      </div>

      {domainSetting && domainSetting?.clientNotification && (
        <marquee className="bg-white rounded-md text-xs py-0.5 mt-1 font-semibold visible md:hidden">
          {domainSetting?.clientNotification}
        </marquee>
      )}
      <Login isOpen={isLoginOpen} closeModal={closeModal} />
      {isSignUpOpen && (
        <SignIn isSignUpOpen={isSignUpOpen} setIsSignUpOpen={setIsSignUpOpen} />
      )}


    </>
  );
}

export default Header;
