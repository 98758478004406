export const data = [
    {
      id: 1,
      icon: "📍",
      content:
        "Magic Win Group Limited 2 WOODBERRY GROVE, FINCHILY LONDON, ENGLAND N12 0DR, Company No. 13980038F",
      order: 1,
    },
    {
      id: 2,
      icon: "📞",
      content: "+918401616518",
      order: 2,
    },
    {
      id: 3,
      icon: "✉️",
      content: "info@magicwin360.club",
      order: 3,
    },
    {
      id: 4,
      icon: "🌐",
      content: "magicwin360.club",
      order: 4,
    },
  ];