import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiSolidError } from "react-icons/bi";
import Blinking from "../component/Blinking/Blinking.js";
import { useDispatch } from "react-redux";
import axios from "axios";
import io from "socket.io-client";
import { BetPlaceDesktop } from "../component/BetPlace/BetPlaceDesktop.js";
import { BetPlaceMobile } from "../component/BetPlace/BetPlaceMobile.js";
import { TbLadder } from "react-icons/tb";
import moment from "moment";
import {
  FaEdit,
  FaList,
  FaMinus,
  FaPlus,
  FaRegListAlt,
  FaTv,
} from "react-icons/fa";
import FancyLadder from "../component/FancyLadder/FancyLadder.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OpenBetsMobile from "../component/OpenBet/OpenBetMobile.js";
import { message } from "antd";
import { apiCall } from "../middelware/Http.js";
import Rules from "../component/rules/Rules.js";
import Loader from "../component/Loader/Loader.js";
import { ImCross } from "react-icons/im";
import BetListComponent from "../component/BetListComponent/BetListComponent.js";
import BetListComponentMobile from "../component/BetListComponent/BetListComponentMobile.js";
import { getUserBalance } from "../redux/_reducers/_user_reducers.js";
import EditStack from "../component/EditStake/EditStake.js";

const MatchViewDeatils = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [inplayMatch, setInplayMatch] = useState({});
  const [scoreShow, setScoreShow] = useState(true);
  const [tvShow, setTvShow] = useState(false);
  const [betShow, setBetShow] = useState(true);
  const [betShowMobile, setBetShowMobile] = useState(false);
  const [matchScoreDetails, setMatchScoreDetails] = useState({});
  const [matchDetailsForSocketNew, setMatchDetailsForSocketNew] = useState({});
  const [finalSocket, setFinalSocketDetails] = useState({});
  const [otherFinalSocket, setOtherFinalSocketDetails] = useState({});
  const [isOpenRightSidebar, setIsOpenRightSidebar] = useState(false);
  const [hiddenRows, setHiddenRows] = useState([]);
  const [active, setActive] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [betSlipData, setBetSlipData] = useState({
    stake: "0",
    count: 0,
    teamname: "",
    teamData: null,
  });

  const [minMaxCoins, setminMaxCoins] = useState({ max: null, min: null });
  const [sessionCoin, setSessionCoin] = useState({ max: null, min: null });
  const [isTieCoin, setIsTieCoin] = useState({ max: null, min: null });
  const [isTossCoin, setIsTossCoin] = useState({ max: null, min: null });
  const [isMatchCoin, setIsMatchCoin] = useState({ max: null, min: null });

  const [fancyBetData, setFancyBetData] = useState([]);
  const [oddsBetData, setOddsBetData] = useState([]);
  const [returnDataObject, setReturnDataObject] = useState({});
  const [returnDataFancyObject, setReturnDataFancyObject] = useState({});
  const [fancypositionModal, setFancypositionModal] = useState(false);
  const [positionData, setPositionData] = useState({});
  const [betLoading, setBetLoading] = useState(false);
  const scrollRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [socketState, setSocketState] = useState(null);

  const [positionObj, setPositionObj] = useState({});
  const [positioBetData, setPositionBetData] = useState({});

  const [fancyPositionObj, setFancyPositionObj] = useState({});
  const [fancybetData, setFancybetData] = useState({});
  const [fullscreen, setFullScreen] = useState(false);
  const [isBookmekar, setIsBookmaker] = useState(true);
  const [isBookmekarHide, setIsBookmakerHide] = useState(true);
  const [isFancy, setIsFancy] = useState(true);
  const [isFancyHide, setIsFancyHide] = useState(true);
  const [isCommFancy, setIsCommFancy] = useState(true);
  const [isCommFancyHide, setIsCommFancyHide] = useState(true);
  const [isMatchOddsHide, setIsMatchOddsHide] = useState(true);
  const [isTossHide, setIsTossHide] = useState(true);
  const [isTieHide, setIsTieHide] = useState(true);
  const [isTieMatch, setIsTieMatch] = useState(true);
  const [stakeModal, setStakeModal] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [oneBetAmount, setOneBetAmount] = useState(
    localStorage.getItem("oneBetAmount") || "100"
  );
  const [oneClickBet, setOneClickBet] = useState(
    localStorage.getItem("oneClickStatus")
  );
  const [showStack, setShowStack] = useState(false);
  const [isScorecardOpen, setIsScorecardOpen] = useState(false);
  const [betListModal, setBetListModal] = useState(false);
  const [hideRowFancy, setHideRowFancy] = useState([]);
  const [deleteRowFancy, setDeleteRowFancy] = useState([]);
  const [hideRowCommFancy, setHideRowCommFancy] = useState([]);
  const [deleteRowCommFancy, setDeleteRowCommFancy] = useState([]);
  const toggleStack = () => {
    setShowStack((prevState) => !prevState);
  };

  const [isToss, setIsToss] = useState(true);

  // const [betPlaceModalMobile, setBetPlaceModalMobile] = useState(false);

  let { marketId, eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const gameDetailOtherPart = pathname.includes("viewMatchDetail");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const threshold = 100;
      setIsFixed(scrollPosition > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let data = localStorage.getItem(`${marketId}_BookmakerData`);
  const setDataFromLocalstorage = async (marketId) => {
    if (data) {
      setMatchScoreDetails(JSON.parse(data).result);
    } else {
      setMatchScoreDetails("");
    }
  };

  useEffect(() => {
    setDataFromLocalstorage();
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "visible" &&
        isConnected &&
        inplayMatch?.data?.socketUrl
      ) {
        callSocket(inplayMatch?.data?.socketUrl);
      } else if (document.visibilityState === "hidden") {
        cleanupWebSocket();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    setupAsyncActions(marketId);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      cleanupWebSocket();
    };
  }, [eventId, marketId, isConnected]);

  const [oddsbetdata, setOddsbetData] = useState();
  const [incomletedFancy, setIncompletedFancy] = useState();
  const [compltedFancy, setCompletedFancy] = useState();

  useEffect(() => {
    if (positioBetData) {
      const sortedOddsBetData = positioBetData?.oddsBetData
        ? positioBetData?.oddsBetData
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        : [];

      const filteredFancyBetData = positioBetData?.fancyBetData
        ? positioBetData?.fancyBetData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        : [];

      const completeFancy =
        filteredFancyBetData && filteredFancyBetData.length > 0
          ? filteredFancyBetData.filter((element) => element.isDeclare === 1)
          : [];
      let showCompletedFancy = [];

      completeFancy.map((data, key) => {
        let pos = 0;
        if (data.decisionRun >= data.run && data.type === "Y") {
          pos = Math.round(data.amount * data.odds);
        } else if (data.decisionRun >= data.run && data.type === "N") {
          pos = Math.round(-1 * data.amount * data.odds);
        } else if (data.decisionRun < data.run && data.type === "Y") {
          pos = Math.round(-1 * data.amount);
        } else if (data.decisionRun < data.run && data.type === "N") {
          pos = Math.round(data.amount);
        }
        data.pos = pos;
        completeFancy[key].pos = pos;

        showCompletedFancy.push(data);
      });

      const finalPositionInfo = {};
      sortedOddsBetData &&
        sortedOddsBetData.forEach((item) => {
          const positionInfo = item.positionInfo;

          for (const key in positionInfo) {
            if (positionInfo.hasOwnProperty(key)) {
              if (!finalPositionInfo[key]) {
                finalPositionInfo[key] = 0;
              }
              finalPositionInfo[key] += positionInfo[key];
            }
          }
        });

      let finalPositionInfoFancy = {};

      filteredFancyBetData.forEach((item) => {
        const selectionId = item.selectionId;
        const loss = item.loss;

        if (finalPositionInfoFancy[selectionId]) {
          finalPositionInfoFancy[selectionId] += loss;
        } else {
          finalPositionInfoFancy[selectionId] = loss;
        }
      });

      setFancyPositionObj(finalPositionInfoFancy);
      setFancybetData(filteredFancyBetData);

      setPositionObj(finalPositionInfo);
      setOddsbetData(sortedOddsBetData);
      setCompletedFancy(showCompletedFancy);
      setIncompletedFancy(
        filteredFancyBetData && filteredFancyBetData.length > 0
          ? filteredFancyBetData.filter((element) => element.isDeclare === 0)
          : []
      );
    }
  }, [positioBetData]);

  useEffect(() => {

    
    const maxCoinData = inplayMatch?.maxMinCoins
      ? JSON.parse(inplayMatch?.maxMinCoins)
      : {
        maximum_match_bet: null,
        minimum_match_bet: null,
        maximum_session_bet: null,
        minimum_session_bet: null,
      };


    setminMaxCoins({
      max: maxCoinData?.maximum_match_bet,
      min: maxCoinData?.minimum_match_bet,
    });
    setSessionCoin({
      max: maxCoinData?.maximum_session_bet,
      min: maxCoinData?.minimum_session_bet,
    });


    setIsTieCoin({
      max: maxCoinData?.maximum_tie_coins > 0 ? maxCoinData?.maximum_tie_coins : maxCoinData?.maximum_match_bet,
      min: maxCoinData?.minimum_match_bet,
    });

    setIsTossCoin({
      max: maxCoinData?.maximum_toss_coins > 0 ? maxCoinData?.maximum_toss_coins : maxCoinData?.maximum_match_bet,
      min: maxCoinData?.minimum_match_bet,
    });

    setIsMatchCoin({
      max: maxCoinData?.maximum_matchOdds_coins > 0 ? maxCoinData?.maximum_matchOdds_coins : maxCoinData?.maximum_match_bet,
      min: maxCoinData?.minimum_match_bet,
    });



  }, [inplayMatch]);


  const setupAsyncActions = async (marketId) => {
    await getMatchDataByMarketID(marketId);
    fetchBetLists();
    // userPositionByMarketId(marketId);
  };

  const cleanupWebSocket = () => {
    if (socketState) {
      socketState.disconnect();
      setSocketState(null);
    }
  };

  const getMatchDataByMarketID = async (marketId) => {
    try {
      const resData = {
        marketId: marketId,
      };
      const inplayMatchResponse = await apiCall(
        "POST",
        "sports/sportByMarketId",
        resData
      );
      if (inplayMatchResponse && inplayMatchResponse.data) {
        setInplayMatch(inplayMatchResponse.data);
        const data = inplayMatchResponse?.data;

        if (inplayMatchResponse?.data?.socketPerm) {
          callSocket(inplayMatchResponse?.data?.socketUrl, inplayMatchResponse?.data);
        } else {
          callCache(inplayMatchResponse?.data?.cacheUrl);
        }

        // callSocket(inplayMatchResponse?.data?.socketUrl, inplayMatchResponse?.data?.socketPerm, inplayMatchResponse?.data?.cacheUrl);
      }
    } catch (error) {
      console.error("Error fetching inplay data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const callSocket = async (socketUrl, matchData) => {
    if (socketState && socketState.connected) {
      return;
    }
    try {
      const socket = io.connect(socketUrl, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99,
      });

      socket.emit(`marketByEvent`, eventId);
      socket.on(eventId, (data) => {
        setMatchDetailsForSocketNew(JSON.parse(data));
        setIsConnected(true);
        filterData(JSON.parse(data));
      });

      matchData.sportId === 4 && socket.emit("JoinRoom", marketId);

      matchData.sportId === 4 && socket.on(marketId, (data) => {
        localStorage.setItem(`${marketId}_BookmakerData`, data);
        setMatchScoreDetails(JSON.parse(data).result);
      });

      socket.on("disconnect", () => {
        setIsConnected(false);
      });

      setSocketState(socket);
    } catch (error) {
      console.error("Error in socket connection:", error);
    }
  };

  const callCache = async (cacheUrl) => {
    try {
      const interval = setInterval(async () => {
        await getMarketCacheUrl(cacheUrl);
      }, 1000);
      return () => clearInterval(interval);
    } catch (error) {
      console.error("Error calling cache:", error);
    }
  };

  const getMarketCacheUrl = async (cacheUrl) => {
    try {
      // if (!cacheUrl) {
      //   console.error("Cache URL is undefined or null");
      //   return; // Exit early if cacheUrl is undefin
      // }
      const response = await axios.get(cacheUrl);
      localStorage.setItem(
        `${marketId}_BookmakerData`,
        JSON.stringify(response.data)
      );
      setMatchScoreDetails(response.data.result);
    } catch (error) {
      console.error("Error fetching cache data:", error);
    }
  };

  // const filterData = (matchDetailsForSocketNew) => {
  //   try {
  //     if (!matchDetailsForSocketNew || matchDetailsForSocketNew.length === 0) {
  //       return;
  //     }

  //     const criteria = ["Tied Match", "Match Odds", "To Win the Toss"];

  //     const filteredData = Array.isArray(matchDetailsForSocketNew)
  //       ? matchDetailsForSocketNew.filter((item) =>
  //           criteria.includes(item.marketType)
  //         )
  //       : [];

  //     if (filteredData.length > 0) {
  //       const filteredDataObject = [];
  //       filteredData.forEach((item) => {
  //         filteredDataObject[item.marketType] = item;
  //       });

  //       setFinalSocketDetails(filteredDataObject);
  //     } else {
  //       console.error("No data matched the criteria.");
  //     }
  //   } catch (error) {
  //     console.error("Error filtering data:", error);
  //   }
  // };


  const filterData = (matchDetailsForSocketNew) => {
    try {
      if (!matchDetailsForSocketNew || matchDetailsForSocketNew.length === 0) {
        return;
      }
      const criteria = ["Tied Match", "Match Odds", "To Win the Toss"];

      const filteredData = Array.isArray(matchDetailsForSocketNew)
        ? matchDetailsForSocketNew.filter((item) =>
          criteria.includes(item.marketType)
        )
        : [];


      if (filteredData.length > 0) {
        const filteredDataObject = [];
        filteredData.forEach((item) => {
          filteredDataObject[item.marketType] = item;
        });
        setFinalSocketDetails(filteredDataObject);
      } else {
        console.error("No data matched the first criteria.");
      }

      const otherData = Array.isArray(matchDetailsForSocketNew)
        ? matchDetailsForSocketNew.filter((item) =>
          !criteria.includes(item.marketType)
        )
        : [];

      if (otherData.length > 0) {
        const OtherFilteredDataObject = [];
        otherData.forEach((item) => {
          OtherFilteredDataObject[item.marketType] = item;
        });
        setOtherFinalSocketDetails(OtherFilteredDataObject);
      } else {
        console.log("No other data matched.");
      }

    } catch (error) {
      console.error("Error filtering data:", error);
    }
  };

































  const handleOnClick = () => {
    navigate("/");
  };

  // const handelScoreModal = () => {
  //   setScoreShow(!scoreShow);
  // };

  const handelScoreModal = () => {
    setScoreShow(true);
    setTvShow(false);
    setBetShowMobile(false);
  };
  const handelTvModal = () => {
    setTvShow(!tvShow);
    setScoreShow(true);
    setBetShowMobile(false);
  };

  const handelAllClossModal = () => {
    setTvShow(false);
    setScoreShow(!scoreShow);
  };

  const openBets = () => {
    setBetShow(true);
  };

  const openBetsClose = () => {
    setBetShow(false);
  };

  const toggleAccordion = (index) => {
    setActive((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // bets Palce Modal write

  const handleBackOpen = async (data) => {
    // setBetPlaceModalMobile(true)

    let oneClickBet = localStorage.getItem("oneClickStatus")
      ? JSON.parse(localStorage.getItem("oneClickStatus"))
      : false;
    let oneBetAmount =
      localStorage.getItem("oneBetAmount") &&
        localStorage.getItem("oneBetAmount") != "undefined"
        ? JSON.parse(localStorage.getItem("oneBetAmount"))
        : 0;

    if (oneClickBet) {
      if (data) {
        setBetShow(false);
        setBetSlipData({
          ...data,
          stake: oneBetAmount,
          count: data.odds,
          teamname: data.name,
          teamData: data.teamData,
        });
      }
    } else {
      if (data) {
        setBetShow(false);
        setBetSlipData({
          ...data,
          stake: 0,
          count: data.odds,
          teamname: data.name,
          teamData: data.teamData,
        });
      }
    }
  };

  useEffect(() => {

    
    if (betSlipData && betSlipData?.stake != 0) {
      placeBet();
    }
  }, [betSlipData]);

  const toggleRowVisibility = (id) => {
    if (hiddenRows.includes(id)) {
      // !== lagane se open close ho jayega
      setHiddenRows(hiddenRows.filter((rowId) => rowId === id));
    } else {
      setHiddenRows([...hiddenRows, id]);
    }
  };

  const userPositionByMarketId = async () => {
    const positionByMarketId = {
      marketId: marketId,
    };
    try {
      const userPositionData = await apiCall(
        "POST",
        "sports/userPositionByMarketId",
        positionByMarketId
      );
      if (userPositionData) {
        const getUserPositionItem = userPositionData.data;
        let oddsPos = [];
        let sessPos = [];
        let returnDataObject = {};
        let returnDataFancyObject = {};

        if (getUserPositionItem?.oddsPosition) {
          oddsPos = getUserPositionItem.oddsPosition;
          oddsPos.forEach((data) => {
            const hasKey = returnDataObject.hasOwnProperty(data._id);
            if (!hasKey) {
              returnDataObject[data._id] = data.totalPosition.toFixed(2);
              setReturnDataObject(returnDataObject);
            }
          });
        }

        if (getUserPositionItem?.sessionPosition) {
          sessPos = getUserPositionItem.sessionPosition;
          sessPos.forEach((data) => {
            const hasKey = returnDataFancyObject.hasOwnProperty(data._id);
            if (!hasKey) {
              returnDataFancyObject[data._id] = data.position;
              setReturnDataFancyObject(returnDataFancyObject);
            }
          });
        }
      }
    } catch (error) {
      console.error("Error fetching user position:", error);
      throw error;
    }
  };

  const placeBet = async () => {
    try {
      const betObject = {
        odds: betSlipData.count + "",
        amount: betSlipData.stake,
        selectionId: betSlipData.selectionId + "",
        marketId: marketId + "",
        eventId: eventId,
        betFor: betSlipData.betFor + "",
        run: betSlipData.run ? betSlipData.run + "" : "0",
        oddsType:
          betSlipData.oddsType === "Match Odds"
            ? "matchOdds"
            : betSlipData.oddsType === "Tied Match"
              ? "tiedMatch"
              : betSlipData.oddsType + "",
        type: betSlipData.betType + "",
      };

      if (
        betSlipData.oddsType === "bookmaker" ||
        betSlipData.oddsType === "fancy"
      ) {
        // Do something if needed
      } else {
        betObject["betfairMarketId"] = betSlipData.betfairMarketId + "";
      }
      setBetLoading(true);

      // this.setState({ betLoading: true, betPlaceModal: false })
      if (betSlipData.oddsType === "fancy") {
        let saveBetOdds = await apiCall(
          "POST",
          "sports/sessionBetPlaced",
          betObject
        );

        setBetShow(true);
        setBetLoading(false);
        // setBetPlaceModalMobile(false)

        if (!saveBetOdds.error) {
          // const toastId = toast.success("Success! Your bet has been placed");
          // setTimeout(() => toast.dismiss(toastId), 1000);

          message.success("Success! Your bet has been placed");
          // await userPositionByMarketId()
          await fetchBetLists();
          await matchOddsPos();

          //   dispatch(userAction.userBalance());
          toast.success(saveBetOdds.message, {
            autoClose: 500,
          });
        } else {
          // const toastId = toast.error("Sorry! your bet couldn't be placed.");
          // setTimeout(() => toast.dismiss(toastId), 1000);
          toast.error(saveBetOdds?.data?.message, {
            autoClose: 500,
          });
          message.error("Sorry, your bet couldn't be placed.");
        }
      } else {
        let saveBetOdds = await apiCall(
          "POST",
          "sports/oddBetPlaced",
          betObject
        );

        setBetShow(true);
        setBetLoading(false);
        // setBetPlaceModalMobile(false);

        if (!saveBetOdds.error) {
          message.success("Success! Your bet has been placed");

          // await userPositionByMarketId()
          await fetchBetLists();
          await matchOddsPos();
          //   dispatch(userAction.userBalance());

          toast.success(saveBetOdds?.data?.message, {
            autoClose: 500,
          });
        } else {
          console.log(saveBetOdds);
          message.error("Sorry! your bet couldn't be placed.");
          toast.error(saveBetOdds?.data?.message, {
            autoClose: 500,
          });
          // toast.success("Sorry, your bet couldn't be placed.");
        }
      }
    } catch (error) {
      toast.error(error?.data?.message, {
        autoClose: 500,
      });
      console.error("Error placing bet:", error);
    } finally {
      setBetLoading(false);
      setBetSlipData({
        stake: "0",
        count: 0,
        teamname: "",
        teamData: null,
      });
      setHiddenRows([]);
      dispatch(getUserBalance());
    }
  };

  const fetchBetLists = async () => {
    try {
      const BetListData = {
        fancyBet: true,
        isDeclare: false,
        oddsBet: true,
        marketId: marketId,
      };

      const userBetHistory = await apiCall(
        "POST",
        "sports/betsList",
        BetListData
      );
      if (userBetHistory && userBetHistory.data) {
        const { fancyBetData, oddsBetData } = userBetHistory.data;
        const filteredFancyBetData = fancyBetData
          ? fancyBetData
            .filter((element) => element.isDeclare === 0)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          : [];
        const sortedOddsBetData = oddsBetData
          ? oddsBetData
            .slice()
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          : [];
        setFancyBetData(filteredFancyBetData);
        setOddsBetData(sortedOddsBetData);
        setPositionBetData(userBetHistory.data);
        // return { fancyBetData: filteredFancyBetData, oddsBetData: sortedOddsBetData };
      }
    } catch (error) {
      console.error("Error fetching bet lists:", error);
      throw error;
    }
  };

  const matchOddsPos = async () => {
    let matchOddsPos = await apiCall("POST", "reports/matchOddsRunningPos");
    if (matchOddsPos) {
      localStorage.setItem(
        "matchOddsRunningPos",
        JSON.stringify(matchOddsPos.data)
      );
    }
  };

  const handleFancyPositionModal = (data) => {
    try {
      setFancypositionModal(!fancypositionModal);
      setPositionData(data);
    } catch (error) {
      console.error("Error handling fancy position modal:", error);
    }
  };

  const handleClose = () => {
    setFancypositionModal(false);
  };

  const closeRow = (id) => {
    setHiddenRows(hiddenRows.filter((rowId) => rowId !== id));
  };

  const increaseCount = () => {
    try {
      setBetSlipData((prevData) => {
        const newCount = parseFloat(prevData.count) + 0.01;
        return {
          ...prevData,
          count: newCount.toFixed(2),
        };
      });
    } catch (error) {
      console.error("Error increasing count:", error);
    }
  };
  const openBetInMobile = () => {
    setBetShowMobile(!betShowMobile);
    setTvShow(false);
    setScoreShow(false);
  };
  const decreaseCount = () => {
    try {
      setBetSlipData((prevData) => {
        const newCount = parseFloat(prevData.count) - 0.01;
        return {
          ...prevData,
          count: newCount.toFixed(2),
        };
      });
    } catch (error) {
      console.error("Error decreasing count:", error);
    }
  };

  const openRuleModal = () => {
    setIsRulesOpen((prevState) => !prevState);
  };
  const closeRuleModal = () => setIsRulesOpen(false);



  // const [isPopoverVisible, setPopoverVisible] = useState(false);


  // // Toggle popover visibility on button click
  // const togglePopover = (value) => {
  //   setPopoverVisible(!isPopoverVisible);
  // };
  const [isPopoverVisible, setPopoverVisible] = useState({
    matchOdds: false,
    bookmakerStake: false,
    soccerStake: false,
    tossStake: false,
    tieStake: false
  });

  const togglePopover = (popover) => {
    setPopoverVisible(prevState => ({
      ...prevState,
      [popover]: !prevState[popover]
    }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleClickBetChange = () => {
    setOneClickBet((prevState) => {
      const newState = !prevState;
      localStorage.setItem("oneClickStatus", newState);
      return newState;
    });
  };

  const match = inplayMatch?.matchName ? inplayMatch?.matchName : "";
  let team1 = "",
    team2 = "";

  if (match.includes(" v ") || match.includes(" V ")) {
    [team1, team2] = match.split(/ v | V /);
  } else if (match.includes(" vs ") || match.includes(" VS ")) {
    [team1, team2] = match.split(/ vs | VS /);
  }

  const [acceptAllOdds, setAcceptAllOdds] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // const [betValue, setBetValue] = useState("100");
  const [betSlipDataNew, setBetSlipDataNew] = useState(
    JSON.parse(localStorage.getItem("betSlipData"))
  );
  const [oneBetAmountlocal, setoneBetAmountlocal] = useState(
    localStorage.getItem("oneBetAmount")
  );

  const [localBetSlipData, setLocalBetSlipData] = useState(betSlipDataNew);

  const handleEditClick = () => setIsEditing(true);

  const handleSaveClick = () => {
    setIsEditing(false);

    setBetSlipDataNew(localBetSlipData);

    localStorage.setItem("betSlipData", JSON.stringify(localBetSlipData));

    setIsEditing(false);

    console.log("Saved Bet Values:", localBetSlipData);
  };
  const handleBetStackUpdate = (e, index) => {
    const updatedBetSlipData = [...localBetSlipData];

    updatedBetSlipData[index] = e.target.value;

    setLocalBetSlipData(updatedBetSlipData);
  };

  const updateStackSelected = async (num) => {
    if (isEditing) return;
    let resData = {
      isOneClickBet: true,
      oneClickBetAmount: num,
    };
    const response = await apiCall("POST", `user/updateOneClickBet`, resData);
    if (response.error === false) {
      toast.success(response.message, {
        autoClose: 500,
        className: "custom-toast",
      });
      localStorage.setItem("oneBetAmount", num);
      try {
        setoneBetAmountlocal(num);
      } catch (error) {
        console.error("Error updating stake:", error);
      }
    }
  };





  return isLoading ? (
    <Loader />
  ) : (
    <section className="afterFooter mb-12 mt-2">
      {/* <div className="bg-[#272727] w-full px-3 flex justify-center">
        <NavigatePathComponent showPathName={inplayMatch && inplayMatch?.matchName
          ? inplayMatch?.matchName
          : ""} />

      </div> */}
      {stakeModal && <EditStack closeModal={() => setStakeModal(false)} />}
      {betListModal && (
        <>
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 p-4" />

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-secondary rounded-lg shadow-lg w-96 border-2 border-button py-2">
              <div className="max-h-96 overflow-y-auto">
                <BetListComponentMobile
                  oddsBetData={oddsBetData}
                  fancyBetData={fancyBetData}
                  matchName={inplayMatch?.matchName}
                  setBetListModal={setBetListModal}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {!betShow && !stakeModal && (
        <div className="fixed  sm:hidden inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white  rounded-lg max-w-md w-full relative">
            <div>
              <BetPlaceDesktop
                setStakeModal={setStakeModal}
                closeRow={closeRow}
                betShow={betShow}
                setBetShow={setBetShow}
                matchName={inplayMatch?.matchName}
                openBets={openBets}
                betSlipData={betSlipData}
                placeBet={placeBet}
                count={betSlipData.count}
                betLoading={betLoading}
                increaseCount={increaseCount}
                decreaseCount={decreaseCount}
              />
            </div>
          </div>
        </div>
      )}
      <div className="w-full  relative bg-black">
        <div className="flex w-full">
          {/* <div className="lg:w-1/6  w-full hidden lg:block py-2">
                        <div className="w-full bg-black flex justify-center">
                            <div className="w-11/12 border-2 border-[#f36c21]  rounded-3xl">
                                <SportListSidebar marketId={marketId} />
                            </div>
                        </div>
                    </div> */}

          {/* lg:w-7/12 */}
          <div className="lg:w-8/12 w-full h-auto lg:px-1 md:pb-0 pb-6">
            <>
              <div className="rounded-md bg-gray-600 w-full px-1 py-1 flex gap-1 justify-end">
                <div className=" py-1 rounded-sm bg-black w-full  text-white px-2">
                  <div className="p-0.5  rounded-sm w-full flex justify-between text-white px-2">
                    <div className="text-xs font-bold flex items-center space-x-1">
                      <span
                        className={`px-4 rounded py-0.5 cursor-pointer ${activeTab === "all"
                          ? "bg-button text-black"
                          : "text-white"
                          }`}
                        onClick={() => handleTabClick("all")}
                      >
                        All
                      </span>
                      <span
                        className={`px-2 rounded py-0.5 cursor-pointer ${activeTab === "matchOdds"
                          ? "bg-button text-black"
                          : "text-white"
                          }`}
                        onClick={() => handleTabClick("matchOdds")}
                      >
                        Match Odds
                      </span>
                      <span
                        className={`px-2 rounded py-0.5 cursor-pointer ${activeTab === "Fancy"
                          ? "bg-button text-black"
                          : "text-white"
                          }`}
                        onClick={() => handleTabClick("Fancy")}
                      >
                        Fancy
                      </span>
                      <span
                        className={`px-2 rounded py-0.5 cursor-pointer hidden sm:block lg:hidden`}
                        onClick={() => setBetListModal(true)}
                      >
                        Bet List
                      </span>
                    </div>
                    <div className="text-xs font-bold flex items-center space-x-2">
                      <span
                        className={`px-2 text-[10px] ${showStack
                          ? "bg-button text-black"
                          : "bg-[#2D2E2E] text-white"
                          } text-white rounded py-0.5`}
                          onClick={() => {
                            toggleStack();
                    
               const currentStatus = JSON.parse(localStorage.getItem('oneClickStatus'));
        
                            const newStatus = currentStatus === true ? false : true;
                    
             
                            localStorage.setItem('oneClickStatus', JSON.stringify(newStatus));
                        }}
                      >
                        1 click betting
                      </span>
                    </div>
                  </div>

                  {showStack && (
                    <>
                      <div className="bg-[#2D2E2E] py-1.5 mt-1 hidden md:flex  rounded-md  w-full  justify-between items-center  text-white px-2">
                        <div
                          className="text-[11px] font-bold "
                          onClick={() => setShowStack(false)}
                        >
                          1 click Bet
                        </div>
                        <div className="flex space-x-2 text-xs font-bold">
                          {localBetSlipData?.map((ele, index) => (
                            <input
                              key={index}
                              type="text"
                              readOnly={!isEditing}
                              // disabled={!isEditing}
                              value={ele}
                              onClick={() => updateStackSelected(ele)}
                              onChange={(e) => handleBetStackUpdate(e, index)}
                              className={`text-center ${oneBetAmountlocal == ele
                                ? "border-button border text-button"
                                : "border border-white"
                                } py-1 w-20  bg-transparent outline-none  `}
                            />
                          ))}
                        </div>
                        <div>
                          {isEditing ? (
                            <div className="flex items-center text-[10px] font-semibold space-x-2">
                              <button
                                onClick={handleSaveClick}
                                className="bg-green-600 text-white px-2 py-1  rounded"
                              >
                                Save
                              </button>
                              <button
                                onClick={() => {
                                  setIsEditing(false);
                                  setLocalBetSlipData(betSlipDataNew);
                                }}
                                className="bg-red-600 text-white px-2 py-1  rounded"
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div className="flex items-center space-x-2">
                              <span
                                className="text-[11px] flex gap-1 items-center font-bold cursor-pointer"
                                onClick={handleEditClick}
                              >
                                <FaEdit size={12} className="text-yellow-500" />{" "}
                                Edit
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="bg-[#2D2E2E] py-1.5 mt-1 block md:hidden  rounded-md  w-full  justify-between items-center  text-white px-2">
                        <div
                          className="text-[11px] font-bold "
                          onClick={() => setShowStack(false)}
                        >
                          1 click Bet
                        </div>
                        <div className="flex justify-between w-full items-center">
                          <div className="flex space-x-2 text-xs font-bold ">
                            {localBetSlipData?.map((ele, index) => (
                              <input
                                key={index}
                                type="text"
                                readOnly={!isEditing}
                                // disabled={!isEditing}
                                value={ele}
                                onClick={() => updateStackSelected(ele)}
                                onChange={(e) => handleBetStackUpdate(e, index)}
                                className={`text-center ${oneBetAmountlocal == ele
                                  ? "border-button border text-button"
                                  : "border border-white"
                                  } py-1 w-20  bg-transparent outline-none  `}
                              />
                            ))}
                          </div>
                          <div>
                            {isEditing ? (
                              <div className="flex items-center text-[10px] font-semibold space-x-2">
                                <button
                                  onClick={handleSaveClick}
                                  className="bg-green-600 text-white px-2 py-1  rounded"
                                >
                                  Save
                                </button>
                                <button
                                  onClick={() => {
                                    setIsEditing(false);
                                    setLocalBetSlipData(betSlipDataNew);
                                  }}
                                  className="bg-red-600 text-white px-2 py-1  rounded"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div className="flex items-center space-x-2">
                                <span
                                  className="text-[11px] flex gap-1 items-center font-bold cursor-pointer"
                                  onClick={handleEditClick}
                                >
                                  <FaEdit
                                    size={12}
                                    className="text-yellow-500"
                                  />{" "}
                                  Edit
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* copy design stary */}

              <div className="market-info p-0 match-info market-wrapper-info position-relative sm:block hidden mt-6  ">
                <div className="row m-0 f-14 pl-0 pr-0 pt-3 justify-content-between">
                  <div className="match-board-tournament-name ">
                    {/**/}
                    <span className="mt-3 ml-4 text-ms">
                      {
                        (() => {
                          const inputMoment = moment(inplayMatch?.matchDate, "DD-MM-YYYY HH:mm:ss A");
                          const currentMoment = moment();
                          return currentMoment.isSameOrAfter(inputMoment) ?
                            <img alt="" src="/assets/appImages/magic-win-biz/assets/icons/sports/live.svg" className="mt-3 ml-4" />
                            : '';
                        })()
                      }
                    </span>
                    <span className="flex  justify-end cursor w-full my-4  items-center">
                      <span className="mr-10 px-2 py-1 flex gap-x-2 items-center">
                        <img
                          src="/assets/tv.png"
                          alt="tv"
                          height={25}
                          width={25}
                          className=""
                          onClick={() => handelTvModal()}
                        />
                        <span
                          className="text-xs gap-1 uppercase rounded-full bg-button px-2.5 text-white font-bold py-1"
                          onClick={() => setFullScreen((state) => !state)}
                        >
                          <span>{fullscreen ? "HS" : "FS"}</span>
                        </span>
                      </span>
                    </span>

                    <span className="left-icon" />
                    <span className="t-name mb-1">
                      <font className="text-sm font-medium text-black">
                        {inplayMatch && inplayMatch?.seriesName
                          ? inplayMatch?.seriesName
                          : ""}
                      </font>
                    </span>
                    <span className="right-icon " />
                    <span className="date-tour  ">
                      <span className="text-black text-xs font-medium">
                        {inplayMatch && inplayMatch?.matchDate
                          ? moment(inplayMatch?.matchDate).format("dddd HH:mm ")
                          : ""}
                      </span>
                    </span>

                    <span className="market-tour-c">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={100}
                        height={26}
                        viewBox="0 0 190 26"
                        fill="Currentcolor"
                      >
                        <path
                          d="M0.208334 0.899998C3.00833 1.4 9.20833 2.6 14.2083 3.4C28.1083 5.9 33.9083 7.6 51.1083 13.9C73.9083 22.5 79.5833 24.7999 93 25.5C107.692 25.4999 116.508 23.5 132.708 17.2C156.308 8 166.008 4.8 177.408 2.4L189.208 -1.90735e-06H92.2083C30.8083 -1.90735e-06 -2.99167 0.399998 0.208334 0.899998Z"
                          fill="Currentcolor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div className=" flex items-center gap-2 w-full px-2 my-8">
                  <div className="bg-gradient-to-l from-[#e4440a] to-[#2E2F2F] w-2/5 py-2 rounded-r-full font-black uppercase text-white text-sm text-center">
                    {team1 ? team1 : "Team 1"}
                  </div>

                  <div className="w-1/5 flex justify-center text-white">
                    <svg
                      _ngcontent-ncm-c43=""
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 142.334 226.473"
                    >
                      <g
                        _ngcontent-ncm-c43=""
                        transform="translate(-770.844 -434.5)"
                      >
                        <path
                          _ngcontent-ncm-c43=""
                          d="M731.479,686.973l26.934-72.1H736.406l-29.561-81.129h27.262l17.435,53.691,16.725-53.691h19.708L815.236,460.5h8.211L740.183,686.973Z"
                          transform="translate(64 -26)"
                          fill="#fff "
                        ></path>
                        <path
                          _ngcontent-ncm-c43=""
                          d="M834.658,588.507l12.536-16.229s-9.149-9.4-27.09-11.489-28.4,5.27-28.4,5.27L781.4,594.24a25.212,25.212,0,0,0,10.667,12.073c7.774,4.261,13.672,4.44,18.987,5.382s13.455.673,13.3,6.645-8.439,5.539-16.886,3.6-17.806-9.135-17.806-9.135a85.64,85.64,0,0,1-5.367,8.761c-3.158,4.494-7.266,9.217-7.266,9.217s9.232,6.608,16.423,9.329,21.865,4.859,32.555,2.235,20.116-6.8,22.837-24-12.05-22.254-16.423-23.711-16.078-3.956-22.545-5.053-5.214-5.379-3.8-6.865,7.488-2.659,15.554-.326A42.842,42.842,0,0,1,834.658,588.507Z"
                          transform="translate(64 -26)"
                          fill="#fff"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className="w-2/5 bg-gradient-to-l from-[#2E2F2F] to-[#e4440a] py-2 text-white rounded-l-full font-black uppercase text-sm text-center">
                    {team2 ? team2 : "Team 2"}
                  </div>
                </div>

                {inplayMatch.isScore ? (
                  <>
                    {scoreShow && (
                      <div
                        className={`bg-white w-full ${fullscreen ? "h-[220px]" : "h-[110px]"
                          }`}
                      >
                        <div className="details">
                          <div
                            className={`w-full relative md:text-sm text-[10px]`}
                          >
                            <iframe
                              src={
                                inplayMatch && inplayMatch.scoreIframe
                                  ? inplayMatch.scoreIframe
                                  : ""
                              }
                              title=" "
                              loading="lazy"
                              className={`w-[100%] ${fullscreen ? "h-[220px]" : "h-[110px]"
                                }`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
              <div className="sm:hidden block">
                <div className="match-container flex text-center bg-white py-1 rounded-lg my-2 relative">
                  <div className="w-full">

                    <div className="match-date font-semibold text-gray-500">
                      {
                        (() => {
                          const inputMoment = moment(inplayMatch?.matchDate, "DD-MM-YYYY HH:mm:ss A");
                          const currentMoment = moment();
                          return currentMoment.isSameOrAfter(inputMoment) ?
                            <div className="absolute top-[5px] left-[10px] flex items-center">
                              <img alt="" src="/images/in-play-events-icon.png" className="w-5 h-5" />
                              <span className="text-[#008000] text-[13px] font-signika uppercase">Live</span>
                            </div>
                            : '';
                        })()
                      }
                      <span className="md:text-sm text-[13px]">

                        {" "}
                        {inplayMatch && inplayMatch?.matchDate
                          ? inplayMatch?.matchDate
                          : ""}
                      </span>
                    </div>
                    <div className="match-teams w-full flex justify-center items-center m-0 mt-1 text-[13px] font-semibold">
                      <span className="col p-0 text-right">
                        <span className="inline font-signika">
                          {team1 ? team1 : "Team 1"}
                        </span>
                      </span>
                      <div className="vs-circle mx-2 bg-yellow-400 px-1.5 py-1 text-[11px] font-signika font-semibold rounded-full">
                        <div>VS</div>
                      </div>
                      <span className="col p-0 text-left">
                        <span className="inline font-signika">
                          {team2 ? team2 : "Team 2"}
                        </span>
                      </span>
                    </div>
                    <div className="match-type mt-1 text-[13px] font-bold ">
                      <span className="inline font-signika">
                        {inplayMatch && inplayMatch?.seriesName
                          ? inplayMatch?.seriesName
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`market-button grid grid-cols-3 justify-items-center divide-x-2 text-xs md:text-sm divide-secondary gap-2  rounded-md border-2 border-secondary my-1 ${isScorecardOpen === false ? "bg-black" : "bg-button"
                    }`}
                >
                  <button
                    onClick={() => setIsScorecardOpen("tv")}
                    className={`btn-tv-score text-center flex items-center space-x-2 px-4 py-2 font-semibold ${isScorecardOpen === "tv" ? "text-black" : "text-gray-500"
                      }`}
                  >
                    <FaTv size={17} />
                    <span>Live Tv</span>
                  </button>
                  <button
                    className={`btn-tv-score text-center flex items-center space-x-2 px-4 py-2 font-semibold ${isScorecardOpen === "score"
                      ? "text-black"
                      : "text-gray-500"
                      }`}
                    onClick={() => setIsScorecardOpen("score")}
                  >
                    <FaRegListAlt size={16} />
                    <span>Scorecard</span>
                  </button>
                  <button
                    onClick={() => setBetListModal(true)}
                    className={`btn-tv-score text-center flex items-center space-x-2 px-4 py-2 font-semibold ${isScorecardOpen === "tv" ? "text-black" : "text-gray-500"
                      }`}
                  >
                    <FaList size={17} />
                    <span> Bet List</span>
                  </button>
                </div>
                <div
                  className={`market-button grid grid-cols-2 gap-2  rounded-md border-2 border-secondary my-1 `}
                ></div>
                <div>
                  {isScorecardOpen === "score" && inplayMatch.isScore ? (
                    <div className="border-2 border-secondary rounded-lg  my-1">
                      <div className="flex justify-between items-center px-2">
                        <div
                          onClick={() => setFullScreen((state) => !state)}
                          className="text-white bg-button rounded-sm px-2 py-0.5 text-xs font-semibold"
                        >
                          {fullscreen ? "HS" : "FS"}
                        </div>
                        <div
                          className="px-2 py-1 text-white bg-red-500 rounded-full text-xs"
                          onClick={() => setIsScorecardOpen(false)}
                        >
                          X
                        </div>
                      </div>
                      <div
                        className={`bg-white w-full ${fullscreen ? "h-[220px]" : "h-[110px]"
                          }`}
                      >
                        <div className="details">
                          <div
                            className={`w-full relative md:text-sm text-[10px]`}
                          >
                            <iframe
                              src={
                                inplayMatch && inplayMatch.scoreIframe
                                  ? inplayMatch.scoreIframe
                                  : ""
                              }
                              title=" "
                              loading="lazy"
                              className={`w-[100%] ${fullscreen ? "h-[220px]" : "h-[110px]"
                                }`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {inplayMatch.isTv ? (
                    <>
                      {isScorecardOpen === "tv" && inplayMatch.tvUrl && (
                        <div className="bg-white w-full rounded-lg border-2 border-secondary">
                          <div className="flex justify-end items-center px-2">
                            <div
                              className="px-2 py-1 text-white bg-red-500 rounded-full text-xs"
                              onClick={() => setIsScorecardOpen(false)}
                            >
                              X
                            </div>
                          </div>
                          <div className="details">
                            <div
                              className={`w-full relative md:text-sm text-[10px]`}
                            >
                              <iframe
                                src={
                                  inplayMatch && inplayMatch.tvUrl
                                    ? inplayMatch.tvUrl
                                    : ""
                                }
                                title=" "
                                loading="lazy"
                                className="w-[100%] h-[300px]"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </div>


              {/* Soccer TEst */}


              {Object.values(otherFinalSocket).map(
                (element, index) =>
                  element.marketType !== "Tied Match" && element.marketType !== "Match Odds" && element.marketType !== "To Win the Toss" && element.marketType !== "Completed Match" && (
                    <div>
                      <div className="rounded-md divide-y divide-black">
                        <header className="bg-[#333333] rounded-t-md z-[1] relative">
                          <div className=" items-center flex relative z-0">
                            <div className="  flex flex-1">
                              <span className="uppercase items-center py-1 px-1 rounded-tl-md bg-button inline-flex text-sm font-medium text-black ">
                                {element && element.marketType
                                  ? element.marketType
                                  : "NA"}
                              </span>
                              <span></span>
                            </div>

                            <div className="bg-[#333333] relative  rounded-tr-md flex flex-wrap justify-center items-center">
                              <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                <span
                                  className="bg-white text-[10px] px-2 py-1 font-extrabold rounded-sm flex justify-center items-center"
                                  onClick={openRuleModal}
                                >
                                  Rules
                                </span>

                                <span
                                  className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                  onClick={() => togglePopover('soccerStake')}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="2"
                                    height="8"
                                    viewBox="0 0 2 8"
                                    fill="none"
                                  >
                                    <path
                                      _ngcontent-lmb-c38=""
                                      d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                      fill="white"
                                    ></path>
                                  </svg>
                                </span>

                                <span
                                  className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                  onClick={() =>
                                    setIsMatchOddsHide(
                                      !isMatchOddsHide
                                    )
                                  }
                                >
                                  {isMatchOddsHide ? (
                                    <FaMinus />
                                  ) : (
                                    <FaPlus />
                                  )}
                                </span>
                              </div>

                              <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.soccerStake && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">50 - 200000</span></p>
                                <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> 2500000</span></p>
                              </div>

                              )}
                              </div>



                            </div>
                          </div>
                          <div className=" items-center flex relative bg-black z-[-1]">
                            <div className="xl:w-2/3 w-1/2 flex ">
                              <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-white">
                                {element.runners.length > 0
                                  ? element.runners.length
                                  : null}{" "}
                                Seclection
                              </div>
                            </div>

                            <div className="xl:w-1/3 w-1/2   divide-y divide-black  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                              <div className="grid grid-cols-6 text-[10px] text-white space-x-0.5 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center lg:px-2 px-1  items-center">
                                <span className="md:block hidden"></span>
                                <span className="md:block hidden"></span>
                                <span className="lg:col-span-1 text-nowrap col-span-2 lg:col-start-1   font-bold text-right px-1">
                                  Back {"{L}"}
                                </span>
                                <span className="col-span-2 text-nowrap  font-bold text-center px-4">
                                  Lay {"{N}"}
                                </span>
                                <span></span>
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </header>
                        {isMatchOddsHide && (
                          <>
                            {element &&
                              element.runners &&
                              element.runners.length > 0
                              ? element.runners.map(
                                (elementtemp, index) => (
                                  <>
                                    <div
                                      className={` bg-[#2E2F2F] shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                    >
                                      <div className="w-1/2 lg:w-1/2  flex px-4">
                                        <div className="w-11/12  leading-3 flex items-center text-white">
                                          <span class="text-sm font-bold">
                                            <span className="text-white text-xs font-bold">
                                              {
                                                elementtemp.selectionName
                                              }{" "}
                                              <br />
                                              <span
                                                key={index}
                                                className={
                                                  positionObj[
                                                    elementtemp
                                                      .selectionId
                                                  ] > 0
                                                    ? "text-green-500"
                                                    : positionObj[
                                                      elementtemp
                                                        .selectionId
                                                    ] < 0
                                                      ? "text-red-500"
                                                      : "text-green-500"
                                                }
                                              >
                                                {/* {returnDataObject[elementtemp.selectionId] !== 0 ? returnDataObject[elementtemp.selectionId] : "-"} */}

                                                {positionObj[
                                                  elementtemp
                                                    .selectionId
                                                ]
                                                  ? (
                                                    Math.floor(
                                                      Number(
                                                        positionObj[
                                                        elementtemp
                                                          .selectionId
                                                        ]
                                                      ) * 100
                                                    ) / 100
                                                  ).toFixed(2)
                                                  : 0}
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="w-1/2 lg:w-1/2 grid grid-cols-6 px-2 space-x-[0.5px]">
                                        {elementtemp &&
                                          elementtemp.ex &&
                                          elementtemp.ex
                                            .availableToBack &&
                                          elementtemp.ex
                                            .availableToBack.length >
                                          0 ? (
                                          <>
                                            {elementtemp.ex.availableToBack
                                              .slice(1)
                                              .map(
                                                (
                                                  tempData,
                                                  index
                                                ) => (
                                                  <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                    <Blinking
                                                      price={
                                                        tempData.price
                                                      }
                                                      size={
                                                        tempData.size
                                                      }
                                                      color={
                                                        "bg-[#4C4C4C]"
                                                      }
                                                      blinkColor={
                                                        "bg-[#CDEBEB]"
                                                      }
                                                      textColors={
                                                        "text-white"
                                                      }
                                                      boderColors={
                                                        "border-black"
                                                      }
                                                    />
                                                  </span>
                                                )
                                              )}
                                          </>
                                        ) : null}

                                        {elementtemp &&
                                          elementtemp.ex &&
                                          elementtemp.ex
                                            .availableToBack &&
                                          elementtemp.ex
                                            .availableToBack.length >
                                          0 ? (
                                          <>
                                            {elementtemp.ex.availableToBack
                                              .slice(0, 1)
                                              .map(
                                                (
                                                  tempData,
                                                  index
                                                ) => (
                                                  <>
                                                    <span
                                                      className="md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 py-1 lg:hidden block"
                                                      onClick={() => {
                                                        handleBackOpen(
                                                          {
                                                            data: tempData,
                                                            type: "Yes",
                                                            odds: tempData.price,
                                                            name: elementtemp.selectionName,
                                                            nameOther:
                                                              element.runners,
                                                            betFor:
                                                              "matchOdds",
                                                            oddsType:
                                                              element.marketType,
                                                            betType:
                                                              "L",
                                                            selectionId:
                                                              elementtemp.selectionId,
                                                            teamData:
                                                              tempData.price,
                                                            betfairMarketId:
                                                              element.marketId,
                                                            price:
                                                              elementtemp
                                                                .ex
                                                                .availableToLay[0]
                                                                .price,
                                                            size: elementtemp
                                                              .ex
                                                              .availableToLay[0]
                                                              .size,
                                                            position:
                                                              returnDataObject,
                                                            newPosition:
                                                              returnDataObject,
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={
                                                          tempData.price
                                                        }
                                                        size={
                                                          tempData.size
                                                        }
                                                        color={
                                                          "bg-[#9ECDDF]"
                                                        }
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    </span>
                                                    <span
                                                      className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                      onClick={() => {
                                                        toggleRowVisibility(
                                                          elementtemp.selectionId
                                                        );
                                                        handleBackOpen(
                                                          {
                                                            data: tempData,
                                                            type: "Yes",
                                                            odds: tempData.price,
                                                            name: elementtemp.selectionName,
                                                            nameOther:
                                                              element.runners,
                                                            betFor:
                                                              "matchOdds",
                                                            oddsType:
                                                              element.marketType,
                                                            betType:
                                                              "L",
                                                            selectionId:
                                                              elementtemp.selectionId,
                                                            teamData:
                                                              tempData.price,
                                                            betfairMarketId:
                                                              element.marketId,
                                                            price:
                                                              elementtemp
                                                                .ex
                                                                .availableToLay[0]
                                                                .price,
                                                            size: elementtemp
                                                              .ex
                                                              .availableToLay[0]
                                                              .size,
                                                            position:
                                                              returnDataObject,
                                                            newPosition:
                                                              returnDataObject,
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={
                                                          tempData.price
                                                        }
                                                        size={
                                                          tempData.size
                                                        }
                                                        color={
                                                          "bg-[#9ECDDF]"
                                                        }
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    </span>
                                                  </>
                                                )
                                              )}
                                          </>
                                        ) : null}

                                        {elementtemp &&
                                          elementtemp.ex &&
                                          elementtemp.ex
                                            .availableToLay &&
                                          elementtemp.ex
                                            .availableToLay.length > 0
                                          ? elementtemp.ex.availableToLay.map(
                                            (tempData, index) => (
                                              <>
                                                {index === 0 ? (
                                                  <>
                                                    <span
                                                      className="md:col-span-2 sm:col-span-2 md:col-start-4 col-span-3 py-1 lg:hidden block"
                                                      onClick={() => {
                                                        toggleRowVisibility(
                                                          elementtemp.selectionId
                                                        );
                                                        handleBackOpen(
                                                          {
                                                            data: tempData,
                                                            type: "No",
                                                            odds: tempData.price,
                                                            name: elementtemp.selectionName,
                                                            nameOther:
                                                              element.runners,
                                                            betFor:
                                                              "matchOdds",
                                                            oddsType:
                                                              element.marketType,
                                                            betType:
                                                              "K",
                                                            selectionId:
                                                              elementtemp.selectionId,
                                                            teamData:
                                                              tempData.price,
                                                            betfairMarketId:
                                                              element.marketId,
                                                            price:
                                                              elementtemp
                                                                .ex
                                                                .availableToBack[0]
                                                                .price,
                                                            size: elementtemp
                                                              .ex
                                                              .availableToBack[0]
                                                              .size,
                                                            position:
                                                              returnDataObject,
                                                            newPosition:
                                                              returnDataObject,
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={
                                                          tempData.price
                                                        }
                                                        size={
                                                          tempData.size
                                                        }
                                                        color={
                                                          "bg-[#FBD7E5]"
                                                        }
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    </span>

                                                    <span
                                                      className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                      onClick={() => {
                                                        toggleRowVisibility(
                                                          elementtemp.selectionId
                                                        );
                                                        handleBackOpen(
                                                          {
                                                            data: tempData,
                                                            type: "No",
                                                            odds: tempData.price,
                                                            name: elementtemp.selectionName,
                                                            nameOther:
                                                              element.runners,
                                                            betFor:
                                                              "matchOdds",
                                                            oddsType:
                                                              element.marketType,
                                                            betType:
                                                              "K",
                                                            selectionId:
                                                              elementtemp.selectionId,
                                                            teamData:
                                                              tempData.price,
                                                            betfairMarketId:
                                                              element.marketId,
                                                            price:
                                                              elementtemp
                                                                .ex
                                                                .availableToBack[0]
                                                                .price,
                                                            size: elementtemp
                                                              .ex
                                                              .availableToBack[0]
                                                              .size,
                                                            position:
                                                              returnDataObject,
                                                            newPosition:
                                                              returnDataObject,
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={
                                                          tempData.price
                                                        }
                                                        size={
                                                          tempData.size
                                                        }
                                                        color={
                                                          "bg-[#FBD7E5]"
                                                        }
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                      <Blinking
                                                        price={
                                                          tempData.price
                                                        }
                                                        size={
                                                          tempData.size
                                                        }
                                                        color={
                                                          "bg-[#4C4C4C]"
                                                        }
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-white"
                                                        }
                                                        boderColors={
                                                          "border-black"
                                                        }
                                                      />
                                                    </span>
                                                  </>
                                                )}
                                              </>
                                            )
                                          )
                                          : null}
                                      </div>
                                    </div>

                                    {hiddenRows.includes(
                                      elementtemp.selectionId
                                    ) && (
                                        <BetPlaceMobile
                                          setStakeModal={
                                            setStakeModal
                                          }
                                          closeRow={closeRow}
                                          closeSec={
                                            elementtemp.selectionId
                                          }
                                          matchName={
                                            inplayMatch?.matchName
                                          }
                                          openBets={openBets}
                                          betSlipData={betSlipData}
                                          placeBet={placeBet}
                                          count={betSlipData.count}
                                          betLoading={betLoading}
                                          increaseCount={
                                            increaseCount
                                          }
                                          decreaseCount={
                                            decreaseCount
                                          }
                                        />
                                      )}
                                  </>
                                )
                              )
                              : null}
                          </>
                        )}
                      </div>
                    </div>
                  )
              )}







              {/* end  */}























              {betShowMobile ? (
                <OpenBetsMobile marketId={marketId} />
              ) : (
                <div className="bg-black h-auto mb-2 overflow-y-auto">
                  <div className="space-y-4">
                    {inplayMatch?.isMatchOdds &&
                      (activeTab === "matchOdds" || activeTab === "all") ? (
                      <>
                        {Object.values(finalSocket).map(
                          (element, index) =>
                            element.marketType === "Match Odds" && (
                              <div>
                                <div className="rounded-md divide-y divide-black">
                                  <header className="bg-[#333333] rounded-t-md z-[1] relative">
                                    <div className=" items-center flex relative z-0">
                                      <div className="  flex flex-1">
                                        <span className="uppercase items-center py-1 px-1 rounded-tl-md bg-button inline-flex text-sm font-medium text-black ">
                                          {element && element.marketType
                                            ? element.marketType
                                            : "NA"}
                                        </span>
                                        <span></span>
                                      </div>

                                      <div className="bg-[#333333] relative  rounded-tr-md flex flex-wrap justify-center items-center">
                                        <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                          <span
                                            className="bg-white text-[10px] px-2 py-1 font-extrabold rounded-sm flex justify-center items-center"
                                            onClick={openRuleModal}
                                          >
                                            Rules
                                          </span>

                                          <span
                                            className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                            onClick={() => togglePopover('matchOdds')}
                                          >
                                            <div className="relatives">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="2"
                                                height="8"
                                                viewBox="0 0 2 8"
                                                fill="none"
                                              >
                                                <path
                                                  _ngcontent-lmb-c38=""
                                                  d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                  fill="white"
                                                ></path>
                                              </svg>
                                            </div>
                                          </span>

                                          <span
                                            className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                            onClick={() =>
                                              setIsMatchOddsHide(
                                                !isMatchOddsHide
                                              )
                                            }
                                          >
                                            {isMatchOddsHide ? (
                                              <FaMinus />
                                            ) : (
                                              <FaPlus />
                                            )}
                                          </span>
                                        </div>

                                        <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.matchOdds && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">{isMatchCoin?.min} - {isMatchCoin?.max}</span></p>
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> {isMatchCoin?.max}</span></p>
                                        </div>

                                        )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" items-center flex relative bg-black z-[-1]">
                                      <div className="xl:w-2/3 w-1/2 flex ">
                                        <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-white">
                                          {element.runners.length > 0
                                            ? element.runners.length
                                            : null}{" "}
                                          Seclection
                                        </div>
                                      </div>

                                      <div className="xl:w-1/3 w-1/2   divide-y divide-black  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                        <div className="grid grid-cols-6 text-[10px] text-white space-x-0.5 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center lg:px-2 px-1  items-center">
                                          <span className="md:block hidden"></span>
                                          <span className="md:block hidden"></span>
                                          <span className="lg:col-span-1 text-nowrap md:col-span-2 col-span-3 lg:col-start-1   font-bold md:text-right text-center px-1">
                                            Back {"{L}"}
                                          </span>
                                          <span className="col-span-2 text-nowrap  font-bold text-center px-4">
                                            Lay {"{N}"}
                                          </span>
                                          <span></span>
                                          <span></span>
                                        </div>
                                      </div>
                                    </div>
                                  </header>
                                  {isMatchOddsHide && (
                                    <>
                                      {element &&
                                        element.runners &&
                                        element.runners.length > 0
                                        ? element.runners.map(
                                          (elementtemp, index) => (
                                            <>
                                              <div
                                                className={` bg-[#2E2F2F] shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                              >
                                                <div className="w-1/2 lg:w-1/2  flex px-4">
                                                  <div className="w-11/12  leading-3 flex items-center text-slate-700">
                                                    <span class="text-sm font-bold">
                                                      <span className="text-white text-xs font-bold">
                                                        {
                                                          elementtemp.selectionName
                                                        }{" "}
                                                        <br />
                                                        <span
                                                          key={index}
                                                          className={
                                                            positionObj[
                                                              elementtemp
                                                                .selectionId
                                                            ] > 0
                                                              ? "text-green-500"
                                                              : positionObj[
                                                                elementtemp
                                                                  .selectionId
                                                              ] < 0
                                                                ? "text-red-500"
                                                                : "text-green-500"
                                                          }
                                                        >
                                                          {/* {returnDataObject[elementtemp.selectionId] !== 0 ? returnDataObject[elementtemp.selectionId] : "-"} */}

                                                          {positionObj[
                                                            elementtemp
                                                              .selectionId
                                                          ]
                                                            ? (
                                                              Math.floor(
                                                                Number(
                                                                  positionObj[
                                                                  elementtemp
                                                                    .selectionId
                                                                  ]
                                                                ) * 100
                                                              ) / 100
                                                            ).toFixed(2)
                                                            : 0}
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="w-1/2 lg:w-1/2 grid grid-cols-6 px-2 space-x-[0.5px]">
                                                  {elementtemp &&
                                                    elementtemp.ex &&
                                                    elementtemp.ex
                                                      .availableToBack &&
                                                    elementtemp.ex
                                                      .availableToBack.length >
                                                    0 ? (
                                                    <>
                                                      {elementtemp.ex.availableToBack
                                                        .slice(1)
                                                        .map(
                                                          (
                                                            tempData,
                                                            index
                                                          ) => (
                                                            <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                              <Blinking
                                                                price={
                                                                  tempData.price
                                                                }
                                                                size={
                                                                  tempData.size
                                                                }
                                                                color={
                                                                  "bg-[#4C4C4C]"
                                                                }
                                                                blinkColor={
                                                                  "bg-[#CDEBEB]"
                                                                }
                                                                textColors={
                                                                  "text-white"
                                                                }
                                                                boderColors={
                                                                  "border-black"
                                                                }
                                                              />
                                                            </span>
                                                          )
                                                        )}
                                                    </>
                                                  ) : null}

                                                  {elementtemp &&
                                                    elementtemp.ex &&
                                                    elementtemp.ex
                                                      .availableToBack &&
                                                    elementtemp.ex
                                                      .availableToBack.length >
                                                    0 ? (
                                                    <>
                                                      {elementtemp.ex.availableToBack
                                                        .slice(0, 1)
                                                        .map(
                                                          (
                                                            tempData,
                                                            index
                                                          ) => (
                                                            <>
                                                              <span
                                                                className="md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 py-1 lg:hidden block"
                                                                onClick={() => {
                                                                  handleBackOpen(
                                                                    {
                                                                      data: tempData,
                                                                      type: "Yes",
                                                                      odds: tempData.price,
                                                                      name: elementtemp.selectionName,
                                                                      nameOther:
                                                                        element.runners,
                                                                      betFor:
                                                                        "matchOdds",
                                                                      oddsType:
                                                                        element.marketType,
                                                                      betType:
                                                                        "L",
                                                                      selectionId:
                                                                        elementtemp.selectionId,
                                                                      teamData:
                                                                        tempData.price,
                                                                      betfairMarketId:
                                                                        element.marketId,
                                                                      price:
                                                                        elementtemp
                                                                          .ex
                                                                          .availableToLay[0]
                                                                          .price,
                                                                      size: elementtemp
                                                                        .ex
                                                                        .availableToLay[0]
                                                                        .size,
                                                                      position:
                                                                        returnDataObject,
                                                                      newPosition:
                                                                        returnDataObject,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <Blinking
                                                                  price={
                                                                    tempData.price
                                                                  }
                                                                  size={
                                                                    tempData.size
                                                                  }
                                                                  color={
                                                                    "bg-[#9ECDDF]"
                                                                  }
                                                                  blinkColor={
                                                                    "bg-[#CDEBEB]"
                                                                  }
                                                                  textColors={
                                                                    "text-black"
                                                                  }
                                                                  boderColors={
                                                                    "border-[#489bbd]"
                                                                  }
                                                                />
                                                              </span>
                                                              <span
                                                                className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                                onClick={() => {
                                                                  toggleRowVisibility(
                                                                    elementtemp.selectionId
                                                                  );
                                                                  handleBackOpen(
                                                                    {
                                                                      data: tempData,
                                                                      type: "Yes",
                                                                      odds: tempData.price,
                                                                      name: elementtemp.selectionName,
                                                                      nameOther:
                                                                        element.runners,
                                                                      betFor:
                                                                        "matchOdds",
                                                                      oddsType:
                                                                        element.marketType,
                                                                      betType:
                                                                        "L",
                                                                      selectionId:
                                                                        elementtemp.selectionId,
                                                                      teamData:
                                                                        tempData.price,
                                                                      betfairMarketId:
                                                                        element.marketId,
                                                                      price:
                                                                        elementtemp
                                                                          .ex
                                                                          .availableToLay[0]
                                                                          .price,
                                                                      size: elementtemp
                                                                        .ex
                                                                        .availableToLay[0]
                                                                        .size,
                                                                      position:
                                                                        returnDataObject,
                                                                      newPosition:
                                                                        returnDataObject,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <Blinking
                                                                  price={
                                                                    tempData.price
                                                                  }
                                                                  size={
                                                                    tempData.size
                                                                  }
                                                                  color={
                                                                    "bg-[#9ECDDF]"
                                                                  }
                                                                  blinkColor={
                                                                    "bg-[#CDEBEB]"
                                                                  }
                                                                  textColors={
                                                                    "text-black"
                                                                  }
                                                                  boderColors={
                                                                    "border-[#489bbd]"
                                                                  }
                                                                />
                                                              </span>
                                                            </>
                                                          )
                                                        )}
                                                    </>
                                                  ) : null}

                                                  {elementtemp &&
                                                    elementtemp.ex &&
                                                    elementtemp.ex
                                                      .availableToLay &&
                                                    elementtemp.ex
                                                      .availableToLay.length > 0
                                                    ? elementtemp.ex.availableToLay.map(
                                                      (tempData, index) => (
                                                        <>
                                                          {index === 0 ? (
                                                            <>
                                                              <span
                                                                className="md:col-span-2 sm:col-span-2 md:col-start-4 col-span-3 py-1 lg:hidden block"
                                                                onClick={() => {
                                                                  toggleRowVisibility(
                                                                    elementtemp.selectionId
                                                                  );
                                                                  handleBackOpen(
                                                                    {
                                                                      data: tempData,
                                                                      type: "No",
                                                                      odds: tempData.price,
                                                                      name: elementtemp.selectionName,
                                                                      nameOther:
                                                                        element.runners,
                                                                      betFor:
                                                                        "matchOdds",
                                                                      oddsType:
                                                                        element.marketType,
                                                                      betType:
                                                                        "K",
                                                                      selectionId:
                                                                        elementtemp.selectionId,
                                                                      teamData:
                                                                        tempData.price,
                                                                      betfairMarketId:
                                                                        element.marketId,
                                                                      price:
                                                                        elementtemp
                                                                          .ex
                                                                          .availableToBack[0]
                                                                          .price,
                                                                      size: elementtemp
                                                                        .ex
                                                                        .availableToBack[0]
                                                                        .size,
                                                                      position:
                                                                        returnDataObject,
                                                                      newPosition:
                                                                        returnDataObject,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <Blinking
                                                                  price={
                                                                    tempData.price
                                                                  }
                                                                  size={
                                                                    tempData.size
                                                                  }
                                                                  color={
                                                                    "bg-[#FBD7E5]"
                                                                  }
                                                                  blinkColor={
                                                                    "bg-[#CDEBEB]"
                                                                  }
                                                                  textColors={
                                                                    "text-black"
                                                                  }
                                                                  boderColors={
                                                                    "border-[#f996ab]"
                                                                  }
                                                                />
                                                              </span>

                                                              <span
                                                                className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                                onClick={() => {
                                                                  toggleRowVisibility(
                                                                    elementtemp.selectionId
                                                                  );
                                                                  handleBackOpen(
                                                                    {
                                                                      data: tempData,
                                                                      type: "No",
                                                                      odds: tempData.price,
                                                                      name: elementtemp.selectionName,
                                                                      nameOther:
                                                                        element.runners,
                                                                      betFor:
                                                                        "matchOdds",
                                                                      oddsType:
                                                                        element.marketType,
                                                                      betType:
                                                                        "K",
                                                                      selectionId:
                                                                        elementtemp.selectionId,
                                                                      teamData:
                                                                        tempData.price,
                                                                      betfairMarketId:
                                                                        element.marketId,
                                                                      price:
                                                                        elementtemp
                                                                          .ex
                                                                          .availableToBack[0]
                                                                          .price,
                                                                      size: elementtemp
                                                                        .ex
                                                                        .availableToBack[0]
                                                                        .size,
                                                                      position:
                                                                        returnDataObject,
                                                                      newPosition:
                                                                        returnDataObject,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <Blinking
                                                                  price={
                                                                    tempData.price
                                                                  }
                                                                  size={
                                                                    tempData.size
                                                                  }
                                                                  color={
                                                                    "bg-[#FBD7E5]"
                                                                  }
                                                                  blinkColor={
                                                                    "bg-[#CDEBEB]"
                                                                  }
                                                                  textColors={
                                                                    "text-black"
                                                                  }
                                                                  boderColors={
                                                                    "border-[#f996ab]"
                                                                  }
                                                                />
                                                              </span>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                                <Blinking
                                                                  price={
                                                                    tempData.price
                                                                  }
                                                                  size={
                                                                    tempData.size
                                                                  }
                                                                  color={
                                                                    "bg-[#4C4C4C]"
                                                                  }
                                                                  blinkColor={
                                                                    "bg-[#CDEBEB]"
                                                                  }
                                                                  textColors={
                                                                    "text-white"
                                                                  }
                                                                  boderColors={
                                                                    "border-black"
                                                                  }
                                                                />
                                                              </span>
                                                            </>
                                                          )}
                                                        </>
                                                      )
                                                    )
                                                    : null}
                                                </div>
                                              </div>

                                              {hiddenRows.includes(
                                                elementtemp.selectionId
                                              ) && (
                                                  <BetPlaceMobile
                                                    setStakeModal={
                                                      setStakeModal
                                                    }
                                                    closeRow={closeRow}
                                                    closeSec={
                                                      elementtemp.selectionId
                                                    }
                                                    matchName={
                                                      inplayMatch?.matchName
                                                    }
                                                    openBets={openBets}
                                                    betSlipData={betSlipData}
                                                    placeBet={placeBet}
                                                    count={betSlipData.count}
                                                    betLoading={betLoading}
                                                    increaseCount={
                                                      increaseCount
                                                    }
                                                    decreaseCount={
                                                      decreaseCount
                                                    }
                                                  />
                                                )}
                                            </>
                                          )
                                        )
                                        : null}
                                    </>
                                  )}
                                </div>
                              </div>
                            )
                        )}
                      </>
                    ) : null}

                    {inplayMatch?.isBookmaker &&
                      (activeTab === "matchOdds" || activeTab === "all") ? (
                      <>
                        {isBookmekar && (
                          <>
                            <div>
                              {matchScoreDetails &&
                                matchScoreDetails.team_data &&
                                matchScoreDetails.team_data.length > 0 ? (
                                <>
                                  <header className="bg-[#333333] rounded-t-md z-[1] relative">
                                    <div className=" items-center flex relative z-0">
                                      <div className=" flex flex-1">
                                        <div className=" rounded-tl-md py-1.5 text-nowrap  bg-button  uppercase px-2 items-center inline-flex text-sm font-medium text-black">
                                          Book Maker
                                        </div>
                                        <div className="w-1/2 px-2 flex items-center">
                                          {/* <img src={fastimg} alt="textd" className="h-5 w-5" /> */}
                                        </div>
                                      </div>

                                      <div className="  bg-[#333333]  rounded-tr-md flex flex-wrap justify-center items-center relative">
                                        <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                          <span
                                            className="bg-white text-[10px] px-2 py-1 font-extrabold rounded-sm flex justify-center items-center"
                                            onClick={openRuleModal}
                                          >
                                            Rules
                                          </span>

                                          <span className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                            onClick={() => togglePopover("bookmakerStake")}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="2"
                                              height="8"
                                              viewBox="0 0 2 8"
                                              fill="none"
                                            >
                                              <path
                                                _ngcontent-lmb-c38=""
                                                d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                fill="white"
                                              ></path>
                                            </svg>
                                          </span>
                                          <span
                                            className="text-[10px] rounded font-semibold  px-2 py-0.5 border border-red-600 text-red-600"
                                            onClick={() =>
                                              setIsBookmaker(false)
                                            }
                                          >
                                            &#10005;
                                          </span>
                                          <span
                                            className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                            onClick={() =>
                                              setIsBookmakerHide(
                                                !isBookmekarHide
                                              )
                                            }
                                          >
                                            {isBookmekarHide ? (
                                              <FaMinus />
                                            ) : (
                                              <FaPlus />
                                            )}
                                          </span>
                                        </div>
                                        <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.bookmakerStake && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">{minMaxCoins?.min} - {minMaxCoins?.max}</span></p>
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> {minMaxCoins?.max}</span></p>
                                        </div>

                                        )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" items-center flex relative bg-black z-[-1]">
                                      <div className="xl:w-2/3 w-1/2 flex ">
                                        <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-white">
                                          {matchScoreDetails.team_data.length > 0
                                            ? matchScoreDetails.team_data.length
                                            : null}{" "}
                                          Seclection
                                        </div>
                                      </div>

                                      <div className="xl:w-1/3 w-1/2   divide-y divide-black  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                        <div className="grid grid-cols-6 text-[10px] text-white space-x-0.5 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center lg:px-2 px-1  items-center">
                                          <span className="md:block hidden"></span>
                                          <span className="md:block hidden"></span>
                                          <span className="lg:col-span-1 text-nowrap md:col-span-2 col-span-3 lg:col-start-1   font-bold md:text-right text-center px-1">
                                            Back {"{L}"}
                                          </span>
                                          <span className="col-span-2 text-nowrap  font-bold text-center px-4">
                                            Lay {"{N}"}
                                          </span>
                                          <span></span>
                                          <span></span>
                                        </div>
                                      </div>
                                    </div>
                                  </header>

                                  {isBookmekarHide && (
                                    <>
                                      {matchScoreDetails &&
                                        matchScoreDetails.team_data &&
                                        matchScoreDetails.team_data.length > 0
                                        ? matchScoreDetails.team_data.map(
                                          (commList, index) => (
                                            <>
                                              <div
                                                key={index}
                                                className={` bg-[#2E2F2F] relative px-2 shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                              >
                                                <div className="w-1/2 lg:w-1/2 flex px-4">
                                                  <div className="w-full   leading-3 flex items-center text-slate-700">
                                                    <span class="text-xs font-bold">
                                                      <span className="text-white">
                                                        {commList.team_name}
                                                      </span>
                                                      <br />
                                                      <span
                                                        key={index}
                                                        className={
                                                          positionObj[
                                                            commList
                                                              ?.selectionid
                                                          ] > 0
                                                            ? "text-green-600"
                                                            : positionObj[
                                                              commList
                                                                ?.selectionid
                                                            ] < 0
                                                              ? "text-red-600"
                                                              : "text-green-500"
                                                        }
                                                      >
                                                        {/* {returnDataObject[commList.selectionid] !== 0 ? returnDataObject[commList.selectionid] : "-"} */}
                                                        {positionObj[
                                                          commList
                                                            ?.selectionid
                                                        ]
                                                          ? (
                                                            Math.floor(
                                                              Number(
                                                                positionObj[
                                                                commList
                                                                  ?.selectionid
                                                                ]
                                                              ) * 100
                                                            ) / 100
                                                          ).toFixed(2)
                                                          : 0}
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <>
                                                  <div className="w-1/2 lg:w-1/2 grid grid-cols-6 space-x-[0.5px] py-0.5">
                                                    <span className="lg:block hidden ">
                                                      <Blinking
                                                        price={0}
                                                        size={0}
                                                        // color={"bg-[#98C8EA]"}
                                                        // blinkColor={"bg-[#CDEBEB]"}
                                                        color={"bg-[#4C4C4C]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-white"
                                                        }
                                                        boderColors={
                                                          "border-black"
                                                        }
                                                      />
                                                    </span>
                                                    <span className="lg:block hidden ">
                                                      <Blinking
                                                        price={0}
                                                        size={0}
                                                        color={"bg-[#4C4C4C]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-white"
                                                        }
                                                        boderColors={
                                                          "border-black"
                                                        }
                                                      />
                                                    </span>
                                                    <span
                                                      className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                                      onClick={() => {
                                                        toggleRowVisibility(
                                                          commList.selectionid
                                                        );
                                                        handleBackOpen({
                                                          data: commList,
                                                          nameOther:
                                                            matchScoreDetails.team_data,
                                                          type: "Yes",
                                                          odds: commList.lgaai,
                                                          name: commList.team_name,
                                                          betFor: "odds",
                                                          oddsType:
                                                            "bookmaker",
                                                          betType: "L",
                                                          selectionId:
                                                            commList.selectionid,
                                                          teamData:
                                                            commList.lgaai,
                                                          betfairMarketId:
                                                            marketId,
                                                          price:
                                                            commList.khaai *
                                                            100,
                                                          size: "0",
                                                          position:
                                                            returnDataObject,
                                                          newPosition:
                                                            returnDataObject,
                                                        });
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={commList.lgaai}
                                                        size={commList.khaai}
                                                        color={"bg-[#9ECDDF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    </span>

                                                    <span
                                                      className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "
                                                      onClick={() => {
                                                        toggleRowVisibility(
                                                          commList.selectionid
                                                        );
                                                        handleBackOpen({
                                                          data: commList,
                                                          nameOther:
                                                            matchScoreDetails.team_data,
                                                          type: "Yes",
                                                          odds: commList.lgaai,
                                                          name: commList.team_name,
                                                          betFor: "odds",
                                                          oddsType:
                                                            "bookmaker",
                                                          betType: "L",
                                                          selectionId:
                                                            commList.selectionid,
                                                          teamData:
                                                            commList.lgaai,
                                                          betfairMarketId:
                                                            marketId,
                                                          price:
                                                            commList.khaai *
                                                            100,
                                                          size: "0",
                                                          position:
                                                            returnDataObject,
                                                          newPosition:
                                                            returnDataObject,
                                                        });
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={commList.lgaai}
                                                        size={commList.khaai}
                                                        color={"bg-[#9ECDDF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    </span>

                                                    <span
                                                      className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden"
                                                      onClick={() => {
                                                        toggleRowVisibility(
                                                          commList.selectionid
                                                        );
                                                        handleBackOpen({
                                                          data: commList,
                                                          nameOther:
                                                            matchScoreDetails.team_data,
                                                          type: "No",
                                                          odds: commList.khaai,
                                                          name: commList.team_name,
                                                          betFor: "odds",
                                                          oddsType:
                                                            "bookmaker",
                                                          betType: "K",
                                                          selectionId:
                                                            commList.selectionid,
                                                          teamData:
                                                            commList.khaai,
                                                          betfairMarketId:
                                                            marketId,
                                                          price:
                                                            commList.lgaai *
                                                            100,
                                                          size: "0",
                                                          position:
                                                            returnDataObject,
                                                          newPosition:
                                                            returnDataObject,
                                                        });
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={commList.khaai}
                                                        size={commList.lgaai}
                                                        color={"bg-[#FBD7E5]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    </span>
                                                    <span className="lg:block hidden ">
                                                      <Blinking
                                                        price={0}
                                                        size={0}
                                                        color={"bg-[#4C4C4C]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-white"
                                                        }
                                                        boderColors={
                                                          "border-black"
                                                        }
                                                      />
                                                    </span>
                                                    <span className="lg:block hidden ">
                                                      <Blinking
                                                        price={0}
                                                        size={0}
                                                        color={"bg-[#4C4C4C]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-white"
                                                        }
                                                        boderColors={
                                                          "border-black"
                                                        }
                                                      />
                                                    </span>

                                                    <span
                                                      className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block"
                                                      onClick={() => {
                                                        toggleRowVisibility(
                                                          commList.selectionid
                                                        );
                                                        handleBackOpen({
                                                          data: commList,
                                                          nameOther:
                                                            matchScoreDetails.team_data,
                                                          type: "No",
                                                          odds: commList.khaai,
                                                          name: commList.team_name,
                                                          betFor: "odds",
                                                          oddsType:
                                                            "bookmaker",
                                                          betType: "K",
                                                          selectionId:
                                                            commList.selectionid,
                                                          teamData:
                                                            commList.khaai,
                                                          betfairMarketId:
                                                            marketId,
                                                          price:
                                                            commList.lgaai *
                                                            100,
                                                          size: "0",
                                                          position:
                                                            returnDataObject,
                                                          newPosition:
                                                            returnDataObject,
                                                        });
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={commList.khaai}
                                                        size={commList.lgaai}
                                                        color={"bg-[#FBD7E5]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    </span>
                                                    <span className=" lg:block hidden"></span>
                                                  </div>
                                                  {commList.lgaai ===
                                                    "0.00" ? (
                                                    <div className="w-1/2 border-[#E4E4E4] right-0 h-full  absolute bg-[#E4E4E4] opacity-90 border-2 rounded px-1   flex justify-center items-center">
                                                      {/* <div className="relative bg-gray-600 border-gray-900 col-span-6 my-0.5 "></div> */}
                                                      <div className="2xl:px-8 lg:px-8 px-2 text-nowrap text-sm font-bold opacity-100 text-gray-900 ">
                                                        SUSPENDED
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </>
                                              </div>
                                              {index <
                                                matchScoreDetails.team_data
                                                  .length -
                                                1 && <hr />}
                                              <>
                                                {hiddenRows.includes(
                                                  commList.selectionid
                                                ) && (
                                                    <BetPlaceMobile
                                                      setStakeModal={
                                                        setStakeModal
                                                      }
                                                      closeRow={closeRow}
                                                      closeSec={
                                                        commList.selectionid
                                                      }
                                                      matchName={
                                                        inplayMatch?.matchName
                                                      }
                                                      openBets={openBets}
                                                      betSlipData={betSlipData}
                                                      placeBet={placeBet}
                                                      count={betSlipData.count}
                                                      betLoading={betLoading}
                                                      increaseCount={
                                                        increaseCount
                                                      }
                                                      decreaseCount={
                                                        decreaseCount
                                                      }
                                                    />
                                                  )}
                                              </>
                                            </>
                                          )
                                        )
                                        : null}
                                    </>
                                  )}
                                </>
                              ) : null}
                            </div>{" "}
                          </>
                        )}
                      </>
                    ) : null}

                    {inplayMatch?.isToss &&
                      (activeTab === "matchOdds" || activeTab === "all") ? (
                      <>
                        {isToss && (
                          <>
                            <div>
                              {matchScoreDetails &&
                                matchScoreDetails.toss_data &&
                                matchScoreDetails.toss_data.length > 0 ? (
                                <>
                                  <header className="bg-[#333333] rounded-t-md">
                                    <div className=" items-center flex relative z-0">
                                      <div className=" flex flex-1 ">
                                        <div className=" rounded-tl-md py-1.5 bg-button  uppercase px-2 items-center inline-flex text-sm font-medium text-black">
                                          To Win The Toss
                                        </div>
                                        <div className="md:w-1/2 px-2 flex items-center">
                                          {/* <img src={fastimg} alt="textd" className="h-5 w-5" /> */}
                                        </div>
                                      </div>

                                      <div className="  bg-[#333333] relative  rounded-tr-md flex flex-wrap justify-center items-center">
                                        <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                          <span
                                            className="bg-white text-[10px] px-2 py-1 font-extrabold rounded-sm flex justify-center items-center"
                                            onClick={openRuleModal}
                                          >
                                            Rules
                                          </span>
                                          <span className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                           onClick={() => togglePopover("tossStake")}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="2"
                                              height="8"
                                              viewBox="0 0 2 8"
                                              fill="none"
                                            >
                                              <path
                                                _ngcontent-lmb-c38=""
                                                d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                fill="white"
                                              ></path>
                                            </svg>
                                          </span>
                                          <span
                                            className="text-[10px] rounded font-semibold  px-2 py-0.5 border border-red-600 text-red-600"
                                            onClick={() => setIsToss(false)}
                                          >
                                            &#10005;
                                          </span>
                                          <span
                                            className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                            onClick={() =>
                                              setIsTossHide(!isTossHide)
                                            }
                                          >
                                            {isTossHide ? (
                                              <FaMinus />
                                            ) : (
                                              <FaPlus />
                                            )}
                                          </span>
                                        </div>
                                        <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.tossStake && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">{isTossCoin?.min} - {isTossCoin?.max}</span></p>
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> {isTossCoin?.max}</span></p>
                                        </div>

                                        )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className=" items-center flex relative bg-black z-[-1]">
                                      <div className="xl:w-2/3 w-1/2 flex ">
                                        <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-white">
                                          {matchScoreDetails.toss_data.length >
                                            0
                                            ? matchScoreDetails.toss_data.length
                                            : null}{" "}
                                          Seclection
                                        </div>
                                        <div className="w-1/2 px-2 flex items-center">
                                          {/* <img src={fastimg} alt="textd" className="h-5 w-5" /> */}
                                        </div>
                                      </div>

                                      <div className="xl:w-1/3 w-1/2   divide-y divide-black  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                        <div className="grid grid-cols-6 text-[10px] text-white space-x-0.5 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center lg:px-2 px-1  items-center">
                                          <span className="md:block hidden"></span>
                                          <span className="md:block hidden"></span>
                                          <span className="lg:col-span-1 text-nowrap md:col-span-2 col-span-3 lg:col-start-1   font-bold md:text-right text-center px-1">
                                            Back {"{L}"}
                                          </span>
                                          <span className="col-span-2 text-nowrap  font-bold text-center px-4">
                                            Lay {"{N}"}
                                          </span>
                                          <span></span>
                                          <span></span>
                                        </div>
                                      </div>
                                    </div>
                                  </header>

                                  {isTossHide && (
                                    <>
                                      {matchScoreDetails &&
                                        matchScoreDetails.toss_data &&
                                        matchScoreDetails.toss_data.length > 0
                                        ? matchScoreDetails.toss_data.map(
                                          (commList, index) => (
                                            <>
                                              <div
                                                key={index}
                                                className={` bg-[#2E2F2F] px-2 relative shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                              >
                                                <div className="w-1/2 lg:w-1/2 flex px-4">
                                                  <div className="w-full leading-3 flex items-center text-white">
                                                    <span class="text-sm font-bold">
                                                      <span>
                                                        {commList.team_name}
                                                      </span>
                                                      <br />
                                                      <span
                                                        key={index}
                                                        className={
                                                          positionObj[
                                                            commList
                                                              ?.selectionId
                                                          ] > 0
                                                            ? "text-green-500"
                                                            : positionObj[
                                                              commList
                                                                ?.selectionId
                                                            ] < 0
                                                              ? "text-red-500"
                                                              : "text-green-500"
                                                        }
                                                      >
                                                        {/* {returnDataObject[commList.selectionid] !== 0 ? returnDataObject[commList.selectionid] : "-"} */}
                                                        {positionObj[
                                                          commList
                                                            ?.selectionId
                                                        ]
                                                          ? (
                                                            Math.floor(
                                                              Number(
                                                                positionObj[
                                                                commList
                                                                  ?.selectionId
                                                                ]
                                                              ) * 100
                                                            ) / 100
                                                          ).toFixed(2)
                                                          : 0}
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <>
                                                  <div className="w-1/2 lg:w-1/2 grid grid-cols-6 space-x-0.5 py-0.5">
                                                    <span className=" lg:block hidden "></span>
                                                    <span className=" lg:block hidden "></span>
                                                    <span
                                                      className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                                      onClick={() =>
                                                        handleBackOpen({
                                                          data: commList,
                                                          type: "Yes",
                                                          odds: commList.lgaai,
                                                          name: commList.team_name,
                                                          betFor: "toss",
                                                          oddsType: "toss",
                                                          betType: "L",
                                                          selectionId:
                                                            commList.selectionid,
                                                          betfairMarketId:
                                                            marketId,
                                                          price:
                                                            commList.khaai *
                                                            100,
                                                          size: "100",
                                                          position:
                                                            returnDataObject,
                                                          newPosition:
                                                            returnDataObject,
                                                          nameOther:
                                                            matchScoreDetails.toss_data,
                                                        })
                                                      }
                                                    >
                                                      <Blinking
                                                        price={parseFloat(
                                                          commList.lgaai * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(
                                                            /\.?0+$/,
                                                            ""
                                                          )}
                                                        size={100}
                                                        color={"bg-[#9ECDDF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    </span>

                                                    <span
                                                      className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block"
                                                      onClick={() => {
                                                        toggleRowVisibility(
                                                          commList.selectionid
                                                        );
                                                        handleBackOpen({
                                                          data: commList,
                                                          type: "Yes",
                                                          odds: commList.lgaai,
                                                          name: commList.team_name,
                                                          betFor: "toss",
                                                          oddsType: "toss",
                                                          betType: "L",
                                                          selectionId:
                                                            commList.selectionid,
                                                          betfairMarketId:
                                                            marketId,
                                                          price:
                                                            commList.khaai *
                                                            100,
                                                          size: "100",
                                                          position:
                                                            returnDataObject,
                                                          newPosition:
                                                            returnDataObject,
                                                          nameOther:
                                                            matchScoreDetails.toss_data,
                                                        });
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={parseFloat(
                                                          commList.lgaai * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(
                                                            /\.?0+$/,
                                                            ""
                                                          )}
                                                        size={100}
                                                        color={"bg-[#9ECDDF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    </span>
                                                    <span
                                                      className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden"
                                                      onClick={() =>
                                                        handleBackOpen({
                                                          data: commList,
                                                          type: "No",
                                                          odds: commList.khaai,
                                                          name: commList.team_name,
                                                          betFor: "toss",
                                                          oddsType: "toss",
                                                          betType: "K",
                                                          selectionId:
                                                            commList.selectionid,
                                                          betfairMarketId:
                                                            marketId,
                                                          price:
                                                            commList.lgaai *
                                                            100,
                                                          size: "100",
                                                          position:
                                                            returnDataObject,
                                                          newPosition:
                                                            returnDataObject,
                                                          nameOther:
                                                            matchScoreDetails.toss_data,
                                                        })
                                                      }
                                                    >
                                                      <Blinking
                                                        price={parseFloat(
                                                          commList.khaai * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(
                                                            /\.?0+$/,
                                                            ""
                                                          )}
                                                        size={100}
                                                        color={"bg-[#FBD7E5]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    </span>

                                                    <span
                                                      className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block"
                                                      onClick={() => {
                                                        toggleRowVisibility(
                                                          commList.selectionid
                                                        );
                                                        handleBackOpen({
                                                          data: commList,
                                                          type: "No",
                                                          odds: commList.khaai,
                                                          name: commList.team_name,
                                                          betFor: "toss",
                                                          oddsType: "toss",
                                                          betType: "K",
                                                          selectionId:
                                                            commList.selectionid,
                                                          betfairMarketId:
                                                            marketId,
                                                          price:
                                                            commList.lgaai *
                                                            100,
                                                          size: "100",
                                                          position:
                                                            returnDataObject,
                                                          newPosition:
                                                            returnDataObject,
                                                          nameOther:
                                                            matchScoreDetails.toss_data,
                                                        });
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={parseFloat(
                                                          commList.khaai * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(
                                                            /\.?0+$/,
                                                            ""
                                                          )}
                                                        size={100}
                                                        color={"bg-[#FBD7E5]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    </span>
                                                    <span className=" lg:block hidden"></span>
                                                  </div>
                                                  {commList.lgaai ===
                                                    "0.00" ? (
                                                    <div className="w-1/2 px-0.5 right-0 h-full  absolute bg-[#E4E4E4] opacity-80 border-gray-900  flex justify-center items-center">
                                                      {/* <div className="relative bg-gray-600 border-gray-900 col-span-6 my-0.5 "></div> */}
                                                      <div className="2xl:px-8 lg:px-8 px-2 text-nowrap text-sm font-bold opacity-100 text-gray-900 ">
                                                        SUSPENDED
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </>
                                              </div>
                                              {index <
                                                matchScoreDetails?.session
                                                  ?.length -
                                                1 && <hr />}
                                              <>
                                                {hiddenRows.includes(
                                                  commList.selectionid
                                                ) && (
                                                    <BetPlaceMobile
                                                      setStakeModal={
                                                        setStakeModal
                                                      }
                                                      matchName={
                                                        inplayMatch?.matchName
                                                      }
                                                      closeSec={
                                                        commList.selectionid
                                                      }
                                                      closeRow={closeRow}
                                                      openBets={openBets}
                                                      betSlipData={betSlipData}
                                                      placeBet={placeBet}
                                                      count={betSlipData.count}
                                                      betLoading={betLoading}
                                                      increaseCount={
                                                        increaseCount
                                                      }
                                                      decreaseCount={
                                                        decreaseCount
                                                      }
                                                    />
                                                  )}
                                              </>
                                            </>
                                          )
                                        )
                                        : null}
                                    </>
                                  )}
                                </>
                              ) : null}
                            </div>
                          </>
                        )}
                      </>
                    ) : null}

                    {inplayMatch?.isFancy &&
                      isFancy &&
                      (activeTab === "Fancy" || activeTab === "all") && (
                        <div>
                          {matchScoreDetails &&
                            matchScoreDetails?.session &&
                            matchScoreDetails?.session?.length > 0 ? (
                            <>
                              {matchScoreDetails.session.filter(
                                (commList) => commList.com_perm == "YES"
                              ).length > 0 && (
                                  <header className="bg-[#333333] rounded-t-md z-[1] relative">
                                    <div className=" items-center flex relative z-0">
                                      <div className=" flex flex-1">
                                        <div className=" rounded-tl-md py-1.5  bg-button  uppercase px-2 items-center inline-flex text-sm font-medium text-black">
                                          Fancy Bets
                                        </div>
                                        <div className="w-1/2 px-2 flex items-center">
                                          {/* <img src={fastimg} alt="textd" className="h-5 w-5" /> */}
                                        </div>
                                      </div>

                                      <div className="  bg-[#333333]  rounded-tr-md flex flex-wrap justify-center items-center">
                                        <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                          {/* <span
                                        className="bg-white text-[10px] px-2 py-1 font-extrabold rounded-sm flex justify-center items-center"
                                        onClick={openRuleModal}
                                      >
                                        Rules
                                      </span> */}
                                          <span
                                            onClick={openRuleModal}
                                            className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="2"
                                              height="8"
                                              viewBox="0 0 2 8"
                                              fill="none"
                                            >
                                              <path
                                                _ngcontent-lmb-c38=""
                                                d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                fill="white"
                                              ></path>
                                            </svg>
                                          </span>
                                          {/* <span
                                        className="text-[10px] rounded font-semibold  px-2 py-0.5 border border-red-600 text-red-600"
                                        onClick={() =>
                                          setIsFancy(false)
                                        }
                                      >
                                        &#10005;
                                      </span>
                                      <span
                                        className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                        onClick={() =>
                                          setIsFancyHide(
                                            !isFancyHide
                                          )
                                        }
                                      >
                                        {isFancyHide ? (
                                          <FaMinus />
                                        ) : (
                                          <FaPlus />
                                        )}
                                      </span> */}
                                        </div>
                                      </div>
                                    </div>
                                  </header>
                                )}
                              {isFancyHide &&
                                matchScoreDetails &&
                                matchScoreDetails.session &&
                                matchScoreDetails.session.length > 0
                                ? matchScoreDetails.session
                                  .filter(
                                    (commList) => commList.com_perm == "YES"
                                  )
                                  .sort((a, b) => {
                                    const order = {
                                      STRING: 100,
                                      ONLY: 1,
                                      OVER: 2,
                                      "FALL OF": 3,
                                      RUN: 4,
                                      PSHIP: 5,
                                      BOUNDARIES: 6,
                                      HOW: 7,
                                      BALLS: 8,
                                    };

                                    const getSessionType = (sessionName) => {
                                      if (sessionName.includes("ONLY"))
                                        return "ONLY";
                                      if (sessionName.includes("OVER"))
                                        return "OVER";
                                      if (sessionName.includes("FALL OF"))
                                        return "FALL OF";
                                      if (sessionName.includes("RUN"))
                                        return "RUN";
                                      if (sessionName.includes("BOUNDARIES"))
                                        return "BOUNDARIES";
                                      if (sessionName.includes("HOW"))
                                        return "HOW";
                                      if (sessionName.includes("BALLS"))
                                        return "BALLS";
                                      return "STRING"; // Default to STRING if none match
                                    };

                                    const typeA = getSessionType(
                                      a.session_name
                                    );
                                    const typeB = getSessionType(
                                      b.session_name
                                    );

                                    // Compare based on the defined order
                                    let orderComparison =
                                      order[typeA] - order[typeB];

                                    // If types are the same, sort based on numeric value for "OVER"
                                    if (
                                      typeA === "OVER" &&
                                      typeB === "OVER"
                                    ) {
                                      const numberA =
                                        parseInt(
                                          a.session_name.split(" ")[0]
                                        ) || 0; // Extracting number before "OVER"
                                      const numberB =
                                        parseInt(
                                          b.session_name.split(" ")[0]
                                        ) || 0; // Extracting number before "OVER"
                                      return numberA - numberB; // Compare based on numeric values
                                    }

                                    return orderComparison;
                                  })
                                  .map(
                                    (commList, index) =>
                                      !deleteRowFancy.includes(index) && (
                                        <React.Fragment key={index}>
                                          <div
                                            className={` bg-[#2E2F2F] relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                          >
                                            <div className="w-1/2 lg:w-1/2 flex px- 2 lg:px-4 ">
                                              <div className="w-full leading-3 flex items-center ">
                                                <span className=" lg:hidden flex z-20 pr-1">
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal(
                                                        {
                                                          positionData:
                                                            commList,
                                                        }
                                                      )
                                                    }
                                                    className="text-black flex items-center justify-center"
                                                  >
                                                    {!hideRowFancy.includes(
                                                      index
                                                    ) && (
                                                        <TbLadder
                                                          size={20}
                                                          className="text-gray-400"
                                                        />
                                                      )}
                                                  </span>
                                                  <span
                                                    className="text-white text-[10px] px-0.5 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setDeleteRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <ImCross />
                                                  </span>
                                                  <span
                                                    className="text-white text-[10px] px-0.5 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setHideRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {hideRowFancy.includes(
                                                      index
                                                    ) ? (
                                                      <FaPlus />
                                                    ) : (
                                                      <FaMinus />
                                                    )}
                                                  </span>

                                                  {/* <span onClick={() => this.hideDiv(element.Selection_id)} className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold'>&times;</span> */}
                                                </span>
                                                <span className="text-xs truncate font-bold">
                                                  <span className="text-white text-xs truncate">
                                                    {commList.session_name}
                                                  </span>
                                                  <br />
                                                  <p
                                                    key={index}
                                                    className={
                                                      isNaN(
                                                        parseFloat(
                                                          fancyPositionObj[
                                                          commList
                                                            ?.session_id
                                                          ]
                                                        )
                                                      )
                                                        ? "text-green-500"
                                                        : parseFloat(
                                                          fancyPositionObj[
                                                          commList
                                                            ?.session_id
                                                          ]
                                                        ) > 0
                                                          ? "text-green-500"
                                                          : parseFloat(
                                                            fancyPositionObj[
                                                            commList
                                                              ?.session_id
                                                            ]
                                                          ) < 0
                                                            ? "text-red-500"
                                                            : "text-green-500"
                                                    }
                                                  >
                                                    {/* {isNaN(parseFloat(returnDataFancyObject[commList.session_id])) ? "" : parseFloat(returnDataFancyObject[commList.session_id]) !== 0 ? parseFloat(returnDataFancyObject[commList.session_id]).toFixed(2) : ""}  */}

                                                    {fancyPositionObj[
                                                      commList?.session_id
                                                    ]
                                                      ? (
                                                        Math.floor(
                                                          Number(
                                                            fancyPositionObj[
                                                            commList
                                                              ?.session_id
                                                            ]
                                                          ) * 100
                                                        ) / 100
                                                      ).toFixed(2)
                                                      : 0}
                                                  </p>
                                                </span>
                                              </div>
                                              {!hideRowFancy.includes(
                                                index
                                              ) && (
                                                  <span className=" lg:flex text-nowrap hidden text-[10px] items-center text-white">
                                                    Stake Limit: 100 -
                                                    {commList?.max}
                                                    <br /> Max Profit:
                                                    {commList?.max}
                                                  </span>
                                                )}

                                              {commList.session_id in
                                                fancyPositionObj && (
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal({
                                                        positionData: commList,
                                                      })
                                                    }
                                                    className="text-black lg:hidden block"
                                                  >
                                                    <TbLadder
                                                      size={20}
                                                      className="text-gray-400"
                                                    />
                                                  </span>
                                                )}
                                            </div>
                                            <>
                                              <div className="w-1/2 lg:w-1/2 grid grid-cols-6 space-x-[1px] py-0.5">
                                                <span className=" lg:flex hidden justify-end m-auto">
                                                  {/* {commList.session_id in
                                                                                            fancyPositionObj && ( */}
                                                  {/* <span  onClick={() => handleFancyPositionModal({positionData: commList,}) } className="text-black flex justify-end">
                                                                                            <TbLadder size={20} className="text-gray-400"/>
                                                                                        </span> */}
                                                  {/* )} */}
                                                </span>
                                                <span
                                                  className="md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "No",
                                                      odds: commList.oddsNo,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "N",
                                                      run: commList.runsNo,
                                                      selectionId:
                                                        commList.session_id,
                                                      price: commList.runsNo,
                                                      size: commList.oddsNo,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsNo}
                                                        size={(
                                                          commList.oddsNo * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#FBD7E5]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "No",
                                                      odds: commList.oddsNo,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "N",
                                                      run: commList.runsNo,
                                                      selectionId:
                                                        commList.session_id,
                                                      price: commList.runsNo,
                                                      size: commList.oddsNo,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsNo}
                                                        size={(
                                                          commList.oddsNo * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#FBD7E5]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );

                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "Yes",
                                                      odds: commList.oddsYes,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "Y",
                                                      run: commList.runsYes,
                                                      selectionId:
                                                        commList.session_id,
                                                      betfairMarketId:
                                                        marketId,
                                                      price: commList.runsYes,
                                                      size: commList.oddsYes,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsYes}
                                                        size={(
                                                          commList.oddsYes * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#9ECDDF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "Yes",
                                                      odds: commList.oddsYes,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "Y",
                                                      run: commList.runsYes,
                                                      selectionId:
                                                        commList.session_id,
                                                      betfairMarketId:
                                                        marketId,
                                                      price: commList.runsYes,
                                                      size: commList.oddsYes,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsYes}
                                                        size={(
                                                          commList.oddsYes * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#9ECDDF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    )}
                                                </span>

                                                <span className=" lg:block hidden"></span>
                                                <span className=" lg:flex hidden z-20">
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal(
                                                        {
                                                          positionData:
                                                            commList,
                                                        }
                                                      )
                                                    }
                                                    className="text-black flex items-center justify-center"
                                                  >
                                                    {!hideRowFancy.includes(
                                                      index
                                                    ) && (
                                                        <TbLadder
                                                          size={20}
                                                          className="text-gray-400"
                                                        />
                                                      )}
                                                  </span>
                                                  <span
                                                    className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setDeleteRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <ImCross />
                                                  </span>
                                                  <span
                                                    className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setHideRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {hideRowFancy.includes(
                                                      index
                                                    ) ? (
                                                      <FaPlus />
                                                    ) : (
                                                      <FaMinus />
                                                    )}
                                                  </span>

                                                  {/* <span onClick={() => this.hideDiv(element.Selection_id)} className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold'>&times;</span> */}
                                                </span>

                                                {commList &&
                                                  commList.running_status &&
                                                  (commList.running_status ===
                                                    "SUSPENDED" ||
                                                    commList.running_status ===
                                                    "CLOSE" ||
                                                    commList.running_status ===
                                                    "Ball Running") ? (
                                                  <div
                                                    className={`w-1/2 px-0.5 right-0 h-full  absolute bg-[#E4E4E4] lg:bg-transparent border-gray-900   flex justify-center items-center`}
                                                  >
                                                    <div className="2xl:px-14 lg:px-14 py-2 px-2 text-nowrap border-2 lg:border-[#E4E4E4] rounded font-bold bg-transparent lg:bg-[#E4E4E4] opacity-90">
                                                      <span className="text-black text-sm font-bold  uppercase opacity-100">
                                                        {
                                                          commList.running_status
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </>
                                          </div>

                                          {index <
                                            matchScoreDetails?.session
                                              ?.length -
                                            1 && <hr />}
                                          {hiddenRows.includes(
                                            commList.session_id
                                          ) && (
                                              <BetPlaceMobile
                                                setStakeModal={setStakeModal}
                                                matchName={
                                                  inplayMatch?.matchName
                                                }
                                                closeSec={commList.session_id}
                                                closeRow={closeRow}
                                                openBets={openBets}
                                                betSlipData={betSlipData}
                                                placeBet={placeBet}
                                                count={betSlipData.count}
                                                betLoading={betLoading}
                                                increaseCount={increaseCount}
                                                decreaseCount={decreaseCount}
                                              />
                                            )}
                                        </React.Fragment>
                                      )
                                  )
                                : null}
                            </>
                          ) : null}
                        </div>
                      )}









                    {inplayMatch?.isFancy &&
                      isCommFancy &&
                      (activeTab === "Fancy" || activeTab === "all") && (
                        <div>
                          {matchScoreDetails &&
                            matchScoreDetails?.session &&
                            matchScoreDetails?.session?.length > 0 ? (
                            <>
                              {matchScoreDetails.session.filter(
                                (commList) => commList.com_perm == "NO"
                              ).length > 0 && (
                                  <header className="bg-[#333333] rounded-t-md z-[1] relative">
                                    <div className=" items-center flex relative z-0">
                                      <div className=" flex flex-1">
                                        <div className=" rounded-tl-md py-1.5  bg-button  uppercase px-2 items-center inline-flex text-sm font-medium text-black">
                                          Comm Fancy Bets
                                        </div>
                                        <div className="w-1/2 px-2 flex items-center">
                                          {/* <img src={fastimg} alt="textd" className="h-5 w-5" /> */}
                                        </div>
                                      </div>

                                      <div className="  bg-[#333333]  rounded-tr-md flex flex-wrap justify-center items-center">
                                        <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                          {/* <span
                                        className="bg-white text-[10px] px-2 py-1 font-extrabold rounded-sm flex justify-center items-center"
                                        onClick={openRuleModal}
                                      >
                                        Rules
                                      </span> */}
                                          <span
                                            onClick={openRuleModal}
                                            className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="2"
                                              height="8"
                                              viewBox="0 0 2 8"
                                              fill="none"
                                            >
                                              <path
                                                _ngcontent-lmb-c38=""
                                                d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                fill="white"
                                              ></path>
                                            </svg>
                                          </span>
                                          {/* <span
                                        className="text-[10px] rounded font-semibold  px-2 py-0.5 border border-red-600 text-red-600"
                                        onClick={() =>
                                          setIsCommFancy(false)
                                        }
                                      >
                                        &#10005;
                                      </span>
                                      <span
                                        className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                        onClick={() =>
                                          setIsCommFancyHide(
                                            !isCommFancyHide
                                          )
                                        }
                                      >
                                        {isCommFancyHide ? (
                                          <FaMinus />
                                        ) : (
                                          <FaPlus />
                                        )}
                                      </span> */}
                                        </div>
                                      </div>
                                    </div>
                                  </header>
                                )}
                              {isCommFancyHide &&
                                matchScoreDetails &&
                                matchScoreDetails.session &&
                                matchScoreDetails.session.length > 0
                                ? matchScoreDetails.session
                                  .filter(
                                    (commList) => commList.com_perm == "NO"
                                  )
                                  .sort((a, b) => {
                                    const order = {
                                      STRING: 100,
                                      ONLY: 1,
                                      OVER: 2,
                                      "FALL OF": 3,
                                      RUN: 4,
                                      PSHIP: 5,
                                      BOUNDARIES: 6,
                                      HOW: 7,
                                      BALLS: 8,
                                    };

                                    const getSessionType = (sessionName) => {
                                      if (sessionName.includes("ONLY"))
                                        return "ONLY";
                                      if (sessionName.includes("OVER"))
                                        return "OVER";
                                      if (sessionName.includes("FALL OF"))
                                        return "FALL OF";
                                      if (sessionName.includes("RUN"))
                                        return "RUN";
                                      if (sessionName.includes("BOUNDARIES"))
                                        return "BOUNDARIES";
                                      if (sessionName.includes("HOW"))
                                        return "HOW";
                                      if (sessionName.includes("BALLS"))
                                        return "BALLS";
                                      return "STRING"; // Default to STRING if none match
                                    };

                                    const typeA = getSessionType(
                                      a.session_name
                                    );
                                    const typeB = getSessionType(
                                      b.session_name
                                    );

                                    // Compare based on the defined order
                                    let orderComparison =
                                      order[typeA] - order[typeB];

                                    // If types are the same, sort based on numeric value for "OVER"
                                    if (
                                      typeA === "OVER" &&
                                      typeB === "OVER"
                                    ) {
                                      const numberA =
                                        parseInt(
                                          a.session_name.split(" ")[0]
                                        ) || 0; // Extracting number before "OVER"
                                      const numberB =
                                        parseInt(
                                          b.session_name.split(" ")[0]
                                        ) || 0; // Extracting number before "OVER"
                                      return numberA - numberB; // Compare based on numeric values
                                    }

                                    return orderComparison;
                                  })
                                  .map(
                                    (commList, index) =>
                                      !deleteRowFancy.includes(index) && (
                                        <React.Fragment key={index}>
                                          <div
                                            className={` bg-[#2E2F2F] relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                          >
                                            <div className="w-1/2 lg:w-1/2 flex px- 2 lg:px-4 ">
                                              <div className="w-full leading-3 flex items-center ">
                                                <span className=" lg:hidden flex z-20 pr-1">
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal(
                                                        {
                                                          positionData:
                                                            commList,
                                                        }
                                                      )
                                                    }
                                                    className="text-black flex items-center justify-center"
                                                  >
                                                    {!hideRowFancy.includes(
                                                      index
                                                    ) && (
                                                        <TbLadder
                                                          size={20}
                                                          className="text-gray-400"
                                                        />
                                                      )}
                                                  </span>
                                                  <span
                                                    className="text-white text-[10px] px-0.5 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setDeleteRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <ImCross />
                                                  </span>
                                                  <span
                                                    className="text-white text-[10px] px-0.5 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setHideRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {hideRowFancy.includes(
                                                      index
                                                    ) ? (
                                                      <FaPlus />
                                                    ) : (
                                                      <FaMinus />
                                                    )}
                                                  </span>

                                                  {/* <span onClick={() => this.hideDiv(element.Selection_id)} className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold'>&times;</span> */}
                                                </span>
                                                <span className="text-xs truncate font-bold">
                                                  <span className="text-white text-xs truncate">
                                                    {commList.session_name}
                                                  </span>
                                                  <br />
                                                  <p
                                                    key={index}
                                                    className={
                                                      isNaN(
                                                        parseFloat(
                                                          fancyPositionObj[
                                                          commList
                                                            ?.session_id
                                                          ]
                                                        )
                                                      )
                                                        ? "text-green-500"
                                                        : parseFloat(
                                                          fancyPositionObj[
                                                          commList
                                                            ?.session_id
                                                          ]
                                                        ) > 0
                                                          ? "text-green-500"
                                                          : parseFloat(
                                                            fancyPositionObj[
                                                            commList
                                                              ?.session_id
                                                            ]
                                                          ) < 0
                                                            ? "text-red-500"
                                                            : "text-green-500"
                                                    }
                                                  >
                                                    {/* {isNaN(parseFloat(returnDataFancyObject[commList.session_id])) ? "" : parseFloat(returnDataFancyObject[commList.session_id]) !== 0 ? parseFloat(returnDataFancyObject[commList.session_id]).toFixed(2) : ""}  */}

                                                    {fancyPositionObj[
                                                      commList?.session_id
                                                    ]
                                                      ? (
                                                        Math.floor(
                                                          Number(
                                                            fancyPositionObj[
                                                            commList
                                                              ?.session_id
                                                            ]
                                                          ) * 100
                                                        ) / 100
                                                      ).toFixed(2)
                                                      : 0}
                                                  </p>
                                                </span>
                                              </div>
                                              {!hideRowFancy.includes(
                                                index
                                              ) && (
                                                  <span className=" lg:flex text-nowrap hidden text-[10px] items-center text-white">
                                                    Stake Limit: 100 -
                                                    {commList?.max}
                                                    <br /> Max Profit:
                                                    {commList?.max}
                                                  </span>
                                                )}

                                              {commList.session_id in
                                                fancyPositionObj && (
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal({
                                                        positionData: commList,
                                                      })
                                                    }
                                                    className="text-black lg:hidden block"
                                                  >
                                                    <TbLadder
                                                      size={20}
                                                      className="text-gray-400"
                                                    />
                                                  </span>
                                                )}
                                            </div>
                                            <>
                                              <div className="w-1/2 lg:w-1/2 grid grid-cols-6 space-x-[1px] py-0.5">
                                                <span className=" lg:flex hidden justify-end m-auto">
                                                  {/* {commList.session_id in
                                                                                            fancyPositionObj && ( */}
                                                  {/* <span  onClick={() => handleFancyPositionModal({positionData: commList,}) } className="text-black flex justify-end">
                                                                                            <TbLadder size={20} className="text-gray-400"/>
                                                                                        </span> */}
                                                  {/* )} */}
                                                </span>
                                                <span
                                                  className="md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "No",
                                                      odds: commList.oddsNo,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "N",
                                                      run: commList.runsNo,
                                                      selectionId:
                                                        commList.session_id,
                                                      price: commList.runsNo,
                                                      size: commList.oddsNo,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsNo}
                                                        size={(
                                                          commList.oddsNo * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#FBD7E5]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "No",
                                                      odds: commList.oddsNo,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "N",
                                                      run: commList.runsNo,
                                                      selectionId:
                                                        commList.session_id,
                                                      price: commList.runsNo,
                                                      size: commList.oddsNo,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsNo}
                                                        size={(
                                                          commList.oddsNo * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#FBD7E5]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );

                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "Yes",
                                                      odds: commList.oddsYes,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "Y",
                                                      run: commList.runsYes,
                                                      selectionId:
                                                        commList.session_id,
                                                      betfairMarketId:
                                                        marketId,
                                                      price: commList.runsYes,
                                                      size: commList.oddsYes,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsYes}
                                                        size={(
                                                          commList.oddsYes * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#9ECDDF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "Yes",
                                                      odds: commList.oddsYes,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "Y",
                                                      run: commList.runsYes,
                                                      selectionId:
                                                        commList.session_id,
                                                      betfairMarketId:
                                                        marketId,
                                                      price: commList.runsYes,
                                                      size: commList.oddsYes,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsYes}
                                                        size={(
                                                          commList.oddsYes * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#9ECDDF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    )}
                                                </span>

                                                <span className=" lg:block hidden"></span>
                                                <span className=" lg:flex hidden z-20">
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal(
                                                        {
                                                          positionData:
                                                            commList,
                                                        }
                                                      )
                                                    }
                                                    className="text-black flex items-center justify-center"
                                                  >
                                                    {!hideRowFancy.includes(
                                                      index
                                                    ) && (
                                                        <TbLadder
                                                          size={20}
                                                          className="text-gray-400"
                                                        />
                                                      )}
                                                  </span>
                                                  <span
                                                    className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setDeleteRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <ImCross />
                                                  </span>
                                                  <span
                                                    className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setHideRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {hideRowFancy.includes(
                                                      index
                                                    ) ? (
                                                      <FaPlus />
                                                    ) : (
                                                      <FaMinus />
                                                    )}
                                                  </span>

                                                  {/* <span onClick={() => this.hideDiv(element.Selection_id)} className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold'>&times;</span> */}
                                                </span>

                                                {commList &&
                                                  commList.running_status &&
                                                  (commList.running_status ===
                                                    "SUSPENDED" ||
                                                    commList.running_status ===
                                                    "CLOSE" ||
                                                    commList.running_status ===
                                                    "Ball Running") ? (
                                                  <div
                                                    className={`w-1/2 px-0.5 right-0 h-full  absolute bg-[#E4E4E4] lg:bg-transparent border-gray-900   flex justify-center items-center`}
                                                  >
                                                    <div className="2xl:px-14 lg:px-14 py-2 px-2 text-nowrap border-2 lg:border-[#E4E4E4] rounded font-bold bg-transparent lg:bg-[#E4E4E4] opacity-90">
                                                      <span className="text-black text-sm font-bold  uppercase opacity-100">
                                                        {
                                                          commList.running_status
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </>
                                          </div>

                                          {index <
                                            matchScoreDetails?.session
                                              ?.length -
                                            1 && <hr />}
                                          {hiddenRows.includes(
                                            commList.session_id
                                          ) && (
                                              <BetPlaceMobile
                                                setStakeModal={setStakeModal}
                                                matchName={
                                                  inplayMatch?.matchName
                                                }
                                                closeSec={commList.session_id}
                                                closeRow={closeRow}
                                                openBets={openBets}
                                                betSlipData={betSlipData}
                                                placeBet={placeBet}
                                                count={betSlipData.count}
                                                betLoading={betLoading}
                                                increaseCount={increaseCount}
                                                decreaseCount={decreaseCount}
                                              />
                                            )}
                                        </React.Fragment>
                                      )
                                  )
                                : null}
                            </>
                          ) : null}
                        </div>
                      )}

                    {inplayMatch?.isTieOdds &&
                      (activeTab === "matchOdds" || activeTab === "all") ? (
                      <>
                        {isTieMatch && (
                          <>
                            {Object.values(finalSocket).map(
                              (elementMatchOdds, index) =>
                                elementMatchOdds.marketType === "Match Odds" &&
                                (elementMatchOdds &&
                                  elementMatchOdds.runners &&
                                  elementMatchOdds.runners.length <= 2 ? (
                                  <>
                                    {Object.values(finalSocket).map(
                                      (element, index) =>
                                        element.marketType === "Tied Match" && (
                                          <div>
                                            <div className="rounded-md divide-y">
                                              <header className="bg-[#333333] rounded-t-md">
                                                <div className=" items-center flex relative z-0">
                                                  <div className="  flex flex-1">
                                                    <span className="uppercase items-center py-1 px-2 rounded-tl-md bg-button inline-flex text-sm font-medium text-black ">
                                                      {element &&
                                                        element.marketType
                                                        ? element.marketType
                                                        : "NA"}
                                                    </span>
                                                    <span></span>
                                                  </div>

                                                  <div className="relative bg-[#333333]  rounded-tr-md flex flex-wrap justify-center items-center">
                                                    
                                                    
                                                    <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                                      <span
                                                        className="bg-white text-[10px] px-2 py-1 font-extrabold rounded-sm flex justify-center items-center"
                                                        onClick={openRuleModal}
                                                      >
                                                        Rules
                                                      </span>
                                                      <span className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                                        onClick={() => togglePopover('tieStake')}
                                                      
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="2"
                                                          height="8"
                                                          viewBox="0 0 2 8"
                                                          fill="none"
                                                        >
                                                          <path
                                                            _ngcontent-lmb-c38=""
                                                            d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                            fill="white"
                                                          ></path>
                                                        </svg>
                                                      </span>
                                                      <span
                                                        className="text-[10px] rounded font-semibold  px-2 py-0.5 border border-red-600 text-red-600"
                                                        onClick={() =>
                                                          setIsTieMatch(false)
                                                        }
                                                      >
                                                        &#10005;
                                                      </span>
                                                      <span
                                                        className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                        onClick={() =>
                                                          setIsTieHide(
                                                            !isTieHide
                                                          )
                                                        }
                                                      >
                                                        {isTieHide ? (
                                                          <FaMinus />
                                                        ) : (
                                                          <FaPlus />
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.tieStake && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">{isTieCoin?.min} - {isTieCoin?.max} </span></p>
                                <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> {isTieCoin?.max}</span></p>
                              </div>

                              )}
                              </div>
                                                  </div>
                                                </div>
                                                <div className=" items-center flex relative bg-black z-[-1]">
                                                  <div className="xl:w-2/3 w-1/2 flex ">
                                                    <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-white">
                                                      {element.runners.length >
                                                        0
                                                        ? element.runners.length
                                                        : null}{" "}
                                                      Seclection
                                                    </div>
                                                  </div>

                                                  <div className="xl:w-1/3 w-1/2   divide-y divide-black  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                                    <div className="grid grid-cols-6 text-[10px] text-white space-x-0.5 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center lg:px-2 px-1  items-center">
                                                      <span className="md:block hidden"></span>
                                                      <span className="md:block hidden"></span>
                                                      <span className="lg:col-span-1 text-nowrap md:col-span-2 col-span-3 lg:col-start-1   font-bold md:text-right text-center px-1">
                                                        Back {"{L}"}
                                                      </span>
                                                      <span className="col-span-3 text-nowrap  font-bold  text-center px-4">
                                                        Lay {"{N}"}
                                                      </span>
                                                      <span></span>
                                                      <span></span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </header>
                                              {isTieHide && (
                                                <>
                                                  {element &&
                                                    element.runners &&
                                                    element.runners.length > 0
                                                    ? element.runners.map(
                                                      (
                                                        elementtemp,
                                                        index
                                                      ) => (
                                                        <>
                                                          <div
                                                            className={` bg-[#2E2F2F] px-2 shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                                          >
                                                            <div className="w-1/2 lg:w-1/2  flex px-4">
                                                              <div className="w-11/12  py-1 leading-3 flex items-center text-slate-700">
                                                                <span class="text-sm font-bold">
                                                                  <span className="text-white font-bold text-xs">
                                                                    {
                                                                      elementtemp.selectionName
                                                                    }{" "}
                                                                    <br />
                                                                    <span
                                                                      key={
                                                                        index
                                                                      }
                                                                      className={
                                                                        positionObj[
                                                                          elementtemp
                                                                            ?.selectionId
                                                                        ] > 0
                                                                          ? "text-green-500"
                                                                          : positionObj[
                                                                            elementtemp
                                                                              ?.selectionId
                                                                          ] <
                                                                            0
                                                                            ? "text-red-500"
                                                                            : "text-green-500"
                                                                      }
                                                                    >
                                                                      {/* {returnDataObject[elementtemp.selectionId] !== 0 ? returnDataObject[elementtemp.selectionId] : "-"} */}
                                                                      {positionObj[
                                                                        elementtemp
                                                                          ?.selectionId
                                                                      ]
                                                                        ? (
                                                                          Math.floor(
                                                                            Number(
                                                                              positionObj[
                                                                              elementtemp
                                                                                ?.selectionId
                                                                              ]
                                                                            ) *
                                                                            100
                                                                          ) /
                                                                          100
                                                                        ).toFixed(
                                                                          2
                                                                        )
                                                                        : 0}
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div className="w-1/2 lg:w-1/2 grid grid-cols-6 space-x-0.5 ">
                                                              {elementtemp &&
                                                                elementtemp.ex &&
                                                                elementtemp.ex
                                                                  .availableToBack &&
                                                                elementtemp.ex
                                                                  .availableToBack
                                                                  .length >
                                                                0 ? (
                                                                <>
                                                                  {elementtemp.ex.availableToBack
                                                                    .slice(1)
                                                                    .map(
                                                                      (
                                                                        tempData,
                                                                        index
                                                                      ) => (
                                                                        <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                                          <Blinking
                                                                            price={
                                                                              tempData.price
                                                                            }
                                                                            size={
                                                                              tempData.size
                                                                            }
                                                                            color={
                                                                              "bg-[#4C4C4C]"
                                                                            }
                                                                            blinkColor={
                                                                              "bg-[#CDEBEB]"
                                                                            }
                                                                            textColors={
                                                                              "text-white"
                                                                            }
                                                                            boderColors={
                                                                              "border-black"
                                                                            }
                                                                          />
                                                                        </span>
                                                                      )
                                                                    )}
                                                                </>
                                                              ) : null}

                                                              {elementtemp &&
                                                                elementtemp.ex &&
                                                                elementtemp.ex
                                                                  .availableToBack &&
                                                                elementtemp.ex
                                                                  .availableToBack
                                                                  .length >
                                                                0 ? (
                                                                <>
                                                                  {elementtemp.ex.availableToBack
                                                                    .slice(
                                                                      0,
                                                                      1
                                                                    )
                                                                    .map(
                                                                      (
                                                                        tempData,
                                                                        index
                                                                      ) => (
                                                                        <>
                                                                          <span
                                                                            className="md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 py-1 lg:hidden block"
                                                                            onClick={() => {
                                                                              toggleRowVisibility(
                                                                                elementtemp.selectionId
                                                                              );
                                                                              handleBackOpen(
                                                                                {
                                                                                  data: tempData,
                                                                                  type: "Yes",
                                                                                  nameOther:
                                                                                    element.runners,
                                                                                  odds: tempData.price,
                                                                                  name: elementtemp.selectionName,
                                                                                  betFor:
                                                                                    "tiedMatch",
                                                                                  oddsType:
                                                                                    element.marketType,
                                                                                  betType:
                                                                                    "L",
                                                                                  selectionId:
                                                                                    elementtemp.selectionId,
                                                                                  teamData:
                                                                                    tempData.price,
                                                                                  betfairMarketId:
                                                                                    element.marketId,
                                                                                  price:
                                                                                    elementtemp
                                                                                      .ex
                                                                                      .availableToLay[0]
                                                                                      .price,
                                                                                  size: elementtemp
                                                                                    .ex
                                                                                    .availableToLay[0]
                                                                                    .size,
                                                                                  position:
                                                                                    returnDataObject,
                                                                                  newPosition:
                                                                                    returnDataObject,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#9ECDDF]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-black"
                                                                              }
                                                                              boderColors={
                                                                                "border-[#489bbd]"
                                                                              }
                                                                            />
                                                                          </span>
                                                                          <span
                                                                            className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                                            onClick={() => {
                                                                              toggleRowVisibility(
                                                                                elementtemp.selectionId
                                                                              );
                                                                              handleBackOpen(
                                                                                {
                                                                                  data: tempData,
                                                                                  type: "Yes",
                                                                                  nameOther:
                                                                                    element.runners,
                                                                                  odds: tempData.price,
                                                                                  name: elementtemp.selectionName,
                                                                                  betFor:
                                                                                    "tiedMatch",
                                                                                  oddsType:
                                                                                    element.marketType,
                                                                                  betType:
                                                                                    "L",
                                                                                  selectionId:
                                                                                    elementtemp.selectionId,
                                                                                  teamData:
                                                                                    tempData.price,
                                                                                  betfairMarketId:
                                                                                    element.marketId,
                                                                                  price:
                                                                                    elementtemp
                                                                                      .ex
                                                                                      .availableToLay[0]
                                                                                      .price,
                                                                                  size: elementtemp
                                                                                    .ex
                                                                                    .availableToLay[0]
                                                                                    .size,
                                                                                  position:
                                                                                    returnDataObject,
                                                                                  newPosition:
                                                                                    returnDataObject,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#9ECDDF]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-black"
                                                                              }
                                                                              boderColors={
                                                                                "border-[#489bbd]"
                                                                              }
                                                                            />
                                                                          </span>
                                                                        </>
                                                                      )
                                                                    )}
                                                                </>
                                                              ) : null}

                                                              {elementtemp &&
                                                                elementtemp.ex &&
                                                                elementtemp.ex
                                                                  .availableToLay &&
                                                                elementtemp.ex
                                                                  .availableToLay
                                                                  .length > 0
                                                                ? elementtemp.ex.availableToLay.map(
                                                                  (
                                                                    tempData,
                                                                    index
                                                                  ) => (
                                                                    <>
                                                                      {index ===
                                                                        0 ? (
                                                                        <>
                                                                          <span
                                                                            className="md:col-span-2 sm:col-span-2 md:col-start-4 col-span-3 py-1 lg:hidden block"
                                                                            onClick={() => {
                                                                              toggleRowVisibility(
                                                                                elementtemp.selectionId
                                                                              );

                                                                              handleBackOpen(
                                                                                {
                                                                                  data: tempData,
                                                                                  type: "No",
                                                                                  odds: tempData.price,
                                                                                  name: elementtemp.selectionName,
                                                                                  nameOther:
                                                                                    element.runners,
                                                                                  betFor:
                                                                                    "tiedMatch",
                                                                                  oddsType:
                                                                                    element.marketType,
                                                                                  betType:
                                                                                    "K",
                                                                                  selectionId:
                                                                                    elementtemp.selectionId,
                                                                                  teamData:
                                                                                    tempData.price,
                                                                                  betfairMarketId:
                                                                                    element.marketId,
                                                                                  price:
                                                                                    elementtemp
                                                                                      .ex
                                                                                      .availableToBack[0]
                                                                                      .price,
                                                                                  size: elementtemp
                                                                                    .ex
                                                                                    .availableToBack[0]
                                                                                    .size,
                                                                                  position:
                                                                                    returnDataObject,
                                                                                  newPosition:
                                                                                    returnDataObject,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#FBD7E5]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-black"
                                                                              }
                                                                              boderColors={
                                                                                "border-[#f996ab]"
                                                                              }
                                                                            />
                                                                          </span>

                                                                          <span
                                                                            className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                                            onClick={() => {
                                                                              toggleRowVisibility(
                                                                                elementtemp.selectionId
                                                                              );
                                                                              handleBackOpen(
                                                                                {
                                                                                  data: tempData,
                                                                                  type: "No",
                                                                                  odds: tempData.price,
                                                                                  name: elementtemp.selectionName,
                                                                                  nameOther:
                                                                                    element.runners,
                                                                                  betFor:
                                                                                    "tiedMatch",
                                                                                  oddsType:
                                                                                    element.marketType,
                                                                                  betType:
                                                                                    "K",
                                                                                  selectionId:
                                                                                    elementtemp.selectionId,
                                                                                  teamData:
                                                                                    tempData.price,
                                                                                  betfairMarketId:
                                                                                    element.marketId,
                                                                                  price:
                                                                                    elementtemp
                                                                                      .ex
                                                                                      .availableToBack[0]
                                                                                      .price,
                                                                                  size: elementtemp
                                                                                    .ex
                                                                                    .availableToBack[0]
                                                                                    .size,
                                                                                  position:
                                                                                    returnDataObject,
                                                                                  newPosition:
                                                                                    returnDataObject,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#FBD7E5]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-black"
                                                                              }
                                                                              boderColors={
                                                                                "border-[#f996ab]"
                                                                              }
                                                                            />
                                                                          </span>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#4C4C4C]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-white"
                                                                              }
                                                                              boderColors={
                                                                                "border-black"
                                                                              }
                                                                            />
                                                                          </span>
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  )
                                                                )
                                                                : null}
                                                            </div>
                                                          </div>

                                                          {hiddenRows.includes(
                                                            elementtemp.selectionId
                                                          ) && (
                                                              <BetPlaceMobile
                                                                setStakeModal={
                                                                  setStakeModal
                                                                }
                                                                closeRow={
                                                                  closeRow
                                                                }
                                                                closeSec={
                                                                  elementtemp.selectionId
                                                                }
                                                                matchName={
                                                                  inplayMatch?.matchName
                                                                }
                                                                openBets={
                                                                  openBets
                                                                }
                                                                betSlipData={
                                                                  betSlipData
                                                                }
                                                                placeBet={
                                                                  placeBet
                                                                }
                                                                count={
                                                                  betSlipData.count
                                                                }
                                                                betLoading={
                                                                  betLoading
                                                                }
                                                                increaseCount={
                                                                  increaseCount
                                                                }
                                                                decreaseCount={
                                                                  decreaseCount
                                                                }
                                                              />
                                                            )}
                                                        </>
                                                      )
                                                    )
                                                    : null}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        )
                                    )}
                                  </>
                                ) : null)
                            )}
                          </>
                        )}{" "}
                      </>
                    ) : null}
                  </div>
                </div>
              )}
            </>
            {/* // )} */}
          </div>

          <div
            ref={scrollRef}
            className={`lg:w-4/12 px-2 py-2   right-1 lg:block hidden overflow-hidden ${isFixed ? "dddddd" : "fffffffff"
              }`}
          >
            {inplayMatch.isTv ? (
              <>
                {tvShow && (
                  <div className="bg-white w-full h-72">
                    <div className="details">
                      <div className={`w-full relative md:text-sm text-[10px]`}>
                        <iframe
                          src={
                            inplayMatch && inplayMatch.tvUrl
                              ? inplayMatch.tvUrl
                              : ""
                          }
                          title=" "
                          loading="lazy"
                          className="w-[100%] h-[300px]"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : null}

            <BetListComponent
              oddsBetData={oddsBetData}
              fancyBetData={fancyBetData}
              matchName={inplayMatch?.matchName}
            />
          </div>
          {fancypositionModal && (
            <FancyLadder
              handleClose={handleClose}
              positionData={positionData}
              marketId={marketId}
            />
          )}
        </div>
      </div>
      <Rules isOpen={isRulesOpen} closeModal={closeRuleModal} />
    </section>
  );
};

export default MatchViewDeatils;
