import { useNavigate } from "react-router-dom";
import { casinoImages } from "../../middelware/casinoConstants";

function ModalContent({ setIsDrawerOpen }) {
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    setIsDrawerOpen();
  };
  const handleResponseCasino = (product) => {
    localStorage.getItem("token")
      ? navigate(`/${product.shortName}/${product.eventId}`)
      : localStorage.setItem("unauthorized", true);
      setIsDrawerOpen(false);
  };
  return (
    <div className="overlay-menu pt-3 px-2 h-[90vh] overflow-auto text-[8px] sm:text-xs">
      <img
        src="/assets/appImages/magic-win-biz/assets/images/more-ipl.webp"
        alt="Overlay Menu"
        className="w-full"
        tabIndex="0"
      />
      <div className="text-white grid grid-cols-4 gap-2 my-2 border-[0.3px] border-gray-300 px-2 py-2 rounded-md">
        <div
          onClick={() => handleClick("/")}
          className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1"
        >
          <img
            alt="Home"
            width="30"
            height="30"
            className="mx-auto"
            src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/60.png"
          />
          <span className="text-capitalize d-block lh-sm mt-1">Home</span>
        </div>
        <div
          onClick={() => {
            handleClick("/deposit");
          }}
          className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1"
        >
          <img
            alt="International Casino"
            width="30"
            height="30"
            className="mx-auto"
            src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/93.png"
          />
          <span className="text-capitalize d-block lh-sm mt-1">Deposit</span>
        </div>
        <div
          onClick={() => {
            handleClick("/withdrawal");
          }}
          className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1"
        >
          <img
            alt="International Casino"
            width="30"
            height="30"
            className="mx-auto"
            src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/95.png"
          />
          <span className="text-capitalize d-block lh-sm mt-1">Withdrwal</span>
        </div>
        <div
          onClick={() => {
            handleClick("/iframe-casino/201206");
          }}
          className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1"
        >
          <img
            alt="Aviator"
            width="30"
            height="30"
            className="mx-auto"
            src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/63.png"
          />
          <span className="text-capitalize d-block lh-sm mt-1">Aviator</span>
        </div>
      </div>

      <div className="text-white  my-2 border-[0.3px] border-gray-300 px-2 py-2 rounded-md">
        <span className=" px-2 text-lg font-semibold text-button">Sports</span>
        <div className="grid grid-cols-4 gap-2">
          <div
            onClick={() => {
              handleClick("/inplay");
            }}
            className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1"
          >
            <img
              alt="In Play"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/64.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">In Play</span>
          </div>
          {/* <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Sports Book"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/114.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Sports Book
            </span>
          </div> */}
          <div
            onClick={() => {
              handleClick("/inplay");
            }}
            className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1"
          >
            <img
              alt="Cricket"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/496.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">Cricket</span>
          </div>
          <div
            onClick={() => {
              handleClick("/inplay");
            }}
            className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1"
          >
            <img
              alt="Football"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/78.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">Football</span>
          </div>
          <div
            onClick={() => {
              handleClick("/inplay");
            }}
            className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1"
          >
            <img
              alt="Tennis"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/79.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">Tennis</span>
          </div>
          {/* <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Horse Racing"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/497.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Horse Racing
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Grey Hound"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/498.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Grey Hound
            </span>
          </div> */}
          {/* <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="All Sports"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/433.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              All Sports
            </span>
          </div> */}
        </div>
      </div>

      <div className="text-white  my-2 border-[0.3px] border-gray-300 px-2 py-2 rounded-md">                                                                              
        <span className=" px-2 text-lg font-semibold text-button">Casinos</span>
        <div className="grid grid-cols-4 gap-2">
          {casinoImages?.filter((image) => image.section2 === "liveCasino")?.map((game, index) => (
              <div
                onClick={() => handleResponseCasino(game)}
                className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1"
                key={index}
              >
                <img
                  alt="Multiplayer Games"
                  width="30"
                  height="30"
                  className="mx-auto"
                  src={game.shortImgPath}
                />
                <span className="text-capitalize d-block lh-sm mt-1">
                  {game.title}
                </span>
              </div>
            ))}

          {/* <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Popular Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/69.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Popular Games
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="International Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/70.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              International Casino
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Crash Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/71.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Crash Games
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Live Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/72.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Live Casino
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Table Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/73.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Table Games
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Indian Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/74.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Indian Casino
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Indian Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/75.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Indian Casino
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Indian Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/81.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Indian Casino
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Indian Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/82.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Indian Casino
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Indian Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/83.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Indian Casino
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Indian Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/84.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Indian Casino
            </span>
          </div> */}






        </div>
      </div>
      <div className="text-white  my-2 border-[0.3px] border-gray-300 px-2 py-2 rounded-md">
        <span className=" px-2 text-lg font-semibold text-button">
          Providers
        </span>
        <div className="grid grid-cols-4 gap-2">
          <div onClick={()=>handleClick("/iframe-casino/100001")} className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Multiplayer Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/navigation/57.webp"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Ezugi
            </span>
          </div>
          <div onClick={()=>{handleClick('/iframe-casino/600000')}} className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Popular Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/86.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Jilli
            </span>
          </div>
          <div onClick={()=>{handleClick('iframe-qtech-casino/SPB-aviator')}} className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="International Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/categorydetail/46.webp"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Qtech
            </span>
          </div>
          <div onClick={()=>{handleClick('/iframe-casino/201153')}} className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Crash Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/categorydetail/1629.webp"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
            Wheel
            </span>
          </div>

          <div onClick={()=>{handleClick('/iframe-casino/200215')}} className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Evolution"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/navigation/56.webp"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
            Evolution
            </span>
          </div>


          <div onClick={()=>{handleClick('/iframe-casino/201206')}} className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="AVIATOR"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/categorydetail/49.webp"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
            AVIATOR
            </span>
          </div>

          <div onClick={()=>{handleClick('/iframe-casino/900000')}} className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Royal Gaming"
              width="70"
              height="70"
              className="mx-auto"
              src="/assets/rgCasino.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
            Royal Gaming
            </span>
          </div>

          <div onClick={()=>{handleClick('/iframe-casino/400000')}} className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="AE SEXY"
              width="70"
              height="70"
              className="mx-auto"
              src="/assets/HotnSexy.jpg"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
            AE SEXY
            </span>
          </div>

          <div onClick={()=>{handleClick('/iframe-casino/500001')}} className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Supernova"
              width="50"
              height="50"
              className="mx-auto"
              src="/assets/supernova.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
            Supernova
            </span>
          </div>
        </div>
      </div>
      {/* <div className="text-white  my-2 border-[0.3px] border-gray-300 px-2 py-2 rounded-md">
        <span className=" px-2 text-lg font-semibold text-button">
          Accessibility
        </span>
        <div className="grid grid-cols-4 gap-2">
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Multiplayer Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/89.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Multiplayer Games
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Popular Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/92.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Popular Games
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="International Casino"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/93.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              International Casino
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Crash Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/94.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Crash Games
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Crash Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/95.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Crash Games
            </span>
          </div>
          <div className="flex flex-col justify-center items-center border bg-secondary rounded-sm p-1">
            <img
              alt="Crash Games"
              width="30"
              height="30"
              className="mx-auto"
              src="/assets/appImages/magic-win-biz/cms-content/assets/images/icons/96.png"
            />
            <span className="text-capitalize d-block lh-sm mt-1">
              Crash Games
            </span>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ModalContent;
