import React from "react";
import { data } from "./contentJson";

const Contact = () => {
    const sortedData = [...data].sort((a, b) => a.order - b.order);
    return (
        <div className="bg-black min-h-screen flex items-center justify-center p-6">
            <div className="w-full max-w-3xl space-y-6">
                {sortedData.map((item) => (
                    <div
                        key={item.id}
                        className="bg-[#1A1A1A] p-4 rounded-md transition-colors"
                    >
                        <div className="text-orange-500 text-3xl mr-4 flex justify-center items-center">{item.icon}</div>
                        <p className="text-white text-sm leading-relaxed flex justify-center items-center">{item.content}</p>
                    </div>
                ))}
            </div>
        </div>

    );
}

export default Contact