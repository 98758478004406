import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userUpdate } from "../../redux/_reducers/_user_reducers";

export const betChipsData = {
  1000: 1000,
  2000: 2000,
  5000: 5000,
  10000: 10000,
  20000: 20000,
  50000: 50000,
  100000: 100000,
  250000: 250000,
};

const EditStack = ({ closeModal }) => {
  const [isActive, setIsActive] = useState(false);
  const [isFormEnabled1, setIsFormEnabled1] = useState(false);
  const [isFormEnabled2, setIsFormEnabled2] = useState(false);
  const [keyValues1, setKeyValues1] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const betChipsDataItems = JSON.parse(
      localStorage.getItem("clientbetChipsData")
    );
    let betChips = {};
    if (betChipsDataItems != null) {
      betChips = betChipsDataItems;
    } else {
      betChips = betChipsData;
    }
    const updatedKeyValues = Object.entries(betChips).map(([key, value]) => ({
      key,
      value: parseInt(value),
    }));
    setKeyValues1(updatedKeyValues);
  }, []);

  const handleInputChange = (index, key, value) => {
    const updatedKeyValues = [...keyValues1];
    if (key !== undefined) {
      updatedKeyValues[index].key = key;
    }
    if (value !== undefined) {
      updatedKeyValues[index].value = Number(value);
    }
    setKeyValues1(updatedKeyValues);
  };

  const submitValue = () => {
    const newBetChipsObject = {};
    keyValues1.forEach((item) => {
      newBetChipsObject[item.key] = item.value;
    });
    const user = JSON.parse(localStorage.getItem("clientspuser"));
    const Id = user?.data?.userId;
    const dataValue = {
      userId: Id,
      betChipsData: newBetChipsObject,
    };

    dispatch(userUpdate(dataValue));
    localStorage.setItem(
      "clientbetChipsData",
      JSON.stringify(newBetChipsObject)
    );
    closeModal();
  };

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const toggleForm1 = () => {
    setIsFormEnabled1(!isFormEnabled1);
  };

  const toggleForm2 = () => {
    setIsFormEnabled2(!isFormEnabled2);
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-start mt-12 justify-center bg-black bg-opacity-50">
        <div className="bg-secondary rounded-lg shadow-xl max-w-lg w-full relative mx-2">
       <div className="flex justify-between rounded-t-lg items-center px-3 py-2 bg-button ">
       <span className="text-xl font-bold text-primary">Custom Chips Setting</span>
          <button
            onClick={closeModal}
            className=" text-white text-3xl"
          >
            &times;
          </button>
       </div>
        
          <div className=" ">
            <form autoComplete="off" className="p-4 space-y-4 capitalize  boredr-t border-b border-white">
              <div className="flex justify-center items-center ">
                <div className="w-full space-y-1 ">
                  {keyValues1.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col md:flex-row md:justify-start md:items-center  space-x-2 "
                    >
                      
                      <label
                        className="block text-sm font-bold  text-white md:text-left mx-2 md:px-0"
                        for="username"
                      >
                       Name :
                      </label>
                     
                          <input
                            className="w-28   p-2 text-xs md:text-sm font-medium text-gray-900 rounded"
                            key={index}
                            type="text"
                            name="buttonValue"
                            value={item.key}
                            placeholder="Button Value"
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                e.target.value,
                                undefined
                              )
                            }
                          />
                       
                    </div>
                  ))}
                </div>
                <div className="w-full space-y-1 ">
                  {keyValues1.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col md:flex-row md:justify-start md:items-center  space-x-2"
                    >
                      <label
                        className="block text-sm font-bold  text-white md:text-left mx-2 md:px-0"
                        for="username"
                      >
                       Value :
                      </label>
                     
                          <input
                            className="w-28  p-2 text-xs md:text-sm font-medium text-gray-900 rounded"
                            key={index}
                            type="number"
                            name="buttonValue"
                            value={item.value}
                            placeholder="Button Value"
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                undefined,
                                e.target.value
                              )
                            }
                          />
                       
                    </div>
                  ))}
                </div>
              </div>
            </form>
            <div className="px-4 py-2 flex items-center justify-center gap-2">


              <span
                onClick={() => {
                  submitValue();
                }}
                className={`block text-xs font-bold py-2 px-4 rounded-md text-center focus:outline-none hover:opacity-100 transition-opacity 
                  bg-green-600 text-white cursor-pointer 
                `}
              >
                Submit
              </span>
              <span
                onClick={() => {
                  closeModal()
                }}
                className={`block text-xs font-bold py-2 px-4 rounded-md text-center focus:outline-none hover:opacity-100 transition-opacity 
                  bg-red-600 text-white cursor-pointer 
                `}
              >
                Close
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStack;
