import { MdOutlineCardGiftcard } from "react-icons/md";
import { FaHandsHelping } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";

export const data = [
    {
      id: 1,
      title: "Referral Program",
      content1:
        "Terms and Conditions agreements act as a legal contract between Magic Win and the user who access your website and mobile You agree that by accessing the App, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the App.",
      content2:
        "You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Site after the date such revised Terms and Conditions are posted.",
      order: 1,
      icon: <MdOutlineCardGiftcard/>,
    },
    {
      id: 2,
      title: "Help & Support",
      content1:
        "24/7 Customer Support Service is available, the changes in any revised Terms and Conditions by your continued use of the Site after the date such revised Terms and Conditions are posted. Magic Win is intended for users who are at least 18 years old. Those under the age of 18 cannot register for the app.",
      order: 2,
      icon: <MdOutlineSupportAgent/>,
    },
    {
      id: 3,
      title: "Terms & Conditions",
      content1:
        "Terms and Conditions agreements act as a legal contract between Magic Win and the user who access your website and mobile You agree that by accessing the App, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the App.",
      content2:
        "You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Site after the date such revised Terms and Conditions are posted.",
      order: 3,
      icon: <FaHandsHelping/>
    },
  ];